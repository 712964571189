import React from 'react';

const PlaceholderMenuCard = () =>
  Array(4)
    .fill(0)
    .map((value, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${value}-${key}`} className="detailed-view--group">
        <div className="card-placeholder">
          <div className="cover">
            <div className="image" />
            <div className="restaurant" />
          </div>
          <div className="info">
            <div className="title" />
            <ul>
              <li />
              <li />
            </ul>
          </div>
        </div>
      </div>
    ));

const PlaceholderHeader = () => (
  <div className="card-placeholder card-placeholder--header">
    <div className="cover">
      <div className="image" />
      <div className="restaurant" />
    </div>

    <div className="info">
      <div className="title" />
      <ul>
        <li />
        <li />
      </ul>
    </div>
  </div>
);

export { PlaceholderHeader };
export default PlaceholderMenuCard;
