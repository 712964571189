import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import { Link } from 'react-router-dom';
import GrandTotalPrice from './TotalPrice';
import FavoriteMarker from './FavoriteMarker';

const MenuCard = ({
  preset,
  isFavorites,
  isSavedPresets,
  isChefs,
  removeHandler,
  clickHandler,
}) => {
  const {
    id,
    slug,
    favorite,
    name,
    searchResultImageUrl,
    chefNames,
    chefImages,
    chefIds,
    pricePerPersonCents,
    totalPriceCents,
    numberOfGuests,
    description,
  } = preset;
  const url = `/${isChefs ? 'chefs' : 'preset-detail'}/${slug}`;
  return (
    <>
      {(isFavorites || isSavedPresets) && (
        <button
          type="button"
          className="close"
          style={{ zIndex: 1 }}
          onClick={e => {
            e.stopPropagation();
            removeHandler(id, isFavorites, isSavedPresets);
          }}
        >
          <svg className="icon icon-close-circle">
            <use xlinkHref="#spriteIcon-close-circle" />
          </svg>
        </button>
      )}
      {!isFavorites && !isSavedPresets && favorite && <FavoriteMarker />}
      <div className="cover">
        <img
          className="img-fluid"
          style={{ minHeight: 190, objectFit: 'cover' }}
          src={
            searchResultImageUrl
          }
          alt="meal_1"
        />
        <ul className="restaurants">
          {chefIds.map(chefId => (
            <li key={chefId + slug}>
              <img
                className="img-fluid"
                src={chefImages[chefId].profileImageUrl}
                alt="restaurant_1"
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="info">
        {chefNames.map(chef => (
          <h4 key={chef}>{chef}</h4>
        ))}
        <h4 style={{ fontSize: 14, color: '#7b8591' }}>
          {isChefs ? description : name}
        </h4>
        {!isSavedPresets && !isChefs && (
          <ul>
            <li>
              <svg className="icon icon-people">
                <use xlinkHref="#spriteIcon-people" />
              </svg>
              Serves: {numberOfGuests} (Adjustable)
            </li>
            <li>
              <svg className="icon icon-wallet">
                <use xlinkHref="#spriteIcon-wallet" />
              </svg>
              <GrandTotalPrice
                totalPriceCents={totalPriceCents}
                popoverHidden
              />
              | ${priceDecimalFormatter(pricePerPersonCents)}/person
            </li>
          </ul>
        )}
      </div>
      <div className="footer hide-desktop">
        <Link to={url} className="btn btn-link">
          View Details
        </Link>
      </div>
      <div className="overlay hide-mobile">
        {!isChefs && (
          <button
            className="btn btn-block btn-light"
            type="button"
            onClick={() => {
              clickHandler(id);
            }}
          >
            Quick View
          </button>
        )}
        <Link
          to={url}
          className={classNames('btn btn-block btn-primary', {
            'mt-20': isChefs,
          })}
        >
          View Details
        </Link>
      </div>
    </>
  );
};

MenuCard.propTypes = {
  preset: PropTypes.shape({
    id: PropTypes.number,
    favorite: PropTypes.bool,
    slug: PropTypes.string,
    name: PropTypes.string,
    searchResultImageUrl: PropTypes.string,
    chefImages: PropTypes.any,
    chefIds: PropTypes.arrayOf(PropTypes.number),
    servingStyles: PropTypes.arrayOf(PropTypes.string),
    pricePerPersonCents: PropTypes.number,
    totalPriceCents: PropTypes.number,
    numberOfGuests: PropTypes.number,
    chefNames: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
  }),
  isFavorites: PropTypes.bool,
  isSavedPresets: PropTypes.bool,
  isChefs: PropTypes.bool,
  removeHandler: PropTypes.func,
  clickHandler: PropTypes.func,
};

MenuCard.defaultProps = {
  preset: {},
  isFavorites: false,
  isSavedPresets: false,
  isChefs: false,
  removeHandler: () => {},
  clickHandler: () => {},
};
export default MenuCard;
