import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import intersectionBy from 'lodash/intersectionBy';

import { store } from 'react-notifications-component';
import { useSelector, useDispatch, batch } from 'react-redux';
import { signoutRequest } from 'redux/actions/authActions';
import {
  loadRestaurantDetails,
  setCheckoutData,
  setPreset,
  resetCheckoutData,
  saveCustomChefPreset,
  toggleRestaurantFavorite,
} from 'redux/restaurants/actions';
import { updateUserCart, removeUserCart } from 'redux/userCart/actions';

import { validate } from 'api/authApi';
import { getGroupOrderURL } from 'api/apiUtils';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import { checkMinumumHourNoticeValidation } from 'utils/address.util';
import {
  buildCheckoutDataFromPresetItem,
  getPresetSubTotalPrice,
  recoverAdditionalItems,
} from 'utils/preset.util';
import CopyToClipboard from 'react-copy-to-clipboard';

import { createGroupOrder } from 'api/presetApi';
import DeliveryInfoFormInline from 'components/common/DeliveryInfoForm/DeliveryInfoFormInline';
import TruncateWrapper from 'components/common/TruncateWrapper';
import PresetItemView from 'components/common/PresetItemView';
import OrderDetails from 'components/common/OrderDetails';
import ConfirmModal from 'components/common/ConfirmModal';
import { PRESET_TRUNCATE_THRESHOLD } from 'consts/presets';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { showSignInModal } from 'redux/actions/modalActions';
import PageMetaInfo from 'components/common/PageMetaInfo';
import OrderBasket from 'components/common/OrderBasket';
import Suggestion from 'components/common/Suggestion/Suggestion';
import RestaurantMenu from './RestaurantMenu';
import { PlaceholderHeader } from '../PresetDetail/Loading';

import './index.scss';

// function safeScrollToId(id = '') {
//   const el = document.getElementById(id);
//   if (el) {
//     window.scrollTo({
//       behavior: 'smooth',
//       top: el.offsetTop,
//     });
//   }
// }

const initialPreset = { presetItems: [] };

const RestaurantDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { slug } = useParams(); // I guess will be used later to fetch real Chef data
  const {
    loadingPresetDetails,
    loadingSaveCustomRestaurant,
    checkoutData,
    preset,
  } = useSelector(state => state.restaurants);
  const userCart = useSelector(state => state.userCart);
  const receivingMethod = useSelector(state => state.receivingMethod);
  const userSignedIn = useSelector(state =>
    get(state, 'user.user.confirmed', false),
  );

  const [showResetModal, setShowResetModal] = useState(false);
  const [currentPresetItem, setCurrentPresetItem] = useState(null);
  const [removePresetId, setRemovePresetId] = useState(null);
  const [showSaveForLaterModal, setShowSaveForLaterModal] = useState(false);
  const { pathname } = useLocation();

  const {
    id,
    bannerImageUrl,
    name,
    description,
    orderCount,
    chefs,
    presetItems,
    deliveryPriceCents,
    averageRating,
    preOrderNoticeHour,
    minimumOrderAmountCents,
    favorite,
  } = preset;

  const savedAt = get(userCart, `${id}.savedAt`, null);

  const subTotal = getPresetSubTotalPrice(checkoutData);
  const totalPrice = useMemo(
    () => (subTotal + deliveryPriceCents) * (1 + preset.taxRate),
    [subTotal, preset],
  );

  useEffect(() => {
    if (preset.slug !== slug) {
      // load data if preset is not defined yet - checkout -> back to chef page
      dispatch(loadRestaurantDetails(slug));
    }
  }, [slug]);

  useEffect(() => {
    // initialize the checkout data
    if (id) {
      if (userCart[id] && userCart[id].savedAt) {
        const userCheckoutData = userCart[id].checkoutData;

        const newPresetItems = intersectionBy(
          presetItems,
          userCheckoutData,
          'id',
        );

        // Now add additional menu items
        const recovered = recoverAdditionalItems(
          chefs,
          presetItems,
          userCheckoutData,
        );

        batch(() => {
          dispatch(setCheckoutData(userCheckoutData));
          dispatch(
            setPreset({
              ...preset,
              presetItems: [...newPresetItems, ...recovered],
            }),
          );
        });
      }
    }
  }, [id]);

  const updateCheckoutData = (newPresetItem, newPresetItemData) => {
    const filteredData = checkoutData.filter(
      presetItemData => presetItemData.id !== newPresetItemData.id,
    );

    const newCheckoutData = [...filteredData, newPresetItemData];
    batch(() => {
      dispatch(setCheckoutData(newCheckoutData));
      dispatch(updateUserCart(id, newCheckoutData));
    });

    const foundItem = presetItems.find(
      presetItem => presetItem.id === newPresetItem.id,
    );

    if (!foundItem) {
      dispatch(
        setPreset({
          ...preset,
          presetItems: [...presetItems, newPresetItem],
        }),
      );

      store.addNotification({
        title: 'Item added!',
        message: 'Please check the additional items category.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    }
  };

  const getCurrentPresetItemChefInfo = useMemo(() => {
    if (!currentPresetItem) {
      return null;
    }

    const currentChef = find(
      chefs,
      chef => chef.id === currentPresetItem.chefId,
    );

    // find menu order selections
    const menu = find(
      currentChef.menus,
      menu => menu.id === currentPresetItem.menuId,
    );

    return {
      chefImage: currentChef.profileImageUrl,
      menuType: menu.menuType,
      minOrderAmount: menu.minOrderAmount,
    };
  }, [currentPresetItem]);

  const removePresetItem = removeItemId => {
    // remove from the checkout as well
    const filteredCheckoutData = checkoutData.filter(
      presetItemData => presetItemData.id !== removeItemId,
    );

    // remove from preset -> presetItems
    const { presetItems } = preset;
    const filteredPresetItems = presetItems.filter(
      presetItem => presetItem.id !== removeItemId,
    );

    batch(() => {
      dispatch(setCheckoutData(filteredCheckoutData));
      dispatch(updateUserCart(id, filteredCheckoutData));
      dispatch(setPreset({ ...preset, presetItems: filteredPresetItems }));
    });

    store.addNotification({
      title: 'Item removed!',
      message: 'If you want to add it back, please check additional items.',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
      },
    });
  };

  const setCurrentPresetItemFromCheckoutData = presetItemId => {
    const presetItem = preset.presetItems.find(
      presetItem => presetItem.id === presetItemId,
    );
    if (presetItem) {
      setCurrentPresetItem(presetItem);
    }
  };

  const saveCustomPreset = name => {
    dispatch(saveCustomChefPreset(name, preset, checkoutData, history));
  };

  const onCheckoutClick = async () => {
    let validation = true;

    if (subTotal === 0) {
      // cart empty
      store.addNotification({
        message: 'Your cart is empty.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      validation = false;
      return;
    }
    // check the minimum date time requirement
    if (
      receivingMethod === 'delivery' &&
      !checkMinumumHourNoticeValidation(preset.preOrderNoticeHour)
    ) {
      // show validation error
      store.addNotification({
        message: `This preset requires ${preset.preOrderNoticeHour} hours notice, please select a delivery date and time that meets the minimum lead time`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      validation = false;
    }

    if (
      receivingMethod === 'delivery' &&
      totalPrice < minimumOrderAmountCents
    ) {
      // show validation error
      store.addNotification({
        message: `Minimum $${minimumOrderAmountCents / 100} order not met for ${
          preset.name
        }. Please add additional items or increase quantity and continue.`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      validation = false;
    }

    if (validation) {
      // need to check if user is not idle
      try {
        const userdata = await validate();
        if (userdata) {
          const {
            address1,
            address2,
            city,
            state,
            zip,
            country,
          } = preset.chefs[0];
          history.push({
            pathname: '/checkout',
            state: {
              preset,
              deliveryPriceCents,
              checkoutData,
              restaurantAddress: [address1, address2, city, state, zip, country]
                .filter(address => address)
                .join(', '),
            },
          });
        } else {
          // idle timeout
          store.addNotification({
            message:
              'You are logged out because of inactivity. Please login again!',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          });
          dispatch(signoutRequest());
        }
      } catch (e) {
        store.addNotification({
          message:
            'You are logged out because of inactivity. Please login again!',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
        dispatch(signoutRequest());
      }
    }
  };

  // function handleSaveOrderFrequency(data) {
  //   store.addNotification({
  //     message: '//TODO where should this data go?',
  //     type: 'danger',
  //     insert: 'top',
  //     container: 'top-right',
  //     animationIn: ['animated', 'bounceIn'],
  //     animationOut: ['animated', 'fadeOut'],
  //     dismiss: {
  //       duration: 4000,
  //     },
  //   });
  // }

  const onResetCart = () => {
    setShowResetModal(false);
    batch(() => {
      dispatch(resetCheckoutData());
      dispatch(removeUserCart(id));
    });
  };

  const onClickFavorite = () => {
    if (!userSignedIn) {
      dispatch(showSignInModal());
    } else {
      dispatch(toggleRestaurantFavorite(preset.id, preset.favorite));
    }
  };

  const createGroupOrderForPreset = async presetId => {
    try {
      console.log('step 1');
      const groupOrder = await createGroupOrder(presetId);
      console.log('step 2');
      console.log(groupOrder);
      const groupOrderUrl = getGroupOrderURL(groupOrder.uuid);
      console.log('step 3');
      console.log(groupOrderUrl);

      const params = localStorage.getItem('params');

      console.log('step 4');
      console.log(params);

      const urlParam = params
        ? `?${params
            .replace(/":"|":/g, '=')
            .replace(/","|,"/g, '&')
            .replace(/{"|"}|{|}/g, '')}`
        : '';
      window.location.replace(groupOrderUrl + urlParam);
    } catch (e) {
      console.log('-> error', e);
    }
  };

  const envSuggestion = process.env.REACT_APP_SHOW_CATERING_SUGGESTION;

  return (
    <main
      style={
        localStorage.getItem('isSuggestions') !== 'false' &&
        envSuggestion === 'true'
          ? {
              position: 'relative',
              zIndex: '3',
            }
          : {}
      }
      className="sticky-container"
    >
      <PageMetaInfo
        title={name}
        description={description}
        image={bannerImageUrl}
        canonicalLink={pathname}
      />
      {showResetModal && (
        <ConfirmModal
          show
          description="Are you sure you want to reset the preset?"
          confirmText="Yes"
          icon="/assets/img/meal_reset.svg"
          onConfirm={onResetCart}
          onClose={() => {
            setShowResetModal(false);
          }}
        />
      )}

      {removePresetId && (
        <ConfirmModal
          show
          description="Are you sure you want to remove this item from your order?"
          confirmText="Remove"
          onConfirm={() => {
            removePresetItem(removePresetId);
            setRemovePresetId(null);
          }}
          onClose={() => {
            setRemovePresetId(null);
          }}
        />
      )}

      <DeliveryInfoFormInline
        isAbleToChangeReceivingMethod
        restaurantAddress={preset?.address1}
      />

      <div className="detailed-view">
        <div className="container my-xl-3 mt-0 pt-0">
          <div className="row">
            <div className="col-xl-8 pr-xl-4">
              {loadingSaveCustomRestaurant && <LoadingAnimation />}

              {(loadingPresetDetails || isEqual(preset, initialPreset)) && (
                <PlaceholderHeader />
              )}

              {!loadingPresetDetails && !isEqual(preset, initialPreset) && (
                <>
                  <div className="detailed-view--cover hide-mobile">
                    <img
                      className="img-fluid"
                      src={bannerImageUrl}
                      alt="banner_img"
                    />

                    <ul className="restaurants large">
                      {chefs &&
                        chefs.map(chef => (
                          <li key={chef.id}>
                            <img
                              className="img-fluid"
                              src={chef.profileImageUrl}
                              alt="chef_logo"
                            />
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="detailed-view--header">
                    <div>
                      <h2 className="mb-2">{name}</h2>
                      <span className="saved-at">
                        {savedAt && `~~ [ Saved @ ${savedAt} ]`}
                      </span>

                      <div className="star-ratings-sprite-wrapper">
                        <div className="star-ratings-sprite star-ratings-sprite--green">
                          <span
                            style={{ width: `${averageRating * 20}%` }}
                            className="star-ratings-sprite-rating star-ratings-sprite-rating--green"
                          />
                        </div>

                        <span className="star-ratings-label">
                          {averageRating}
                        </span>

                        {/* <a
                          href="#reviews"
                          className="star-reviews-link"
                          onClick={e => {
                            e.preventDefault();
                            safeScrollToId('review-wrapper');
                          }}
                        >
                          (view {preset.reviewsCount} catering reviews)
                        </a> */}
                      </div>

                      <p style={{ fontSize: '14px' }}>
                        <svg className="icon icon-dinner">
                          <use xlinkHref="#spriteIcon-dinner" />
                        </svg>
                        Ordered {orderCount} times
                      </p>
                    </div>

                    <div className="buttons">
                      <button
                        className="btn btn-outline-primary btn-block hide-desktop"
                        data-toggle="dynamic-modal"
                        data-target="#modalOrderDetails"
                        type="button"
                        onClick={() => onCheckoutClick()}
                      >
                        Checkout
                      </button>
                      <CopyToClipboard text={preset.sharedUrl}>
                        <button
                          className="btn btn-outline-primary mt-3 btn-clipboard hide-desktop"
                          type="button"
                          onClick={() => {
                            store.addNotification({
                              title: 'URL Copied!',
                              message: 'You can now paste the URL',
                              type: 'success',
                              insert: 'top',
                              container: 'top-right',
                              animationIn: ['animated', 'bounceIn'],
                              animationOut: ['animated', 'fadeOut'],
                              dismiss: {
                                duration: 4000,
                              },
                            });
                          }}
                        >
                          <p>{preset.sharedUrl}</p>
                          <span>Share This</span>
                          <img src="/assets/img/share.svg" alt="clipboard" />
                        </button>
                      </CopyToClipboard>
                      {preset.presetType !== 'saved_for_later' && (
                        <button
                          className="btn btn-outline-primary btn-block mt-3 hide-desktop"
                          type="button"
                          onClick={() => {
                            setShowSaveForLaterModal(true);
                          }}
                        >
                          Save Cart
                        </button>
                      )}

                      <button
                        className="btn btn-link mr-xl-3"
                        type="button"
                        onClick={() => {
                          setShowResetModal(true);
                        }}
                      >
                        <svg className="icon icon-reset">
                          <use xlinkHref="#spriteIcon-reset" />
                        </svg>
                        Reset to default
                      </button>

                      <button
                        className={`
                          btn hide-mobile
                          ${favorite ? 'btn-primary' : 'btn-outline-primary'}
                        `}
                        type="button"
                        onClick={onClickFavorite}
                      >
                        <svg className="icon icon-heart">
                          <use xlinkHref="#spriteIcon-heart" />
                        </svg>
                        {favorite
                          ? 'Remove from favorites'
                          : 'Save to favorites'}
                      </button>
                    </div>
                  </div>

                  {receivingMethod === 'delivery' && (
                    <div className="detailed-view--info mb-4">
                      <div className="entry">
                        <div className="title">Delivery Fee</div>
                        <div className="value">
                          ${priceDecimalFormatter(deliveryPriceCents)}
                        </div>
                      </div>
                      <div className="delimiter" />
                      <div className="entry">
                        <div className="title">This Vendor Requires</div>
                        <div className="value">
                          {preOrderNoticeHour} Hours Notice
                        </div>
                      </div>
                      <div className="delimiter" />
                      <div className="entry">
                        <div className="title">Minimum Order Amount</div>
                        <div className="value">
                          ${priceDecimalFormatter(minimumOrderAmountCents)}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mb-5">
                    <TruncateWrapper
                      text={description}
                      threshold={PRESET_TRUNCATE_THRESHOLD}
                    />
                  </div>

                  {chefs && chefs.length > 0 && (
                    <div className="restaurant-menu_wrapper">
                      <RestaurantMenu
                        {...{
                          chefs,
                          presetItems,
                          setCurrentPresetItem,
                          checkoutData,
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-xl-4 pl-xl-2" style={{ zIndex: 2 }}>
              {/* <div className="d-none">
                  <OrderFrequency
                    handleSaveOrderFrequency={handleSaveOrderFrequency}
                  />
                </div> */}
              <div className="mb-4 cart-sticky">
                <OrderDetails
                  {...{
                    deliveryPriceCents,
                    preset,
                    checkoutData,
                    saveCustomPreset,
                    createGroupOrderForPreset,
                    onCheckoutClick,
                    preOrderNoticeHour: preset?.preOrderNoticeHour,
                  }}
                  removeCheckoutItem={checkoutItemId => {
                    setRemovePresetId(checkoutItemId);
                  }}
                  setShowSaveForLaterModal={setShowSaveForLaterModal}
                  showSaveForLaterModal={showSaveForLaterModal}
                  setCurrentPresetItemFromCheckoutData={
                    setCurrentPresetItemFromCheckoutData
                  }
                />
              </div>
              {envSuggestion === 'true' ? (
                <Suggestion
                  preset={preset}
                  loadDate={
                    loadingPresetDetails ||
                    isEqual(preset, initialPreset) ||
                    loadingSaveCustomRestaurant
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {currentPresetItem && (
        <PresetItemView
          presetItem={currentPresetItem}
          initialValues={
            checkoutData.find(
              presetForm => presetForm.id === currentPresetItem.id,
            ) || buildCheckoutDataFromPresetItem(currentPresetItem)
          }
          chefInfo={getCurrentPresetItemChefInfo}
          onClose={() => {
            setCurrentPresetItem(null);
          }}
          onSubmit={(presetItem, form) => {
            updateCheckoutData(presetItem, form);
          }}
        />
      )}

      <section className="total-footer hide-desktop">
        <OrderBasket
          onCheckoutClick={onCheckoutClick}
          totalPrice={totalPrice}
          totalItems={get(preset, 'presetItems.length')}
          preset={preset}
          deliveryPriceCents={deliveryPriceCents}
          checkoutData={checkoutData}
          removeCheckoutItem={checkoutItemId => {
            setRemovePresetId(checkoutItemId);
          }}
          setCurrentPresetItemFromCheckoutData={
            setCurrentPresetItemFromCheckoutData
          }
        />
        {/* <div className="order-freq__footer-position-wrap">
          <OrderFrequency handleSaveOrderFrequency={handleSaveOrderFrequency} />
        </div> */}
      </section>
    </main>
  );
};

export default RestaurantDetails;
