export const GET_CATERING_ORDER_REQUEST = 'GET_CATERING_ORDER_REQUEST';
export const GET_CATERING_ORDER_SUCCESS = 'GET_CATERING_ORDER_SUCCESS';
export const GET_CATERING_ORDER_FAILED = 'GET_CATERING_ORDER_FAILED';

export const GET_GROUP_ORDER_REQUEST = 'GET_GROUP_ORDER_REQUEST';
export const GET_GROUP_ORDER_SUCCESS = 'GET_GROUP_ORDER_SUCCESS';
export const GET_GROUP_ORDER_FAILED = 'GET_GROUP_ORDER_FAILED';

export const FETCH_CATERING_ORDER_REQUEST = 'FETCH_CATERING_ORDER_REQUEST';
export const FETCH_CATERING_ORDER_SUCCESS = 'FETCH_CATERING_ORDER_SUCCESS';
export const FETCH_CATERING_ORDER_FAILED = 'FETCH_CATERING_ORDER_FAILED';

export const CANCEL_CATERING_ORDER_REQUEST = 'CANCEL_CATERING_ORDER_REQUEST';
export const CANCEL_CATERING_ORDER_SUCCESS = 'CANCEL_CATERING_ORDER_SUCCESS';
export const CANCEL_CATERING_ORDER_FAILED = 'CANCEL_CATERING_ORDER_FAILED';

export const CANCEL_GROUP_ORDER_REQUEST = 'CANCEL_GROUP_ORDER_REQUEST';
export const CANCEL_GROUP_ORDER_SUCCESS = 'CANCEL_GROUP_ORDER_SUCCESS';
export const CANCEL_GROUP_ORDER_FAILED = 'CANCEL_GROUP_ORDER_FAILED';

export const UPDATE_CATERING_ORDER_REQUEST = 'UPDATE_CATERING_ORDER_REQUEST';
export const UPDATE_CATERING_ORDER_SUCCESS = 'UPDATE_CATERING_ORDER_SUCCESS';
export const UPDATE_CATERING_ORDER_FAILED = 'UPDATE_CATERING_ORDER_FAILED';

export const CLEAR_CATERING_ORDER_SUGGESTIONS =
  'CLEAR_CATERING_ORDER_SUGGESTIONS';
export const CATERING_ORDER_SUGGESTIONS_REQUEST =
  'CATERING_ORDER_SUGGESTIONS_REQUEST';
export const CATERING_ORDER_SUGGESTIONS_SUCCESS =
  'CATERING_ORDER_SUGGESTIONS_SUCCESS';
export const CATERING_ORDER_SUGGESTIONS_FAILED =
  'CATERING_ORDER_SUGGESTIONS_FAILED';

export const FINALIZE_CATERING_ORDER_REQUEST =
  'FINALIZE_CATERING_ORDER_REQUEST';
export const FINALIZE_CATERING_ORDER_SUCCESS =
  'FINALIZE_CATERING_ORDER_SUCCESS';
export const FINALIZE_CATERING_ORDER_FAILED = 'FINALIZE_CATERING_ORDER_FAILED';

export const CLEAR_CATERING_ORDER = 'CLEAR_CATERING_ORDER';
