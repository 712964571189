import React from 'react';

const PageNotFound = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 200px)',
    }}
  >
    <h1>Oops! Page Not found.</h1>
  </div>
);

export default PageNotFound;
