import { createSelector } from 'reselect';

export const getFilteredChefs = createSelector(
  [
    state => state.restaurants.chefs,
    state => state.restaurants.filterTags,
    state => state.restaurants.selectedFilterTags,
  ],
  (chefs, filterTags, selectedFilterTags) => {
    if (
      selectedFilterTags.length === 0 ||
      selectedFilterTags.length === filterTags.length
    ) {
      // all selected
      return [chefs, 'All'];
    }
    return [
      chefs.filter(({ cuisineTypes }) =>
        selectedFilterTags.some(tag => cuisineTypes.includes(tag)),
      ),
      selectedFilterTags.join(', '),
    ];
  },
);
