import React from 'react';
import PropTypes from 'prop-types';

const NoFavorites = ({ restaurants }) => (
  <section className="made--section mb-5">
    <div className="made--section-header">
      <div className="left">
        <h3>Favorites</h3>
      </div>
    </div>
    <div className="made--section-content mb-xl-4 collapsed d-flex justify-content-center">
      <div className="made--no-favorites">
        <div
          className="alert alert-secondary py-4 px-3 mt-0 mb-0"
          style={{ alignItems: 'center' }}
        >
          <img
            className="mb-0"
            src="/assets/img/favorite-add.svg"
            alt="no_fav"
          />
          <b className="px-xl-4">Tip</b>
          <p>
            To add {restaurants ? 'restaurants' : 'presets'} to your favorites
            click the little heart
            <svg className="icon icon-heart-filled">
              <use xlinkHref="#spriteIcon-heart" />
            </svg>{' '}
            in the upper right corner of the preset image.
          </p>
        </div>
      </div>
    </div>
  </section>
);

NoFavorites.propTypes = {
  restaurants: PropTypes.bool,
};

NoFavorites.defaultProps = {
  restaurants: false,
};

export default NoFavorites;
