import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function showLightboxReducer(
  state = initialState.showLightbox,
  action,
) {
  switch (action.type) {
    case types.SHOW_LIGHTBOX_SUCCESS:
      return false;
    default:
      return state;
  }
}
