import * as types from './actionTypes';

export const updateReceivingMethod = receivingMethod => {
  return {
    type: types.UPDATE_RECEIVING_METHOD,
    payload: {
      receivingMethod,
    },
  };
};
