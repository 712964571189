import Geocode from 'react-geocode';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import addBusinessDays from 'date-fns/addBusinessDays';
import differenceInHours from 'date-fns/differenceInHours';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'consts/form';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
const queryString = require('query-string');

export const setDeliveryInfo = (itemKey, value) => {
  if (itemKey === null) {
    // meaning set entire item
    localStorage.setItem('jb-delivery-info', JSON.stringify(value));
    return;
  }

  const deliveryInfo = localStorage.getItem('jb-delivery-info');
  if (deliveryInfo) {
    const parsedInfo = JSON.parse(deliveryInfo);
    parsedInfo[itemKey] = value;
    localStorage.setItem('jb-delivery-info', JSON.stringify(parsedInfo));
  } else {
    localStorage.setItem(
      'jb-delivery-info',
      JSON.stringify({ [itemKey]: value }),
    );
  }
};

export const getQueryString = () => {
  const deliveryInfo = localStorage.getItem('jb-delivery-info');
  if (deliveryInfo) {
    const parsedInfo = JSON.parse(deliveryInfo);
    const params = {
      delivery_date: parsedInfo.delivery_date,
      delivery_time: parsedInfo.delivery_time,
      address: parsedInfo.address,
      lat: parsedInfo.lat,
      lng: parsedInfo.lng,
    };

    return queryString.stringify(params);
  }

  return '';
};

export const checkDeliveryDateValidation = () => {
  const deliveryInfo = localStorage.getItem('jb-delivery-info');
  if (deliveryInfo) {
    const parsedInfo = JSON.parse(deliveryInfo);

    if (!parsedInfo.delivery_date) {
      return;
    }

    const prevDate = parse(
      parsedInfo.delivery_date,
      DEFAULT_DATE_FORMAT,
      new Date(),
    );
    const today = new Date();
    if (
      prevDate <
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    ) {
      setDeliveryInfo(
        'delivery_date',
        format(addBusinessDays(new Date(), 3), DEFAULT_DATE_FORMAT),
      );
    }
  }
};

export const checkMinumumHourNoticeValidation = minimumHours => {
  const deliveryInfo = localStorage.getItem('jb-delivery-info');
  if (!deliveryInfo) {
    return false;
  }

  const parsedInfo = JSON.parse(deliveryInfo);
  if (!parsedInfo.delivery_date || !parsedInfo.delivery_time) {
    return false;
  }

  const hours = differenceInHours(
    parse(
      `${parsedInfo.delivery_date}, ${parsedInfo.delivery_time}`,
      `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`,
      new Date(),
    ),
    new Date(),
  );

  return hours >= minimumHours;
};

export const getDeliveryInfo = () => {
  checkDeliveryDateValidation(); // check if delivery date is past or not

  const deliveryInfo = localStorage.getItem('jb-delivery-info');
  if (deliveryInfo) {
    return JSON.parse(deliveryInfo);
  }

  return {};
};

export const getCurrentLocation = setAddress => {
  navigator.geolocation.getCurrentPosition(
    pos => {
      const { coords } = pos;
      Geocode.fromLatLng(coords.latitude, coords.longitude).then(
        response => {
          const address = response.results[0].formatted_address;
          setAddress(address);
          setDeliveryInfo('address', address);
        },
        error => {
          console.error(error, 'geoCode failed');
        },
      );
    },
    () => {},
  );
};
