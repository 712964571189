import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function presetsReducer(state = initialState.presets, action) {
  switch (action.type) {
    case types.FETCH_PRESET_LIST_SUCCESS:
      return action.presets;
    default:
      return state;
  }
}

export function presetReducer(state = initialState.preset, action) {
  switch (action.type) {
    case types.FETCH_PRESET_DETAIL_SUCCESS:
      return action.preset;
    default:
      return state;
  }
}
