import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './index.scss';
import FavoriteMarker from '../FavoriteMarker';

const RestaurantCard = ({
  id,
  onFavoritePage,
  favorite,
  savedOrder,
  removeHandler,
  firstName,
  description,
  distance,
  profileImageUrl,
  shortAddress,
  slug,
  mainImageUrl,
  openRestaurantLocation,
  latitude,
  longitude,
  distanceUnit,
}) => {
  return (
    <div className="made--meal meal-card slide slide--25 restaurant-card">
      {favorite && !onFavoritePage && <FavoriteMarker />}
      {(favorite || savedOrder) && onFavoritePage && (
        <button
          type="button"
          className="close"
          style={{ zIndex: 1 }}
          onClick={e => {
            e.stopPropagation();
            removeHandler(id, favorite, savedOrder);
          }}
        >
          <svg className="icon icon-close-circle">
            <use xlinkHref="#spriteIcon-close-circle" />
          </svg>
        </button>
      )}
      <Link className="restaurant-card__top" to={slug || '/'}>
        <div
          className="restaurant-card__cover"
          style={{
            backgroundImage: `url(${mainImageUrl})`,
          }}
        >
          <ul className="restaurant-card__logo">
            <li>
              <img
                className="img-fluid"
                src={profileImageUrl}
                alt={firstName}
              />
            </li>
          </ul>
          <div className="restaurant-card__cover-hover">
            <button type="button" className="btn btn-sm btn-outline-primary">
              View Details
            </button>
          </div>
        </div>

        <div className="restaurant-card__info">
          <h4
            style={{ marginBottom: '3px' }}
            className="restaurant-card__info-name"
          >
            {firstName}
          </h4>
          {description && (
            <h4
              style={{ fontSize: 15, color: '#7D7D7D', fontWeight: 400 }}
              className="mb-0"
            >
              {description}
            </h4>
          )}
        </div>
      </Link>

      <a
        href="#map"
        className="restaurant-card__footer"
        onClick={e => {
          e.preventDefault();
          openRestaurantLocation({
            firstName,
            shortAddress,
            slug,
            latitude,
            longitude,
          });
        }}
      >
        <p className="restaurant-card__address">
          <img
            src="/assets/img/location.svg"
            className="restaurant-card__address-ico"
            alt="Location"
          />
          <span className="restaurant-card__address-p1">{shortAddress}</span>
          <span className="restaurant-card__address-p2">
            {distance} {distanceUnit} away
          </span>
          <span className="restaurant-card__footer-map">Map it</span>
        </p>
      </a>
    </div>
  );
};

RestaurantCard.propTypes = {
  id: PropTypes.number,
  onFavoritePage: PropTypes.bool,
  favorite: PropTypes.bool,
  savedOrder: PropTypes.bool,
  removeHandler: PropTypes.func,
  firstName: PropTypes.string,
  description: PropTypes.string,
  distance: PropTypes.number,
  profileImageUrl: PropTypes.string,
  shortAddress: PropTypes.string,
  slug: PropTypes.string,
  mainImageUrl: PropTypes.string,
  openRestaurantLocation: PropTypes.func,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  distanceUnit: PropTypes.string,
};

RestaurantCard.defaultProps = {
  id: 0,
  onFavoritePage: false,
  favorite: false,
  savedOrder: false,
  removeHandler: () => {},
  firstName: '',
  description: '',
  distance: 0,
  profileImageUrl: '',
  shortAddress: '',
  slug: '',
  latitude: '',
  longitude: '',
  distanceUnit: '',
  mainImageUrl: '/assets/img/restaurant-card-placeholder.jpg',
  openRestaurantLocation: () => {},
};

export default memo(RestaurantCard);
