// deprecated - as we don't need to save presets data inside redux
import * as types from './actionTypes';
import * as presetApi from '../../api/presetApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function loadPresetDetailSuccess(preset) {
  return { type: types.FETCH_PRESET_DETAIL_SUCCESS, preset };
}

export function loadPresetListSuccess({ presets }) {
  return { type: types.FETCH_PRESET_LIST_SUCCESS, presets };
}

export function loadPresetList() {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const presets = await presetApi.getPresets();
      const action = loadPresetListSuccess(presets);
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadPresetDetail(slug) {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const preset = await presetApi.getPresetDetail(slug);
      const action = loadPresetDetailSuccess(preset);
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
