import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DeliveryInfoFormInline from 'components/common/DeliveryInfoForm/DeliveryInfoFormInline';
import { searchPresets } from 'api/presetApi';

import LoadingAnimation from 'components/common/LoadingAnimation';
import { connect } from 'react-redux';
import { getQueryString } from 'utils/address.util';
import WelcomeModal from 'components/common/WelcomeModal';
import { Link } from 'react-router-dom';
import PresetsFilter from './PresetsFilter';
import PresetsCategory from './PresetsCategory';
import MenuCardPlaceholder from './MenuCardPlaceholder';

const MadeForYou = ({ user, showLightbox }) => {
  const [loading, setLoading] = useState(true);
  const [presets, setPresets] = useState({});
  const [removeLoading, setRemoveLoading] = useState(false);
  const userSignedIn = user && user.confirmed;
  const [formDataQuery, setFormDataQuery] = useState(getQueryString());

  const fetchData = async (isRemoving = false) => {
    if (isRemoving) {
      setRemoveLoading(true);
    } else {
      setLoading(true);
    }

    const { presets } = await searchPresets({
      queryParams: formDataQuery,
      page: 1,
    });
    setPresets(presets);

    if (isRemoving) {
      setRemoveLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [formDataQuery, userSignedIn]);

  const onDeliveryFormSubmit = query => {
    setFormDataQuery(query);
  };

  const { allPresets } = presets;

  return (
    <main>
      {!userSignedIn && showLightbox && <WelcomeModal />}
      {removeLoading && <LoadingAnimation />}
      <DeliveryInfoFormInline onSubmit={onDeliveryFormSubmit} />
      <div className="container">
        <div className="row align-items-center">
          <h1 className="page-title mt-0">
            {userSignedIn ? 'Browse Presets' : 'Popular Options'}
          </h1>
          {userSignedIn && (
            <Link className="ml-4" to="/favorites">
              View Your Favorites / Saved Orders
            </Link>
          )}
        </div>
        <PresetsFilter />
        {loading && (
          <div className="slick-initialized slick-slider slick slick-grid home--carousel">
            <div className="slick-list">
              <div className="slick-track slick-track--wrapped">
                <MenuCardPlaceholder />
              </div>
            </div>
          </div>
        )}
        {!loading && (
          <>
            {allPresets &&
              allPresets.length > 0 &&
              allPresets.map(presetCategory => (
                <PresetsCategory
                  key={presetCategory[0]}
                  name={presetCategory[0]}
                  presets={presetCategory[1]}
                />
              ))}
          </>
        )}
        {/* <div className="made--more-options py-5 my-5">
          <h2 className="mb-4">Need more options?</h2>
          <button type="button" className="btn btn-outline-primary">
            Load More
          </button>
        </div> */}
      </div>
    </main>
  );
};

const mapStateToProps = state => ({
  user: state.user && state.user.user,
  showLightbox: state.showLightbox,
});

const connectedMadeForYou = connect(mapStateToProps, null)(MadeForYou);

MadeForYou.propTypes = {
  user: PropTypes.shape({
    confirmed: PropTypes.bool,
  }).isRequired,
  showLightbox: PropTypes.bool.isRequired,
};

export default connectedMadeForYou;
