/* eslint-disable no-case-declarations */
import xor from 'lodash/xor';
import { buildFormData } from 'utils/preset.util';
import * as types from './actionTypes';

const initialState = {
  chefs: [],
  filterTags: [],
  selectedFilterTags: [],
  loading: false,
  loadingMore: false,
  currentPage: 1,
  fullCount: 0,
  totalPage: 1,
  error: null,
  savedForLaterRestaurants: [],
  favoriteRestaurants: [],

  // restaurant detail
  loadingPresetDetails: false,
  checkoutData: [],
  initialCheckoutData: [],
  initialPresetItems: [],
  preset: { presetItems: [] },

  // save custom restaurant
  loadingSaveCustomRestaurant: false,

  // remove favorite or saved on browse restaurants page
  loadingRemoveFavoriteOrSaved: false,
};

export default function restaurantsReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESTAURANTS_FETCH_REQUESTED:
      const { loadingMore, page } = action.payload;
      if (loadingMore) {
        return { ...state, loadingMore: true, currentPage: page };
      }
      return { ...state, loading: true, currentPage: 1 };

    case types.RESTAURANTS_FETCH_SUCCESS:
      const {
        filterTags,
        chefs,
        currentPage,
        fullCount,
        totalPage,
        favoriteRestaurants,
        savedForLaterRestaurants,
      } = action.payload;
      if (!action.payload.loadingMore) {
        return {
          ...state,
          loading: false,
          chefs,
          filterTags,
          selectedFilterTags: [],
          currentPage,
          fullCount,
          totalPage,
          favoriteRestaurants,
          savedForLaterRestaurants,
        };
      }
      return {
        ...state,
        loadingMore: false,
        chefs: [...state.chefs, ...chefs],
        currentPage,
        fullCount,
        totalPage,
      };

    case types.RESTAURANTS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        chefs: [],
        filterTags: [],
        selectedFilterTags: [],
        error: action.payload.error,
      };

    case types.TOGGLE_FILTER_TAG:
      const { tag } = action.payload;
      return {
        ...state,
        selectedFilterTags: xor(state.selectedFilterTags, [tag]),
      };

    case types.SET_SELECTED_TAGS:
      return {
        ...state,
        selectedFilterTags: [...action.payload.tags],
      };

    case types.RESTAURANT_DETAIL_FETCH_REQUESTED:
      return {
        ...state,
        loadingPresetDetails: true,
        checkoutData: [],
        initialCheckoutData: [],
        initialPresetItems: [],
        preset: { presetItems: [] },
      };

    case types.RESTAURANT_DETAIL_FETCH_SUCCESS:
      const initialCheckoutData = buildFormData(action.payload.preset);
      return {
        ...state,
        loadingPresetDetails: false,
        preset: action.payload.preset,
        initialCheckoutData,
        initialPresetItems: [...action.payload.preset.presetItems],
        checkoutData: [...initialCheckoutData],
      };

    case types.RESTAURANT_DETAIL_FETCH_FAILED:
      return {
        ...state,
        loadingPresetDetails: false,
        checkoutData: initialState.checkoutData,
        preset: initialState.preset,
        error: action.payload.error,
      };

    case types.SET_CHECKOUT_DATA:
      return {
        ...state,
        checkoutData: action.payload.checkoutData,
      };

    case types.RESET_CHECKOUT_DATA:
      return {
        ...state,
        checkoutData: [...state.initialCheckoutData],
        preset: {
          ...state.preset,
          presetItems: [...state.initialPresetItems],
        },
      };

    case types.SET_PRESET:
      return {
        ...state,
        preset: action.payload.preset,
      };

    case types.SAVE_CUSTOM_CHEF_PRESET_REQUESTED:
      return {
        ...state,
        loadingSaveCustomRestaurant: true,
      };

    case types.SAVE_CUSTOM_CHEF_PRESET_SUCCEEDED:
      return {
        ...state,
        loadingSaveCustomRestaurant: false,
      };

    case types.SAVE_CUSTOM_CHEF_PRESET_FAILED:
      return {
        ...state,
        loadingSaveCustomRestaurant: false,
        error: action.payload.error,
      };

    case types.TOGGLE_FAVORITE_STATUS_SUCCEEDED:
      return {
        ...state,
        preset: {
          ...state.preset,
          favorite: !state.preset.favorite,
        },
      };
    default:
      return state;
  }
}
