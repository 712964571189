/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { signoutRequest } from 'redux/actions/authActions';
import classnames from 'classnames';
import ProgressBar from 'react-bootstrap/ProgressBar';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import intersectionBy from 'lodash/intersectionBy';
import { useParams, withRouter, useLocation } from 'react-router-dom';

import Sticky from 'react-sticky-el';
import { store } from 'react-notifications-component';

import DeliveryInfoFormInline from 'components/common/DeliveryInfoForm/DeliveryInfoFormInline';
import OrderDetails from 'components/common/OrderDetails';
import VoidLink from 'components/common/VoidLink';
import LoadingAnimation from 'components/common/LoadingAnimation';
import {
  getPresetDetail,
  saveForLater,
  addFavorite,
  removeFavorite,
  createGroupOrder,
} from 'api/presetApi';
import {
  splitItems,
  getPresetItemQuantity,
  getPresetItemTotalPrice,
  getChefOrderAmountFromPreset,
  buildCheckoutDataFromPresetItem,
  buildFormData,
  recoverAdditionalItems,
  getTotalQuantity,
} from 'utils/preset.util';
import { getGroupOrderURL } from 'api/apiUtils';
import TruncateWrapper from 'components/common/TruncateWrapper';
import './index.scss';
import GrandTotalPrice from 'components/common/TotalPrice';
import PresetItemView from 'components/common/PresetItemView';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import ConfirmModal from 'components/common/ConfirmModal';
import { ADDITIONAL_ITEMS } from 'consts/presets';
import { showSignInModal } from 'redux/actions/modalActions';
import { checkMinumumHourNoticeValidation } from 'utils/address.util';
import { validate } from 'api/authApi';
import { updateUserCart, removeUserCart } from 'redux/userCart/actions';
import PageMetaInfo from 'components/common/PageMetaInfo';
import AdditionalItemToggle from './AdditionalItemToggle';
import AdditionalItemTab from './AdditionalItemTab';
import PlaceholderMenuCard, { PlaceholderHeader } from './Loading';

const PRESET_TRUNCATE_THRESHOLD = 340;
const PROGRESS_VARIANTS = ['success', 'warning'];

const PresetDetail = ({ history, user, showSignInModal, signoutRequest }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [saveForLaterLoading, setSaveForLaterLoading] = useState(false);
  const [preset, setPreset] = useState({});
  const [currentPresetItem, setCurrentPresetItem] = useState(null); // used to show change quantity modal
  const [checkoutData, setCheckoutData] = useState([]); // used to save dynamic form data
  const [initialCheckoutData, setInitialCheckoutData] = useState([]); // used to check equality
  const [removePresetId, setRemovePresetId] = useState(null); // used to show remove modal
  const [showResetModal, setShowResetModal] = useState(false); // used to show reset modal dialog
  const [showAdditionalItems, setShowAdditionalItems] = useState(false); // used to show additional items
  const [chefsOrderAmount, setChefsOrderAmount] = useState([]); // need to show progress bar for min order amount
  const [showSaveForLaterModal, setShowSaveForLaterModal] = useState(false);
  const [savedAt, setSavedAt] = useState(null);
  const userSignedIn = user && user.confirmed;
  const userCart = useSelector(state => state.userCart);
  const { pathname } = useLocation();

  const totalQuantity = useMemo(() => {
    return getTotalQuantity(checkoutData);
  }, [checkoutData]);

  const fetchData = async (forceReset = false) => {
    setLoading(true);
    try {
      const preset = await getPresetDetail(id);
      const userCheckoutData =
        userCart[preset.id] && userCart[preset.id].checkoutData;
      // check if we already have saved checkout
      if (!forceReset && userCheckoutData && Array.isArray(userCheckoutData)) {
        // load saved state
        setCheckoutData(userCheckoutData);
        // need to rebuild preset items based on saved checkoutData
        // - remove/added items especially

        // get rid of removed preset items first
        const { presetItems, chefs } = preset;
        const newPresetItems = intersectionBy(
          presetItems,
          userCheckoutData,
          'id',
        );

        // Now add additional menu items
        const recovered = recoverAdditionalItems(
          chefs,
          presetItems,
          userCheckoutData,
        );

        setPreset({
          ...preset,
          presetItems: [...newPresetItems, ...recovered],
        });
        setSavedAt(userCart[preset.id].savedAt);
      } else {
        const formData = buildFormData(preset);
        setPreset(preset);
        setInitialCheckoutData([...formData]);
        setCheckoutData([...formData]);
        setSavedAt(null);
      }
    } catch (e) {
      console.log('--> error');
      // redirect to not found page
      history.push('/not-found');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [id]);

  useEffect(() => {
    const { chefs } = preset;
    if (chefs) {
      setChefsOrderAmount(
        chefs.map(chef => getChefOrderAmountFromPreset(checkoutData, chef.id)),
      );
    }
  }, [preset, checkoutData]); // whenever preset or checkout data changes, need to re-calc min order progress

  useEffect(() => {
    if (preset && preset.id) {
      if (isEqual(checkoutData, initialCheckoutData)) {
        // just remove user preset cart
        dispatch(removeUserCart(preset.id));
      } else {
        dispatch(updateUserCart(preset.id, checkoutData));
      }
    }
  }, [preset, checkoutData, initialCheckoutData]); // needed to auto save the user cart

  function mealCard(presetCategory, preset, key) {
    if (presetCategory.length === 0) {
      return null;
    }

    const { chefs } = preset;
    const { presetMenuCategoryName } = presetCategory[0];

    return (
      <div
        className={classnames('detailed-view--group', {
          multi: presetCategory.length > 1,
        })}
        key={key}
      >
        <h3>{presetMenuCategoryName}</h3>
        <div className="items">
          {presetCategory.map(presetItem => {
            const {
              mainImageUrl,
              name,
              description,
              dietaryTypes,
            } = presetItem;
            const itemChef = find(chefs, chef => chef.id === presetItem.chefId);
            const presetItemCheckoutData = find(
              checkoutData,
              presetForm => presetForm.id === presetItem.id,
            );

            if (!presetItemCheckoutData) {
              return null;
            }

            const totalQuantity = getPresetItemQuantity(presetItemCheckoutData);

            return (
              <div key={presetItem.id} className="meal-card">
                <div
                  className="cover"
                  onClick={() => {
                    setCurrentPresetItem(presetItem);
                  }}
                >
                  <img className="w-100" src={mainImageUrl} alt="item_image" />
                  <ul className="restaurants">
                    {itemChef && (
                      <li key={itemChef.id}>
                        <img
                          className="img-fluid"
                          src={itemChef.profileImageUrl}
                          alt="chef_image"
                        />
                      </li>
                    )}
                  </ul>
                  <button
                    type="button"
                    className="close"
                    onClick={e => {
                      e.stopPropagation();
                      setRemovePresetId(presetItem.id);
                    }}
                  >
                    <svg className="icon icon-close-circle">
                      <use xlinkHref="#spriteIcon-close-circle" />
                    </svg>
                  </button>
                </div>
                <div className="info">
                  <div className="preset-item--info">
                    <h4>{name}</h4>
                  </div>

                  <div className="meal-price mt-25">
                    <svg className="icon icon-wallet">
                      <use xlinkHref="#spriteIcon-wallet" />
                    </svg>
                    $
                    {priceDecimalFormatter(
                      getPresetItemTotalPrice(presetItemCheckoutData),
                    )}
                    /{totalQuantity} quantity
                  </div>
                  <ul className="tags">
                    {dietaryTypes
                      .split(',')
                      .map(
                        dietary => dietary && <li key={dietary}>{dietary}</li>,
                      )}
                  </ul>
                  <span className="text-muted">
                    <TruncateWrapper text={description} />
                  </span>
                </div>
                <div className="footer">
                  <div data-toggle="modal" data-target="#modalEditQty">
                    <span>Qty</span>
                    <span className="badge badge-pill badge-primary">
                      {totalQuantity}
                    </span>
                    <VoidLink
                      className="btn btn-link"
                      onClick={() => {
                        setCurrentPresetItem(presetItem);
                      }}
                    >
                      Change
                    </VoidLink>
                  </div>
                  <VoidLink
                    className="btn btn-link"
                    onClick={() => {
                      setCurrentPresetItem(presetItem);
                    }}
                  >
                    View Details
                  </VoidLink>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const updateCheckoutData = (newPresetItem, newPresetItemData) => {
    const filteredData = filter(
      checkoutData,
      presetItemData => presetItemData.id !== newPresetItemData.id,
    );

    setCheckoutData([...filteredData, newPresetItemData]);

    // check if presetItem already exists, if not then we need to add it.
    // For default preset items, this is not needed, but for additional items
    // Because we're only adding preset items when user clicks save
    const { presetItems } = preset;
    const foundItem = find(
      presetItems,
      presetItem => presetItem.id === newPresetItem.id,
    );

    if (!foundItem) {
      setPreset({
        ...preset,
        presetItems: [...presetItems, newPresetItem],
      });

      store.addNotification({
        title: 'Item added!',
        message: 'Please check the additional items category.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    }
  };

  const saveCustomPreset = async name => {
    setSaveForLaterLoading(true);
    try {
      const saved = await saveForLater(name, preset, checkoutData);
      // extract relative path
      const relativeIndex = saved.sharedUrl.indexOf('/preset-detail/');
      if (relativeIndex > -1) {
        history.push(saved.sharedUrl.slice(relativeIndex));
      }
      store.addNotification({
        title: 'Preset Saved!',
        message: 'You can find it on your home page under Saved Presets.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    } catch (e) {
      console.log('-> error', e);
    } finally {
      setSaveForLaterLoading(false);
    }
  };

  const removePresetItem = removeItemId => {
    // remove from the checkout as well
    const filteredCheckoutData = filter(
      checkoutData,
      presetItemData => presetItemData.id !== removeItemId,
    );
    setCheckoutData(filteredCheckoutData);

    // remove from preset -> presetItems
    const { presetItems } = preset;
    const filteredPresetItems = filter(
      presetItems,
      presetItem => presetItem.id !== removeItemId,
    );

    setPreset({ ...preset, presetItems: filteredPresetItems });
    store.addNotification({
      title: 'Item removed!',
      message: 'If you want to add it back, please check additional items.',
      type: 'danger',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'bounceIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: 4000,
      },
    });
  };

  const createGroupOrderForPreset = async presetId => {
    try {
      const groupOrder = await createGroupOrder(presetId);
      const groupOrderUrl = getGroupOrderURL(groupOrder.uuid);
      // const params = localStorage.getItem('params');
      console.log(groupOrderUrl);
      // window.location.replace(groupOrderUrl);
    } catch (e) {
      console.log('-> error', e);
    }
  };

  const {
    bannerImageUrl,
    name,
    description,
    orderCount,
    chefs,
    presetItems,
    totalPriceCents,
    deliveryPriceCents,
    priceCents,
    averageRating,
    pricePerPersonCents,
    preOrderNoticeHour,
    minimumOrderAmountCents,
    favorite,
  } = preset;

  const onCheckoutClick = async () => {
    let validation = true;
    // check the minimum date time requirement
    if (!checkMinumumHourNoticeValidation(preOrderNoticeHour)) {
      // show validation error
      store.addNotification({
        message: `This preset requires ${preOrderNoticeHour} hours notice, please select a delivery date and time that meets the minimum lead time`,
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      validation = false;
    }

    chefs.forEach((chef, index) => {
      const chefOrderAmount = chefsOrderAmount[index];

      if (chefOrderAmount < chef.preOrderMinOrderAmountCents) {
        // show validation error
        store.addNotification({
          message: `Minimum $${chef.preOrderMinOrderAmountCents /
            100} order not met for ${chef.firstName} ${
            chef.lastName
          }. Please add additional items or increase quantity and continue.`,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
        validation = false;
      }
    });

    if (validation) {
      // need to check if user is not idle
      try {
        const userdata = await validate();
        if (userdata) {
          history.push({
            pathname: '/checkout',
            state: {
              preset,
              deliveryPriceCents,
              checkoutData,
            },
          });
        } else {
          // idle timeout
          store.addNotification({
            message:
              'You are logged out because of inactivity. Please login again!',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'bounceIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 4000,
            },
          });
          signoutRequest();
        }
      } catch (e) {
        store.addNotification({
          message:
            'You are logged out because of inactivity. Please login again!',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
        signoutRequest();
      }
    }
  };

  const toggleFavoriteStatus = async presetId => {
    try {
      if (favorite) {
        await removeFavorite(presetId);
        store.addNotification({
          message: 'Removed from your Favorites. You can add it back anytime.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
      } else {
        await addFavorite(presetId);
        store.addNotification({
          message:
            'Added to your Favorites. You can find this preset saved under the Favorites category on your homepage.',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
      }
      setPreset({ ...preset, favorite: !favorite });
    } catch (e) {
      console.log('--> error', e);
    }
  };

  const setCurrentPresetItemFromCheckoutData = presetItemId => {
    const presetItem = find(
      presetItems,
      presetItem => presetItem.id === presetItemId,
    );
    if (presetItem) {
      setCurrentPresetItem(presetItem);
    }
  };

  const memoizedMenuCategories = useMemo(() => splitItems(presetItems), [
    presetItems,
  ]);

  const getCurrentPresetItemChefInfo = useMemo(() => {
    if (!currentPresetItem) {
      return null;
    }

    const currentChef = find(
      chefs,
      chef => chef.id === currentPresetItem.chefId,
    );

    // find menu order selections
    const menu = find(
      currentChef.menus,
      menu => menu.id === currentPresetItem.menuId,
    );

    return {
      chefImage: currentChef.profileImageUrl,
      menuType: menu.menuType,
      minOrderAmount: menu.minOrderAmount,
    };
  }, [currentPresetItem]);

  return (
    <main className="sticky-container">
      <PageMetaInfo
        title={name}
        description={description}
        image={bannerImageUrl}
        canonicalLink={pathname}
      />
      {saveForLaterLoading && <LoadingAnimation />}
      {removePresetId && (
        <ConfirmModal
          show
          description="Are you sure you want to remove this item from your order?"
          confirmText="Remove"
          onConfirm={() => {
            removePresetItem(removePresetId);
            setRemovePresetId(null);
          }}
          onClose={() => {
            setRemovePresetId(null);
          }}
        />
      )}
      {showResetModal && (
        <ConfirmModal
          show
          description="Are you sure you want to reset the preset?"
          confirmText="Yes"
          icon="/assets/img/meal_reset.svg"
          onConfirm={() => {
            setShowResetModal(false);
            fetchData(true);
          }}
          onClose={() => {
            setShowResetModal(false);
          }}
        />
      )}
      <DeliveryInfoFormInline />
      <div className="detailed-view">
        <div className="container my-xl-3 mt-0 pt-0">
          <div className="row">
            <div className="col-xl-8 pr-xl-4">
              {!loading && (
                <>
                  <div className="detailed-view--cover hide-mobile">
                    <img
                      className="img-fluid"
                      src={bannerImageUrl}
                      alt="banner_img"
                    />
                    <ul className="restaurants large">
                      {chefs &&
                        chefs.map(chef => (
                          <li key={chef.id}>
                            <img
                              className="img-fluid"
                              src={chef.profileImageUrl}
                              alt="chef_logo"
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="detailed-view--header">
                    <div>
                      <h2>
                        {chefs.map(chef =>
                          [chef.firstName, chef.lastName].join(', '),
                        )}
                      </h2>
                      <h3>
                        {name}
                        <div className="text-primary d-xl-none">
                          <svg className="icon icon-heart">
                            <use xlinkHref="#spriteIcon-heart" />
                          </svg>
                        </div>
                      </h3>
                      <span className="saved-at">
                        {savedAt && `~~ [ Saved @ ${savedAt} ]`}
                      </span>

                      <div className="star-ratings-sprite">
                        <span
                          style={{ width: `${averageRating * 20}%` }}
                          className="star-ratings-sprite-rating"
                        />
                      </div>
                      <p>
                        <svg className="icon icon-dinner">
                          <use xlinkHref="#spriteIcon-dinner" />
                        </svg>
                        Ordered {orderCount} times
                      </p>
                      <p className="hide-desktop">
                        <svg className="icon icon-coin-stack">
                          <use xlinkHref="#spriteIcon-coin-stack" />
                        </svg>
                        Total Price: &nbsp;{' '}
                        <GrandTotalPrice totalPriceCents={totalPriceCents} />
                      </p>
                    </div>
                    <div className="buttons">
                      <button
                        className="btn btn-outline-primary btn-block hide-desktop"
                        data-toggle="dynamic-modal"
                        data-target="#modalOrderDetails"
                        type="button"
                      >
                        Checkout
                      </button>
                      <button
                        className="btn btn-link mr-xl-3"
                        type="button"
                        onClick={() => {
                          setShowResetModal(true);
                        }}
                      >
                        <svg className="icon icon-reset">
                          <use xlinkHref="#spriteIcon-reset" />
                        </svg>
                        Reset to default
                      </button>
                      <button
                        className={classnames('btn hide-mobile', {
                          'btn-outline-primary': !favorite,
                          'btn-primary': favorite,
                        })}
                        type="button"
                        onClick={() => {
                          if (!userSignedIn) {
                            showSignInModal();
                          } else {
                            toggleFavoriteStatus(preset.id);
                          }
                        }}
                      >
                        <svg className="icon icon-heart">
                          <use xlinkHref="#spriteIcon-heart" />
                        </svg>
                        {favorite
                          ? 'Remove from favorites'
                          : 'Save to favorites'}
                      </button>
                    </div>
                  </div>
                  <div className="detailed-view-progress">
                    {chefs &&
                      chefs.map((chef, index) => {
                        const { preOrderMinOrderAmountCents } = chef;
                        const percentage =
                          preOrderMinOrderAmountCents > 0
                            ? (chefsOrderAmount[index] * 100.0) /
                              preOrderMinOrderAmountCents
                            : 0;
                        return (
                          preOrderMinOrderAmountCents >
                            chefsOrderAmount[index] && (
                            <ProgressBar
                              key={PROGRESS_VARIANTS[index]}
                              className="mb-2"
                              now={percentage}
                              label={`  $${chefsOrderAmount[index] /
                                100} of $${chefs[index]
                                .preOrderMinOrderAmountCents /
                                100} Minimum Order Amount`}
                              variant={PROGRESS_VARIANTS[index]}
                            />
                          )
                        );
                      })}
                  </div>
                  <div className="detailed-view--info">
                    <div className="entry">
                      <div className="title">Delivery Fee</div>
                      <div className="value">
                        ${priceDecimalFormatter(deliveryPriceCents)}
                      </div>
                    </div>
                    <div className="delimiter" />
                    <div className="entry">
                      <div className="title">This Vendor Requires</div>
                      <div className="value">
                        {preOrderNoticeHour} Hours Notice
                      </div>
                    </div>
                    <div className="delimiter" />
                    <div className="entry">
                      <div className="title">Minimum Order Amount</div>
                      <div className="value">
                        ${priceDecimalFormatter(minimumOrderAmountCents)}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <TruncateWrapper
                      text={description}
                      threshold={PRESET_TRUNCATE_THRESHOLD}
                    />
                  </div>
                  <div className="mt-5">
                    <hr />
                  </div>
                </>
              )}
              {loading && <PlaceholderHeader />}
              <div className="detailed-view--meals pr-xl-5">
                {loading && <PlaceholderMenuCard />}
                {memoizedMenuCategories.map((category, index) =>
                  mealCard(category, preset, `${index}-preset-category`),
                )}

                <div className="detailed-view--group">
                  <div className="items">
                    <h3>{ADDITIONAL_ITEMS}</h3>
                    <AdditionalItemToggle
                      onClickHandler={() => {
                        setShowAdditionalItems(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {currentPresetItem && (
              <PresetItemView
                presetItem={currentPresetItem}
                initialValues={
                  find(
                    checkoutData,
                    presetForm => presetForm.id === currentPresetItem.id,
                  ) || buildCheckoutDataFromPresetItem(currentPresetItem)
                  // because when for adding new item,
                  // initially we don't have checkout data
                }
                chefInfo={getCurrentPresetItemChefInfo}
                onClose={() => {
                  setCurrentPresetItem(null);
                }}
                onSubmit={(presetItem, form) => {
                  updateCheckoutData(presetItem, form);
                }}
              />
            )}
            <div className="col-xl-4 pl-xl-2" style={{ zIndex: 2 }}>
              <Sticky
                stickyClassName="mt-20"
                boundaryElement=".sticky-container"
                hideOnBoundaryHit={false}
              >
                <OrderDetails
                  {...{
                    priceCents,
                    deliveryPriceCents,
                    totalPriceCents,
                    preset,
                    pricePerPersonCents,
                    checkoutData,
                    saveCustomPreset,
                    createGroupOrderForPreset,
                    setCurrentPresetItemFromCheckoutData,
                    onCheckoutClick,
                    preOrderNoticeHour,
                  }}
                  setShowSaveForLaterModal={setShowSaveForLaterModal}
                  showSaveForLaterModal={showSaveForLaterModal}
                  removeCheckoutItem={checkoutItemId => {
                    setRemovePresetId(checkoutItemId);
                  }}
                />
              </Sticky>
            </div>
          </div>
        </div>
        {showAdditionalItems && (
          <AdditionalItemTab
            {...{ chefs, presetItems, setCurrentPresetItem }}
          />
        )}
      </div>
      <section className="total-footer hide-desktop">
        <div className="container">
          <div>
            <h4>Total ${priceDecimalFormatter(totalPriceCents)}</h4>
            <span>{totalQuantity} Items</span>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => {
              onCheckoutClick();
            }}
            type="button"
          >
            Checkout
          </button>
        </div>
      </section>
      <div className="py-5" />
    </main>
  );
};

PresetDetail.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    confirmed: PropTypes.bool,
  }).isRequired,
  showSignInModal: PropTypes.func.isRequired,
  signoutRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user && state.user.user,
});

const mapDispatchToProps = {
  showSignInModal,
  signoutRequest,
};
const connectedPresetDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PresetDetail);

export default withRouter(connectedPresetDetail);
