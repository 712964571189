export const REQUIRED_RECAPCHA = 'Please verify if you are human.';
export const REQUIRED_VALIDATION = 'Please fill in the required field.';
export const REQUIRED_SIGNIN_FOR_CHECKOUT = 'Please Sign In to CheckOut.';
export const REQUIRED_VALIDATION_TOO_BROAD =
  'Your search is too broad, please enter in a street address.';
export const REQUIRED_SIGNIN_FOR_SAVEFORLATER =
  'Please Sign In to Save the CheckOut.';
export const REQUIRED_SIGNIN_FOR_PAYMENT = 'Please Sign In to Pay.';
export const REQUIRED_MIN_DATE = 'Please select valid date.';
export const INVALID_EMAIL = 'Invalid email address';
export const INVALID_OPTION = 'Please select one of the options below';
export const INVALID_INTEGER = 'Please select non negative integer.';
export const INVALID_PHONE_NUMBER = 'Invalid phone number';
// Book Demo page
export const NUMBER_OF_PEOPLE = [
  '0-10',
  '10-25',
  '25-50',
  '50-100',
  '100-150',
  '150-300',
  '300+',
];
export const ORDER_FREQUENCY = [
  'Daily',
  'Weekly',
  'Monthly',
  'Occasionally',
  'One Time Event',
];

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE_FORMAT_SAFARI = 'MMM d yyyy';
export const DEFAULT_TIME_FORMAT = 'hh:mm a';
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd hh:mm a';
export const SAVED_AT_TIME_FORMAT = 'hh:mm a, eee, LLL do';
export const FRIENDLY_DATE_FORMAT = 'eee, LLL do';
