import React from 'react';

const Testimonials = () => {
  return (
    <section>
      <div className="container text-center">
        <h2 className="mb-5">Our Customers Love Us</h2>
        <div className="row">
          <div className="col-xl-4 offset-xl-2 mr-xl-3">
            <div className="home--feedback">
              <img src="/assets/img/home/nicole.jpg" alt="customer_1" />
              <div className="customer-name text-primary mb-3 mt-4">
                Nicole Moonie - Nascent Digital
              </div>
              <p>
                As an Office Manager, this service makes it easier to bring in
                unique lunches to my staff. The variety is great!
              </p>
            </div>
          </div>
          <div className="col-xl-4 ml-xl-3">
            <div className="home--feedback">
              <img src="/assets/img/home/customer_2.png" alt="customer_2" />
              <div className="customer-name text-primary mb-3 mt-4">
                Tatiana Miyamoto - League Inc.
              </div>
              <p>
                We have a large staff (over 200) with a variety of dietary
                sensitivities. Whether it&apos;s a corporate lunch and learn or
                a large off-site event, Catered Club always pulls through and
                provides fresh and memorable catering - most importantly, the
                process itself is fast and easy.
              </p>
            </div>
          </div>
        </div>
        <div className="home--see-all mt-xl-3">
          <a
            href="https://www.trustpilot.com/review/cateredclub.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            See all reviews
          </a>
          &nbsp;on &nbsp;
          <img src="/assets/img/home/trustpilot.svg" alt="trustpilot" />
        </div>
      </div>
    </section>
  );
};
export default Testimonials;
