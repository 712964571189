import React from 'react';
import { Link } from 'react-router-dom';
import VoidLink from './VoidLink';

const Footer = () => {
  return (
    <footer>
      <section className="d-none container footer-menus px-5 py-5 px-xl-4">
        <ul className="footer-menu">
          <li>
            <h5>About</h5>
          </li>
          <li>
            <Link to="/about-us">About us</Link>
          </li>
          <li>
            <VoidLink>Careers</VoidLink>
          </li>
          <li>
            <VoidLink>Contact Us</VoidLink>
          </li>
        </ul>
        <ul className="footer-menu">
          <li>
            <h5>Learn More</h5>
          </li>
          <li>
            <VoidLink>Blog</VoidLink>
          </li>
          <li>
            <VoidLink>Press</VoidLink>
          </li>
          <li>
            <VoidLink>Help</VoidLink>
          </li>
        </ul>
        <ul className="footer-menu">
          <li>
            <h5>Legal </h5>
          </li>
          <li>
            <VoidLink>Terms &amp; Conditions</VoidLink>
          </li>
          <li>
            <VoidLink>Privacy Policy</VoidLink>
          </li>
        </ul>
        <ul className="footer-menu">
          <li>
            <h5>Partners</h5>
          </li>
          <li>
            <VoidLink>Cater with Catered Club</VoidLink>
          </li>
          <li>
            <VoidLink>Partners&apos; Portal</VoidLink>
          </li>
        </ul>
        <ul className="footer-menu">
          <li>
            <h5>Get Started</h5>
          </li>
          <li>
            <VoidLink>Sign Up</VoidLink>
          </li>
          <li>
            <VoidLink>Corporate Members</VoidLink>
          </li>
          <li>
            <VoidLink>Event Planning</VoidLink>
          </li>
        </ul>
      </section>
      <section className="d-none container py-3 py-xl-5 px-0">
        <div className="accordion" id="footer_collapse">
          <div className="card">
            <div
              className="card-header hide-desktop collapsed"
              data-toggle="collapse"
              data-target="#collapse_1"
            >
              <h5 className="mb-0">Discover by city</h5>
              <div className="arrow" />
            </div>
            <div className="card-header hide-mobile">
              <h5 className="mb-0">Discover by city</h5>
            </div>
            <div
              className="collapse d-xl-block"
              id="collapse_1"
              data-parent="#footer_collapse"
            >
              <div className="card-body">
                <ul className="footer-menu">
                  <li className="hide-desktop">
                    <h5>Canada</h5>
                  </li>
                  <li
                    className="hide-mobile"
                    data-toggle="collapse"
                    data-target="#canada"
                  >
                    <h5>Canada</h5>
                    <div className="arrow" />
                  </li>
                  <ul className="collapse show" id="canada">
                    <li>
                      <VoidLink>Toronto</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Kitchener-Waterloo</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Montreal</VoidLink>
                    </li>
                  </ul>
                </ul>
                <ul className="footer-menu">
                  <li className="hide-desktop">
                    <h5>United States</h5>
                  </li>
                  <li
                    className="hide-mobile"
                    data-toggle="collapse"
                    data-target="#usa"
                  >
                    <h5>United States</h5>
                    <div className="arrow" />
                  </li>
                  <ul className="collapse show" id="usa">
                    <li>
                      <VoidLink>Los Angeles</VoidLink>
                    </li>
                    <li>
                      <VoidLink>San Francisco</VoidLink>
                    </li>
                    <li>
                      <VoidLink>New York</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Chicago</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Philadelphia</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Boston</VoidLink>
                    </li>
                    <li>
                      <VoidLink>District of Columbia</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Phoenix</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Austin</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Stamford</VoidLink>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header hide-desktop collapsed"
              data-toggle="collapse"
              data-target="#collapse_2"
            >
              <h5 className="mb-0">Discover by Cuisine</h5>
              <div className="arrow" />
            </div>
            <div className="card-header hide-mobile">
              <h5 className="mb-0">Discover by Cuisine</h5>
            </div>
            <div
              className="collapse d-xl-block"
              id="collapse_2"
              data-parent="#footer_collapse"
            >
              <div className="card-body">
                <div className="row">
                  <ul className="footer-menu col-xl-6">
                    <li>
                      <VoidLink>Ice Cream</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Desserts</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Chinese</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Mexican</VoidLink>
                    </li>
                    <li>
                      <VoidLink>BBQ</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Greek</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Indian</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Healthy</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Breakfast</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Kosher</VoidLink>
                    </li>
                  </ul>
                  <ul className="footer-menu col-xl-6">
                    <li>
                      <VoidLink>European</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Japanese</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Bagels</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Caribbean</VoidLink>
                    </li>
                    <li>
                      <VoidLink>American</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Thai</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Deli</VoidLink>
                    </li>
                    <li>
                      <VoidLink>French</VoidLink>
                    </li>
                    <li>
                      <VoidLink>Italian</VoidLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header hide-desktop collapsed"
              data-toggle="collapse"
              data-target="#collapse_3"
            >
              <h5 className="mb-0">Top Caterers in Toronto</h5>
              <div className="arrow" />
            </div>
            <div className="card-header hide-mobile">
              <h5 className="mb-0">Top Caterers in Toronto</h5>
            </div>
            <div
              className="collapse d-xl-block"
              id="collapse_3"
              data-parent="#footer_collapse"
            >
              <div className="card-body">
                <ul className="footer-menu">
                  <li>
                    <VoidLink>Mika</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Dew Haus</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Via Cibo</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Sud Forno di Terroni</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Milk n Honey</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Nu Bügel</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Amazing Salad</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Kamasutra</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Nourish by Medcan</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Street Shak</VoidLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-header hide-desktop collapsed"
              data-toggle="collapse"
              data-target="#collapse_4"
            >
              <h5 className="mb-0">What We Offer</h5>
              <div className="arrow" />
            </div>
            <div className="card-header hide-mobile">
              <h5 className="mb-0">What We Offer</h5>
            </div>
            <div
              className="collapse d-xl-block"
              id="collapse_4"
              data-parent="#footer_collapse"
            >
              <div className="card-body">
                <ul className="footer-menu">
                  <li>
                    <VoidLink>Breakfast Catering</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Lunch Catering</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Lunch &amp; Learn Catering</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Special Occasion Catering</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Dinner Catering</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Meeting Catering</VoidLink>
                  </li>
                  <li>
                    <VoidLink>Snack Catering</VoidLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container text-center footer-bottom">
        <Link className="logo" to="/">
          <img src="/assets/img/catered_club_logo.svg" alt="logo" />
        </Link>
        <div className="socials my-4">
          <a
            href="http://facebook.com/cateredclub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon icon-facebook">
              <use xlinkHref="#social--spriteIcon-facebook" />
            </svg>
          </a>
          <a
            href="https://twitter.com/cateredclub_yyz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="icon icon-twitter">
              <use xlinkHref="#social--spriteIcon-twitter" />
            </svg>
          </a>
        </div>
        <small className="text-muted">
          © 2017 - {new Date().getFullYear()} Catered Club. All rights reserved.
        </small>
      </section>
    </footer>
  );
};

export default Footer;
