/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import Tab from 'react-bootstrap/Tab';
import { getAdditionalItems, buildPresetItemFromMenu } from 'utils/preset.util';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import './AdditionalItemTab.scss';
import TruncateWrapper from 'components/common/TruncateWrapper';

const AdditionalItemTab = ({ chefs, presetItems, setCurrentPresetItem, shouldScrollIntoView, filterType, mainHeading, }) => {
  const divEl = useRef(null);
  const [activeChef, setActiveChef] = useState(
    chefs && chefs.length > 0 ? chefs[0].id : 0,
  ); // by default show first restaurant
  const [activeCategories, setActiveCategories] = useState(['All', 'All']);

  useEffect(() => {
    if (shouldScrollIntoView) {
      if (divEl.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: divEl.current.offsetTop,
        });
      }
    }
  }, []);

  const memoizedCategories = useMemo(
    () =>
      chefs.map(chef =>
        groupBy(
          getAdditionalItems(sortBy(chef.menus, ['priceCents']), presetItems),
          // Need to show menus based on price
          'categoryName',
        ),
      ),
    [chefs, presetItems],
  );

  const menuCategoryNames = useMemo(
    () =>
      activeChef === chefs[0].id
        ? Object.keys(memoizedCategories[0])
        : Object.keys(memoizedCategories[1]),
    [memoizedCategories, activeChef],
  );

  useEffect(() => {
    if (activeChef === chefs[0].id) {
      if (
        activeCategories[0] !== 'All' &&
        !menuCategoryNames.includes(activeCategories[0])
      ) {
        setActiveCategories(['All', activeCategories[1]]);
      }
    } else if (
      activeCategories[1] !== 'All' &&
      !menuCategoryNames.includes(activeCategories[1])
    ) {
      setActiveCategories([activeCategories[0], 'All']);
    }
  }, [menuCategoryNames, activeChef]);

  return (
    <div ref={divEl} className="detailed-view--additional show">
      <div className="container">
        <div className="col-xl-8 pr-xl-4">
          <div className="header mb-3">
            <h2>{mainHeading}</h2>
            {filterType === 'select' &&
              <div className="right">
                <select
                  className="form-control lg"
                  placeholder="Jump to"
                  value={
                    activeChef === chefs[0].id
                      ? activeCategories[0]
                      : activeCategories[1]
                  }
                  onChange={e => {
                    if (activeChef === chefs[0].id) {
                      setActiveCategories([
                        e.currentTarget.value,
                        activeCategories[1],
                      ]);
                    } else {
                      setActiveCategories([
                        activeCategories[0],
                        e.currentTarget.value,
                      ]);
                    }
                  }}
                >
                  <option value="All">All</option>
                  {menuCategoryNames.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
                <ul className="restaurants">
                  {chefs.map(chef => (
                    <li
                      key={chef.id}
                      className={classnames({
                        active: activeChef === chef.id,
                      })}
                      onClick={() => {
                        setActiveChef(chef.id);
                      }}
                    >
                      <img src={chef.profileImageUrl} alt="chef_image" />
                    </li>
                  ))}
                </ul>
              </div>
            }
            
          </div>
          
          {filterType === 'tabs' &&
            <div className="menu-filter-tabs__wrap-sticky">
              <div className="menu-filter-tabs__wrap-scroll">
                <div className="home--form__tabs menu-filter-tabs__wrap">
{/* Might not need "All" tab if it will work as a navigation (not filter)
                  <button
                    aria-label="tab"
                    href="#"
                    className={`
                      home--form__tab-link menu-filter-tab
                      ${activeCategories[chefs.findIndex(chefLoc => chefLoc.id === activeChef)] === "All" ? 'active' : ''}
                    `} 
                    onClick={e => {
                      e.preventDefault();
                      if (activeChef === chefs[0].id) {
                        setActiveCategories([
                          "All",
                          activeCategories[1],
                        ]);
                      } else {
                        setActiveCategories([
                          activeCategories[0],
                          "All",
                        ]);
                      }
                    }}>
                    All
                  </button>
*/}
                  {menuCategoryNames.map(category => (
                    <button
                      key={category}
                      aria-label="tab"
                      href="#"
                      className={`
                        home--form__tab-link  menu-filter-tab
                        ${activeCategories[chefs.findIndex(chefLoc => chefLoc.id === activeChef)] === category ? 'active' : ''}
                      `} 
                      onClick={e => {
                        e.preventDefault();
                        if (activeChef === chefs[0].id) {
                          setActiveCategories([
                            category,
                            activeCategories[1],
                          ]);
                        } else {
                          setActiveCategories([
                            activeCategories[0],
                            category,
                          ]);
                        }
                      }}>
                      {category}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          }

          <Tab.Container
            activeKey={activeChef}
            onSelect={k => setActiveChef(k)}
          >
            <Tab.Content>
              {chefs.map((chef, index) => (
                <Tab.Pane eventKey={chef.id} key={chef.id}>
                  {map(memoizedCategories[index], (menus, category) => {
                    // if all of the items under a category have been added, then we should show all again
                    if (
                      activeCategories[index] !== 'All' &&
                      activeCategories[index] !== category
                    ) {
                      return null;
                    }
                    return (
                      <div
                        key={`${chef.id}_${category}`}
                        className="detailed-view--additional-section card entree"
                      >
                        <div className="card-header">{category}</div>
                        <div className="card-body">
                          {menus.map(menu => {
                            const {
                              name,
                              mainImageUrl,
                              dietaryTypes,
                              priceCents,
                              description,
                            } = menu;
                            return (
                              <div
                                key={menu.id}
                                className="detailed-view--additional-item"
                              >
                                <div className="left">
                                  <img src={mainImageUrl} 
                                         alt="menu_img" 
                                         onClick={() => {
                                          setCurrentPresetItem(
                                            buildPresetItemFromMenu(menu),
                                          );
                                  }}/>
                                  <div className="info">
                                    <h4>{name}</h4>
                                    {dietaryTypes && (
                                      <div className="tags">
                                        {dietaryTypes
                                          .split(',')
                                          .map(dietaryType => (
                                            <li key={dietaryType}>
                                              {dietaryType}
                                            </li>
                                          ))}
                                      </div>
                                    )}
                                    <TruncateWrapper text={description} />
                                  </div>
                                </div>
                                <div className="right">
                                  <div className="d-flex">
                                    <div className="badge badge-pill badge-primary">
                                      ${priceDecimalFormatter(priceCents)}
                                    </div>
                                  </div>
                                  <button
                                    className="btn btn-outline-primary"
                                    type="button"
                                    onClick={() => {
                                      setCurrentPresetItem(
                                        buildPresetItemFromMenu(menu),
                                      );
                                    }}
                                  >
                                    Add Item
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

AdditionalItemTab.propTypes = {
  shouldScrollIntoView: PropTypes.bool,
  chefs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      menus: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  presetItems: PropTypes.arrayOf(PropTypes.shape({})),
  setCurrentPresetItem: PropTypes.func,
  filterType: PropTypes.string,
  mainHeading: PropTypes.string,
};

AdditionalItemTab.defaultProps = {
  shouldScrollIntoView: true,
  chefs: [],
  presetItems: [],
  setCurrentPresetItem: () => {},
  filterType: 'select',
  mainHeading: 'Additional Items',
};
export default AdditionalItemTab;
