import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const VoidLink = ({ children, onClick, className }) => {
  return (
    <a
      href="/"
      className={classnames(className)}
      onClick={event => {
        event.preventDefault();
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {children}
    </a>
  );
};

VoidLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

VoidLink.defaultProps = {
  children: '',
  className: '',
  onClick: () => {},
};
export default VoidLink;
