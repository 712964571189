import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { getPresetDetail } from 'api/presetApi';
import LoadingAnimation from './LoadingAnimation';
import QuickViewModal from './QuickViewModal';
import MenuCard from './MenuCard';

const MenuSlider = ({
  presets,
  isFavorites,
  isSavedPresets,
  removeHandler,
  isChefs,
}) => {
  const [quickViewModalShown, setQuickViewModalShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [presetId, setPresetId] = useState(null);
  const [preset, setPreset] = useState({});

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: Math.min(presets.length, 4),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(presets.length, 3),
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: Math.min(presets.length, 2),
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (presetId !== null) {
      setLoading(true);
      const fetchData = async () => {
        const preset = await getPresetDetail(presetId);
        setPreset(preset);
        setLoading(false);
      };
      fetchData();
      // fetch preset data
    }
  }, [presetId]);

  const clickHandler = id => {
    setPresetId(id);
    setQuickViewModalShown(true);
  };

  return (
    <>
      {loading && <LoadingAnimation />}
      {!isChefs && quickViewModalShown && !loading && (
        <QuickViewModal
          preset={preset}
          isSavedPresets={isSavedPresets}
          onHide={() => {
            setQuickViewModalShown(false);
          }}
        />
      )}
      <Slider
        className={`slick slick-grid home--carousel wid-${presets.length * 25}`}
        // eslint-disable-next-line
        {...settings}
      >
        {presets &&
          presets.length > 0 &&
          presets.map(preset => {
            return (
              <div
                key={preset.id + preset.slug}
                className="made--meal meal-card slide"
              >
                <MenuCard
                  {...{
                    preset,
                    isFavorites,
                    isSavedPresets,
                    isChefs,
                    removeHandler,
                    clickHandler,
                  }}
                />
              </div>
            );
          })}
      </Slider>
    </>
  );
};

MenuSlider.propTypes = {
  presets: PropTypes.arrayOf(PropTypes.any),
  isSavedPresets: PropTypes.bool,
  isFavorites: PropTypes.bool,
  isChefs: PropTypes.bool,
  removeHandler: PropTypes.func,
};

MenuSlider.defaultProps = {
  presets: [],
  isFavorites: false,
  isSavedPresets: false,
  isChefs: false,
  removeHandler: () => {},
};

export default memo(MenuSlider);
