import React from 'react';
import PropTypes from 'prop-types';
import { topBarSearchFocused } from 'redux/actions/searchActions';
import VoidLink from 'components/common/VoidLink';
import { connect } from 'react-redux';

const PresetsFilter = ({ topBarSearchFocused }) => {
  return (
    <div className="made--header mt-0 mb-2">
      <VoidLink
        className="btn btn-link"
        onClick={() => {
          topBarSearchFocused(true);
        }}
      >
        Search by Cuisine Type
      </VoidLink>
    </div>
  );
};

const mapDispatchToProps = {
  topBarSearchFocused,
};

PresetsFilter.propTypes = {
  topBarSearchFocused: PropTypes.func.isRequired,
};

const connectedPresetsFilter = connect(
  null,
  mapDispatchToProps,
)(PresetsFilter);

export default connectedPresetsFilter;
