import { resetPassword } from 'api/authApi';
import * as types from './actionTypes';
import { apiCallError } from './apiStatusActions';

export function signupSuccess() {
  return { type: types.USER_SIGNUP_SUCCESS };
}

export function signinSuccess(userData) {
  return { type: types.USER_SIGNIN_SUCCESS, userData };
}

export function signoutSuccess() {
  return { type: types.USER_SIGNOUT_SUCCESS };
}

export function signinRequest(userData) {
  return async dispatch => {
    dispatch(signinSuccess(userData));
  };
}

export function resetPasswordRequest(data) {
  return async dispatch => {
    try {
      await resetPassword(data);
      dispatch({ type: types.RESET_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch({ type: types.RESET_PASSWORD_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function signoutRequest() {
  return async dispatch => {
    dispatch(signoutSuccess());
  };
}
