/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Geocode from 'react-geocode';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import addBusinessDays from 'date-fns/addBusinessDays';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { connect } from 'react-redux';

import FormError from 'components/common/FormError';
import DatePickerField from 'components/common/DatePickerField';
import AddressPickerField from 'components/common/AddressPickerField';
import {
  REQUIRED_VALIDATION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  REQUIRED_MIN_DATE,
} from 'consts/form';
import './index.scss';
import { getDeliveryInfo, setDeliveryInfo } from 'utils/address.util';

const queryString = require('query-string');

const today = new Date();

export const validationSchema = yup.object().shape({
  address: yup.string().required(REQUIRED_VALIDATION),
  city: yup.string().required(REQUIRED_VALIDATION),
  date: yup
    .date()
    .typeError(REQUIRED_VALIDATION)
    .required(REQUIRED_VALIDATION)
    .min(
      new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      REQUIRED_MIN_DATE,
    )
    .notOneOf([null], REQUIRED_VALIDATION),
  time: yup
    .date()
    .typeError(REQUIRED_VALIDATION)
    .required(REQUIRED_VALIDATION)
    .notOneOf([null], REQUIRED_VALIDATION),
});

export const setInitialValues = (defaultAddress = '') => {
  const deliveryInfo = getDeliveryInfo();

  const deliveryDate = deliveryInfo.delivery_date
    ? parse(deliveryInfo.delivery_date, DEFAULT_DATE_FORMAT, new Date())
    : addBusinessDays(new Date(), 3);

  const dateString = deliveryDate.toISOString().slice(0, 10);
  const deliveryTime = deliveryInfo.delivery_time
    ? parse(
        `${dateString} ${deliveryInfo.delivery_time}`,
        DEFAULT_DATE_TIME_FORMAT,
        new Date(),
      )
    : parse(`${dateString} 11:30 AM`, DEFAULT_DATE_TIME_FORMAT, new Date());

  return {
    address: defaultAddress || deliveryInfo.address || '', // get these fields from the local storage
    city: 'Toronto',
    date: deliveryDate,
    time: deliveryTime,
  };
};

export const geoCodeOnSubmit = async (
  form,
  onSubmit,
  setInlineDeliveryInfo = () => {},
) => {
  // geocode first
  const params = {
    delivery_date: format(form.date, DEFAULT_DATE_FORMAT),
    delivery_time: format(form.time, DEFAULT_TIME_FORMAT),
    address: form.address,
  };

  console.log('params', params);
  try {
    const { results } = await Geocode.fromAddress(form.address);
    const { lat, lng } = results[0].geometry.location;
    console.log(lat, lng);
    params.lat = lat;
    params.lng = lng;
  } catch (e) {
    console.log('---> geocode failed');
    // just use plain address
    params.city = form.city;
  }
  localStorage.setItem('params', JSON.stringify(params));

  setDeliveryInfo(null, params); // set entire item
  setInlineDeliveryInfo({
    date: form.date,
    time: form.time,
    address: form.address,
  }); // may need to update DeliveryInfoFormInline state

  onSubmit(queryString.stringify(params));
};

const DeliveryInfoForm = ({ onSubmit, defaultAddress, receivingMethod }) => {
  const initialValues = setInitialValues(defaultAddress);

  return (
    <Formik
      {...{ initialValues, validationSchema }}
      validateOnChange
      validateOnBlur
      onSubmit={form => geoCodeOnSubmit(form, onSubmit)}
    >
      {({ setFieldValue, values, handleBlur, setFieldTouched }) => (
        <Form className="row">
          <div className="form-group col-xl-12">
            <label htmlFor="address">
              Enter {receivingMethod === 'delivery' ? 'delivery' : 'your'}{' '}
              address
            </label>

            <div className="input-with-icons mb-2 ii-left">
              <AddressPickerField
                className="form-control"
                name="address"
                placeholder="Enter delivery street address"
                value={values.address}
                onChange={setFieldValue}
                onFocus={() => {
                  setFieldTouched('address');
                }}
                onBlur={e => {
                  handleBlur(e);
                }}
              />
              <div className="input-prepend">
                <svg className="icon icon-location-arrow">
                  <use xlinkHref="#spriteIcon-location-arrow" />
                </svg>
              </div>
              <FormError name="address" />
            </div>
          </div>
          {/* <div className="form-group col-xl-4">
            <label className="disabled" htmlFor="city">
              Choose your city
            </label>
            <div className="input-with-icons mb-2 ii-left custom-select-wrapper">
              <Field
                as="select"
                className="form-control lg gray custom-select"
                name="city"
              >
                <option value="Toronto">Toronto</option>
              </Field>
              <div className="input-prepend">
                <svg className="icon icon-location-point">
                  <use xlinkHref="#spriteIcon-location-point" />
                </svg>
              </div>
            </div>
          </div> */}
          <div className="form-group col-xl-9 pr-xl-4">
            <label className="disabled" htmlFor="date">
              When is your order for
            </label>
            <div className="field-group">
              <div className="datepicker-container input-with-icons mb-2 ii-right">
                <DatePickerField
                  name="date"
                  className="datepicker-home--left"
                  value={values.date}
                  onChange={setFieldValue}
                  onFocus={() => {
                    setFieldTouched('date');
                  }}
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
                <FormError name="date" />
                <div className="input-append">
                  <svg className="icon icon-calender">
                    <use xlinkHref="#spriteIcon-calender" />
                  </svg>
                </div>
              </div>
              <div className="datepicker-container input-with-icons mb-2 ii-right">
                <DatePickerField
                  name="time"
                  className="datepicker-home--right"
                  showTimeSelect
                  showTimeSelectOnly
                  value={values.time}
                  onChange={setFieldValue}
                  onFocus={() => {
                    setFieldTouched('time');
                  }}
                  onBlur={e => {
                    handleBlur(e);
                  }}
                />
                <FormError name="time" />
                <div className="input-append">
                  <svg className="icon icon-clock">
                    <use xlinkHref="#spriteIcon-clock" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group d-flex align-items-end col-xl-3">
            <button type="submit" className="btn btn-primary btn-block mb-2">
              GO
              <svg className="icon icon-right-arrow-fat">
                <use xlinkHref="#spriteIcon-right-arrow-fat" />
              </svg>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

DeliveryInfoForm.propTypes = {
  defaultAddress: PropTypes.string,
  onSubmit: PropTypes.func,
  searchBy: PropTypes.string,
  idPrefix: PropTypes.string,
  receivingMethod: PropTypes.string,
};

DeliveryInfoForm.defaultProps = {
  defaultAddress: '',
  onSubmit: () => {},
};

const mapStateToProps = state => {
  return {
    receivingMethod: state.receivingMethod,
  };
};

const connectedDeliveryInfoForm = connect(mapStateToProps)(DeliveryInfoForm);

export default connectedDeliveryInfoForm;
