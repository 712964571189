import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import ReactHtmlParser from 'react-html-parser';

import { TRUNCATE_DEFAULT_THRESHOLD } from 'consts/presets';
import VoidLink from './VoidLink';

const TruncateWrapper = ({ threshold, text }) => {
  const [extended, setExtended] = useState(false);
  const parsedText = useMemo(
    () => text && text.replace(/(\r\n|\n|\r)/gm, '<br />'),
    [text],
  );

  const memoizedTruncatedText = useMemo(
    () =>
      truncate(parsedText, {
        length: threshold,
        omission: '...',
      }),
    [parsedText],
  );

  return (
    <>
      {ReactHtmlParser(extended ? parsedText : memoizedTruncatedText)}{' '}
      {!extended && parsedText && parsedText.length > threshold && (
        <VoidLink
          className="btn-link"
          onClick={() => {
            setExtended(true);
          }}
        >
          more
        </VoidLink>
      )}
      {extended && parsedText.length > threshold && (
        <VoidLink
          className="btn-link"
          onClick={() => {
            setExtended(false);
          }}
        >
          less
        </VoidLink>
      )}
    </>
  );
};

TruncateWrapper.propTypes = {
  threshold: PropTypes.number,
  text: PropTypes.string,
};

TruncateWrapper.defaultProps = {
  threshold: TRUNCATE_DEFAULT_THRESHOLD,
  text: '',
};
export default TruncateWrapper;
