import React from 'react';

const MenuCardPlaceholder = () =>
  Array(8)
    .fill(0)
    .map((value, key) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`${value}-${key}`}
        className="made--meal meal-card slide slide--25"
      >
        <div className="card-placeholder">
          <div className="cover">
            <div className="image" />
            <div className="restaurant" />
          </div>
          <div className="info">
            <div className="title" />
            <ul>
              <li />
              <li />
            </ul>
          </div>
        </div>
      </div>
    ));

export default MenuCardPlaceholder;
