import React from 'react';
import PropTypes from 'prop-types';

const GetStarted = ({ children }) => {
  return (
    <section className="container mt-3">
      <div className="col-xl-8 offset-xl-2 home--bottom">
        <h2 className="mb-4 mb-xl-5">Get ready to get started?</h2>
        <div className="decoration-dots hide-mobile">
          <img src="/assets/img/home/dots_h.svg" alt="dots_h" />
        </div>
        {children}
        <div className="decoration-bottom hide-mobile">
          <img
            src="/assets/img/home/decoration_2.jpg"
            srcSet="/assets/img/home/decoration_2@2x.jpg 2x"
            width={714}
            alt="decoration_2"
          />
        </div>
      </div>
    </section>
  );
};

GetStarted.propTypes = {
  children: PropTypes.node,
};

GetStarted.defaultProps = {
  children: null,
};

export default GetStarted;
