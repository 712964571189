import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchReducer(
  state = initialState.searchResult,
  action,
) {
  switch (action.type) {
    case types.TOP_BAR_SEARCH_RESULT_SUCCESS:
      return { ...state, presets: action.presets, q: action.q };
    case types.TOP_BAR_SEARCH_INPUT_FOCUSED:
      return { ...state, inputFocused: action.isFocused };
    default:
      return state;
  }
}
