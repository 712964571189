/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { setDeliveryInfo } from 'utils/address.util';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { updateReceivingMethod } from 'redux/actions/receivingMethodActions';
import { validate } from 'api/authApi';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import FormError from 'components/common/FormError';
import DatePickerField from 'components/common/DatePickerField';
import AddressPickerField from 'components/common/AddressPickerField';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  FRIENDLY_DATE_FORMAT,
  REQUIRED_VALIDATION_TOO_BROAD,
} from 'consts/form';
import { validationSchema, setInitialValues, geoCodeOnSubmit } from './index';
import './index.scss';

const DeliveryInfoFormInline = ({
  onSubmit,
  defaultAddress,
  isAbleToChangeReceivingMethod,
  receivingMethod,
  updateReceivingMethod,
}) => {
  const initialValues = setInitialValues(defaultAddress);
  const [showForm, setShowForm] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleLS, setToggleLS] = useState(false);
  const [dataLS, setDataLS] = useState(null);
  const [inlineDeliveryInfo, setInlineDeliveryInfo] = useState(
    setInitialValues(defaultAddress),
  );

  useEffect(() => {
    (async () => {
      const deliveryInfo = localStorage.getItem('jb-delivery-info');
      const parsedInfo = JSON.parse(deliveryInfo);
      setDataLS({ ...parsedInfo });
      if (!parsedInfo?.address) {
        const userInfo = await validate();
        if (userInfo?.address1) {
          await setInlineDeliveryInfo(prev => ({
            ...prev,
            address: userInfo.address1,
          }));
          if (userInfo.latitude && userInfo.longitude) {
            await setDataLS(prev => ({
              ...prev,
              lat: userInfo.latitude,
              lng: userInfo.longitude,
            }));
          } else {
            await geocodeByAddress(userInfo.address1).then(results =>
              getLatLng(results[0]).then(prop => {
                setDataLS(prev => ({ ...prev, ...prop }));
              }),
            );
          }
          await setDataLS(prev => ({ ...prev, address: userInfo.address1 }));
        } else {
          await setShowForm(true);
        }
      }
      if (!(parsedInfo?.delivery_date || parsedInfo?.delivery_time)) {
        setDataLS(prev => ({
          ...prev,
          delivery_date: format(
            new Date(inlineDeliveryInfo.date),
            DEFAULT_DATE_FORMAT,
          ),
          delivery_time: format(
            new Date(inlineDeliveryInfo?.time),
            DEFAULT_TIME_FORMAT,
          ),
        }));
      }
      setToggleLS(true);
    })();
  }, []);

  useEffect(() => {
    if (
      toggleLS &&
      dataLS &&
      JSON.stringify(dataLS) !== localStorage.getItem('jb-delivery-info')
    ) {
      localStorage.setItem('jb-delivery-info', JSON.stringify(dataLS));
      localStorage.setItem('params', JSON.stringify(dataLS));
      setDataLS(null);
      setToggleLS(false);
    }
  }, [toggleLS]);

  function updateReceivingMethodWithCheck(value) {
    if (value === receivingMethod) {
      return;
    }
    updateReceivingMethod(value);
  }

  return (
    <section className="order-form-inline show-form">
      {!showForm && (
        <div
          className="delivery-info alert alert-info"
          style={{ display: 'block' }}
        >
          <span className="mr-3">
            <span>
              {receivingMethod === 'delivery' && (
                <span>
                  Deliver to <b>{inlineDeliveryInfo.address}</b> @{' '}
                </span>
              )}
              {receivingMethod === 'pickup' && (
                <span>
                  Pick up near <b>{inlineDeliveryInfo.address}</b> @{' '}
                </span>
              )}
            </span>

            <b>{format(inlineDeliveryInfo.time, DEFAULT_TIME_FORMAT)}</b>
            {', '}
            <b>{format(inlineDeliveryInfo.date, FRIENDLY_DATE_FORMAT)}</b>
          </span>

          {isAbleToChangeReceivingMethod && (
            <div
              className="btn-group mr-3"
              role="group"
              aria-label="Receiving Method"
            >
              <button
                type="button"
                className={`
                  btn btn-sm btn-outline-info
                  ${receivingMethod === 'delivery' ? 'selected' : ''}
                `}
                onClick={() => updateReceivingMethodWithCheck('delivery')}
              >
                Delivery
              </button>
              <button
                type="button"
                className={`
                  btn btn-sm btn-outline-info
                  ${receivingMethod === 'pickup' ? 'selected' : ''}
                `}
                onClick={() => updateReceivingMethodWithCheck('pickup')}
              >
                Pick up
              </button>
            </div>
          )}

          <button
            type="button"
            className="btn btn-sm btn-outline-info hide-mobile toggle-form"
            onClick={() => setShowForm(true)}
          >
            Update Details
          </button>
          <svg
            onClick={() => setShowForm(true)}
            className="icon icon-pencil hide-desktop toggle-form"
          >
            <use xlinkHref="#spriteIcon-pencil" />
          </svg>
        </div>
      )}
      {showForm && (
        <div className="container order-form pb-0 mb-0">
          <Formik
            {...{ initialValues, validationSchema }}
            validateOnChange
            validateOnBlur
            onSubmit={form => {
              geoCodeOnSubmit(form, onSubmit, setInlineDeliveryInfo);
              setShowForm(false);
            }}
          >
            {({ setFieldValue, values, handleBlur, setFieldTouched }) => (
              <Form className="row" style={{ marginBottom: -20 }}>
                <div className="form-group col-xl-5">
                  <label htmlFor="delivery_address">
                    Enter Street Address for Delivery address
                  </label>

                  <div className="input-with-icons mb-2 ii-left">
                    <AddressPickerField
                      id="delivery_address"
                      className="form-control"
                      name="address"
                      placeholder="Enter street address for delivery"
                      value={values.address}
                      onChange={setFieldValue}
                      setToggle={setToggle}
                      onFocus={() => {
                        setFieldTouched('address');
                      }}
                    />
                    <div className="input-prepend">
                      <svg className="icon icon-location-arrow">
                        <use xlinkHref="#spriteIcon-location-arrow" />
                      </svg>
                    </div>
                    {toggle ? (
                      <FormError message={REQUIRED_VALIDATION_TOO_BROAD} />
                    ) : (
                      <FormError name="address" />
                    )}
                  </div>
                </div>
                {/* <div className="form-group col-xl-2">
                  <label className="disabled" htmlFor="city">
                    Choose your city
                  </label>
                  <div className="input-with-icons mb-2 ii-left custom-select-wrapper">
                    <Field
                      as="select"
                      className="form-control lg gray custom-select"
                      name="city"
                    >
                      <option value="Toronto">Toronto</option>
                    </Field>
                    <div className="input-prepend">
                      <svg className="icon icon-location-point">
                        <use xlinkHref="#spriteIcon-location-point" />
                      </svg>
                    </div>
                  </div>
                </div> */}
                <div className="form-group col-xl-5">
                  <label className="disabled" htmlFor="date">
                    When is your order for
                  </label>
                  <div className="field-group">
                    <div className="datepicker-container input-with-icons mb-2 ii-right">
                      <DatePickerField
                        id="checkout-date"
                        name="date"
                        className="datepicker-home--left"
                        value={values.date}
                        onChange={(name, val) => {
                          // update date in the delivery info storage as well
                          const deliveryDate = format(val, DEFAULT_DATE_FORMAT);
                          setDeliveryInfo('delivery_date', deliveryDate);
                          setFieldValue(name, val);
                        }}
                        onFocus={() => {
                          setFieldTouched('date');
                        }}
                        onBlur={e => {
                          handleBlur(e);
                        }}
                      />
                      <FormError name="date" />
                      <div className="input-append">
                        <svg className="icon icon-calender">
                          <use xlinkHref="#spriteIcon-calender" />
                        </svg>
                      </div>
                    </div>
                    <div className="datepicker-container input-with-icons mb-2 ii-right">
                      <DatePickerField
                        id="checkout-time"
                        name="time"
                        className="datepicker-home--right"
                        showTimeSelect
                        showTimeSelectOnly
                        value={values.time}
                        onChange={(name, val) => {
                          // update the stored data as well
                          const deliveryTime = format(val, DEFAULT_TIME_FORMAT);
                          setDeliveryInfo('delivery_time', deliveryTime);
                          setFieldValue(name, val);
                        }}
                        onFocus={() => {
                          setFieldTouched('time');
                        }}
                        onBlur={e => {
                          handleBlur(e);
                        }}
                      />
                      <FormError name="time" />
                      <div className="input-append">
                        <svg className="icon icon-clock">
                          <use xlinkHref="#spriteIcon-clock" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex align-items-end col-xl-2 toggle-form">
                  <button
                    type="submit"
                    disabled={!values.address}
                    className="btn btn-primary btn-block mb-2"
                  >
                    SAVE
                    <svg className="icon icon-right-arrow-fat">
                      <use xlinkHref="#spriteIcon-right-arrow-fat" />
                    </svg>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </section>
  );
};

DeliveryInfoFormInline.propTypes = {
  defaultAddress: PropTypes.string,
  onSubmit: PropTypes.func,
  isAbleToChangeReceivingMethod: PropTypes.bool,
  receivingMethod: PropTypes.string.isRequired,
  updateReceivingMethod: PropTypes.func.isRequired,
  restaurantAddress: PropTypes.string,
};

DeliveryInfoFormInline.defaultProps = {
  defaultAddress: '',
  onSubmit: () => {},
  isAbleToChangeReceivingMethod: false,
  restaurantAddress: 'selected restaurant',
};

const mapStateToProps = state => {
  return {
    receivingMethod: state.receivingMethod,
  };
};

const mapDispatchToProps = {
  updateReceivingMethod,
};

const connectedDeliveryInfoFormInline = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryInfoFormInline);

export default connectedDeliveryInfoFormInline;
