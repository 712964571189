import React from 'react';
import './index.scss';
import Accordion from 'components/common/Accordion/Accordion';
import { useLocation } from 'react-router-dom';

const Faq = () => {
  const { hash } = useLocation();
  const data = [
    {
      title: 'How do I order food on behalf of my team?',
      link: '#order-for-team',
      content:
        'If you are looking to order food for your team you can accomplish this easily by creating a catered order. Watch this short instructional video on how to create a catered order: </br></br></br></br>Catered orders allow you to select items on behalf of your team and place the order. You can select whether you want the food individually packaged and labelled or served family/buffett-style in trays and labelled.</br></br>Catered orders are great for when your team members are busy and would rather not trouble them with making their choice. You can choose all of the food for the order and place the order all in one easy process.',
    },
    {
      title:
        'How do I create a group order, so that my team members can choose items themselves?',
      link: '#group-order',
      content:
        'If you would like to allow your team members to choose their meals themselves this can be easily accomplished by creating a Group Order.</br></br>You can set the budget that each team member can spend, the delivery date/time and invite the team members to join the order.</br></br>They will receive an initial invite email to join the order. They will also receive reminders to place their order before the order is processed.</br></br>Group orders are typically automatically processed 24-48 hours before the scheduled delivery date/time, therefore it&apos;s best to create group orders 4-5 or more days in advance, which gives your team members enough time to join and submit their orders.eter; </br></br>You can also share the below video with team members on how they can join the group order and submit their order(s):</br></br>Group orders are great when you have many team members with unique preferences and dietary restrictions and would prefer that they choose their own meal. It takes the work off of your plate in deciding what everyone should eat and allows them to make their own choices.</br></br>You simply only have to make the choice of restaurant for each order and create the group orders and invite the team members, the rest of the process is up to each individual team member.',
    },
    {
      title: 'How do I add or remove items from a catered order?',
      link: '#remove-items-catered-order',
      content:
        'To add items to a group order simply scroll down to the menu section of the vendor&apos;s listing and click on "Add Item":</br></br>Once you click "Add Item" you will be able to select the quantity of the item that you would like to add to your order by clicking the + icon:</br></br>To remove items that you have added to your cart on a catered order, simply click on the little X beside the item in your cart.',
    },
    {
      title:
        "How can I edit (add/remove items or change delivery details) or cancel a Catered Order once it's already been submitted?",
      link: '#editing-catered-order',
      content:
        'If you need to add or remove items to a catered order that has already been submitted and accepted by the vendor you are able to do this as long as the order hasn&apos;t been processed yet.</br></br>You can edit (add/remove items) as well as update the delivery address and date/time, contact info, and edit special delivery instructions. Or you can cancel a catered order at anytime before the "Minimum order notice" required period is up. For example, some restaurants require 24 hours notice, others 48 hours etc.</br></br>If you place an order on a Monday for Wednesday at 11:30am delivery and the vendor requires 24 hours notice then you will be able to edit and or cancel the catered order anytime up until Tuesday at 11:30am.</br></br>At that point the order will be processed and finalized.</br></br>To edit or cancel a catered order sign in to your Catered Club account and click on your name in the upper right hand corner and click on Orders:</br></br>Here you will be able to view all Up Coming orders. Simply locate the order that you need to edit or cancel and click on "View Order":</br></br>Then you will be able to click "Edit Order" or "Cancel Order"</br></br>You will be able to edit the delivery date/time, address, contact info and many more details of an order and also add/remove items to an order.',
    },
    {
      link: '#editing-group-order',
      title:
        "How can I edit (add/remove items or change delivery details) or cancel a Group Order once it's already been submitted?",
      content:
        'If you need to add or remove items to a group order order that has already been submitted and accepted by the vendor you are able to do this as long as the order hasn&apos;t been processed yet.</br></br>You can edit (add/remove items) as well as update the delivery address and date/time, contact info, and edit special delivery instructions. Or you can cancel a group order at anytime before the "Minimum order notice" required period is up. For example, some restaurants require 24 hours notice, others 48 hours etc.</br></br>If you place an order on a Monday for Wednesday at 11:30am delivery and the vendor requires 24 hours notice then you will be able to edit and or cancel the group order anytime up until Tuesday at 11:30am.</br></br>At that point the order will be processed and finalized.</br></br>To edit or cancel a group order sign in to your Catered Club account and click on your name in the upper right hand corner and click on Orders:</br></br>Here you will be able to view all Up Coming orders. Simply locate the order that you need to edit or cancel and click on "View Order":</br></br>On this page you can either click on the link in the upper left side of the pop-up window, this will take you to the group order to allow you to login and cancel your submitted item if you are no longer going to be in attendance on the day of this delivery.</br></br>Or you can simply remove the item from your cart and add another item and click "Update Order" to resubmit your new order.</br></br>Or if you need to cancel or edit the entire group order, for example if you need to change delivery info or invite more members by uploading a new CSV file or cancel the order you can do so by clicking the "Edit Order" link on the below page, or "Cancel Order" and this will edit or cancel the entire group order.</br></br>All team members who joined the group order will be notified that their orders have been cancelled and the vendor will also be notified.',
    },
    {
      link: '#automatic-receipts-for-orders',
      title: 'When will I receive the receipt for my order?',
      content:
        'You will receive the receipt once the catered and or group order is processed and your card is charged. Orders are typically processed 24-48 hours before the scheduled delivery date and time. Once the order is procossed it is finalized, however you are able to make changes and or cancel the orders before that time.',
    },
    {
      link: '#emailed-receipts',
      title: 'Are receipts emailed to me?',
      content:
        'Yes all receipts are emailed to you and also texted to you once the order has been officially processed and when your card has been charged.',
    },
    {
      link: '#lost-receipts',
      title:
        'What if I delete the receipt email is there any other way to retrieve my receipt?',
      content:
        'Yes you can retrieve your past order receipts at anytime by signing into your Catered Club acount and visiting the Orders page as seen below:</br></br>Then click on "Past" Orders:</br></br>Then locate the order based on the Delivery date, or on when the order was placed and then click View Order and here you can Print or Download the PDF of the order receipt.',
    },
    {
      link: '#editing-processed-order',
      title:
        'Can I add or remove items from an order after the "Minimum Hour Notice" required by vendor? Or after an order has been processed?',
      content:
        'Unfortunately no. Once the order has been processed it becomes finalized and at that point you will not be able to add/remove items or cancel the order. Therefore, it is best that you are always aware of this and make any changes prior to the order being processed.',
    },
    {
      link: '#late-deliveries',
      title: 'Who do I contact if the delivery is late?',
      content:
        'You will receive a text message 30 minutes before the delivery arrival with the contact info of the vendor to check up on the delivery status if needed.',
    },
  ];

  return (
    <div className="container faq">
      {(data || []).map((item, index) => (
        <div className="accordionItemWrapp">
          <Accordion
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            hash={hash}
            link={item?.link}
            topHeader={`${index + 1}. ${item?.title}`}
            content={item?.content}
          />
        </div>
      ))}
    </div>
  );
};

export default Faq;
