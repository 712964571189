import sumBy from 'lodash/sumBy';
import concat from 'lodash/concat';
import intersectWith from 'lodash/intersectionWith';
import differenceWith from 'lodash/differenceWith';

export function buildCheckoutDataFromPresetItem(presetItem) {
  const {
    id,
    presetMenuCategoryName,
    chefId,
    menuId,
    quantity,
    specialInstruction,
    menuItemsMapping,
    menuItems,
    priceCents,
    name,
  } = presetItem;
  return {
    id,
    name,
    presetMenuCategoryName,
    chefId,
    menuId,
    quantity,
    specialInstruction,
    menuItemsMapping,
    menuItems,
    priceCents,
  };
}
export function buildFormData(preset) {
  const { presetItems } = preset;
  return presetItems.map(presetItem =>
    buildCheckoutDataFromPresetItem(presetItem),
  );
}

export const buildPaymentIntentPresetItems = presetItems => {
  const newPresetItems = presetItems.map(presetItem => {
    const {
      id,
      menuId,
      name,
      quantity,
      specialInstruction,
      menuItemsMapping,
    } = presetItem;
    const newPresetItem = {
      id: typeof id === 'string' ? null : id,
      name,
      menuId,
      quantity,
      specialInstruction,
      menuItemsMapping,
    };

    return newPresetItem;
  });

  return newPresetItems;
};

export const buildPresetItemFromMenu = menu => {
  const { menuItemsMapping } = menu;
  const presetItem = {
    ...menu,
    menuId: menu.id,
    chefId: menu.chefId,
    id: `Additional_${menu.id}`, // pass empty to the backend
    presetMenuCategoryName: 'Additional Items',
    quantity: menu.minOrderAmount,
    menuItemsMapping:
      menu.menuType === 'single'
        ? { ...menuItemsMapping }
        : {
            ...menuItemsMapping,
            [Object.keys(menuItemsMapping)[0]]: menu.minOrderAmount,
          },
  };

  return presetItem;
};

export const recoverAdditionalItems = (
  chefs,
  presetItems,
  userCheckoutData,
) => {
  const addedItems = differenceWith(
    userCheckoutData,
    presetItems,
    (cartData, presetItem) => cartData.id === presetItem.id,
  );

  // concat all chef menus
  const menus = concat(...chefs.map(chef => chef.menus));
  const recoveredItems = intersectWith(
    menus,
    addedItems,
    (menu, item) => menu.id === item.menuId,
  );
  return recoveredItems.map(menu => buildPresetItemFromMenu(menu));
};

export const getAdditionalItems = (menus, presetItems) => {
  // find menus that are not already inside presetItems
  return differenceWith(
    menus,
    presetItems,
    (menu, presetItem) => menu.id === presetItem.menuId,
  );
};

export const splitItems = presetItems => {
  if (presetItems && presetItems.length > 0) {
    const categories = [];
    let currentCategory = [];
    for (let i = 0; i < presetItems.length; i += 1) {
      if (
        i === 0 ||
        presetItems[i].presetMenuCategoryName ===
          presetItems[i - 1].presetMenuCategoryName
      ) {
        // add new categories
        currentCategory.push(presetItems[i]);
      } else {
        categories.push(currentCategory);
        currentCategory = [presetItems[i]];
      }
    }

    if (currentCategory.length > 0) {
      categories.push(currentCategory);
    }

    return categories;
  }

  return [];
};

export const getPresetItemQuantity = presetItem => {
  // presetItem change form data
  const { menuItems, menuItemsMapping, quantity } = presetItem;
  if (menuItems && menuItems.length > 0) {
    // get menu mappings
    return (
      sumBy(Object.values(menuItemsMapping), mapping =>
        parseInt(mapping, 10),
      ) || 0
    );
  }

  return quantity;
};

export const getPresetItemTotalPrice = presetItem => {
  // presetItem change form data
  const { menuItems, menuItemsMapping, quantity, priceCents } = presetItem;
  if (menuItems && menuItems.length > 0) {
    // get menu mappings
    return (
      sumBy(
        menuItems,
        menuItem =>
          (parseInt(menuItemsMapping[menuItem.id], 10) || 0) *
          menuItem.priceCents,
      ) || 0
    );
  }

  return priceCents * quantity;
};

export const getPresetSubTotalPrice = presetItems => {
  return sumBy(presetItems, presetItem => getPresetItemTotalPrice(presetItem));
};

export const getChefOrderAmountFromPreset = (presetItems, chefId) => {
  return sumBy(presetItems, presetItem =>
    presetItem.chefId === chefId ? getPresetItemTotalPrice(presetItem) : 0,
  );
};

export const getTotalQuantity = checkoutData => {
  if (!checkoutData || checkoutData.length === 0) {
    return 0;
  }
  return sumBy(checkoutData, presetItem => getPresetItemQuantity(presetItem));
};
