import React from 'react';
import './index.scss';

const FavoriteMarker = () => {
  return (
    <div className="favorite-marker">
      <svg className="icon icon-heart">
        <use xlinkHref="#spriteIcon-heart-red" />
      </svg>
    </div>
  );
};

export default FavoriteMarker;
