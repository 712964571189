import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Elements } from 'react-stripe-elements';
import FormSelect from 'components/common/FormSelect';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import InjectedCheckoutForm from './InjectedCheckoutForm';

const StripeFormModal = ({
  show,
  onClose,
  orderHandler,
  loadingHandler,
  totalPrice,
  cateringOrder,
  clearCateringOrder,
  setSaveCard,
}) => {
  const [active, setActive] = useState(null);
  useEffect(() => {
    return () => clearCateringOrder();
  }, []);
  useEffect(() => {
    if (cateringOrder && !cateringOrder?.savedCards?.length) {
      setActive('add');
    }
  }, [cateringOrder]);

  const handleSubmit = () => {
    if (active && cateringOrder.purchaseId !== 'add') {
      orderHandler(active);
    }
  };

  const savedCardsOptions = cateringOrder?.savedCards?.map(item => ({
    label: item.name,
    value: item.paymentMethodId,
  }));

  return (
    <Modal show={show} onHide={onClose}>
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      />
      <Modal.Header>
        <h3>Complete Order</h3>
      </Modal.Header>
      <Modal.Body className="pb-5">
        <>
          {cateringOrder?.savedCards?.length ? (
            <FormSelect
              name="numPeopleRange"
              placeholder="Pay with"
              className="mb-5"
              options={[
                ...savedCardsOptions,
                { label: 'Add new card', value: 'add' },
              ]}
              onChange={(_, value) => setActive(value)}
            />
          ) : null}
          {!cateringOrder ? <LoadingAnimation /> : null}
          {active === 'add' && cateringOrder ? (
            <>
              <Elements>
                <InjectedCheckoutForm
                  {...{
                    orderHandler,
                    loadingHandler,
                    totalPrice,
                    setSaveCard,
                  }}
                />
              </Elements>
            </>
          ) : (
            <button
              type="button"
              disabled={!active}
              onClick={handleSubmit}
              className="btn btn-block btn-primary"
            >
              Pay ${priceDecimalFormatter(totalPrice)}
            </button>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

StripeFormModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  orderHandler: PropTypes.func,
  loadingHandler: PropTypes.func,
  setSaveCard: PropTypes.func,
  totalPrice: PropTypes.number.isRequired,
  clearCateringOrder: PropTypes.func.isRequired,
  cateringOrder: PropTypes.shape({
    savedCards: PropTypes.arrayOf(
      PropTypes.objectOf({
        name: PropTypes.string,
        payment_method_id: PropTypes.string,
      }),
    ),
    clientSecret: PropTypes.string,
    purchaseId: PropTypes.number.isRequired,
  }).isRequired,
};

StripeFormModal.defaultProps = {
  show: true,
  onClose: () => {},
  orderHandler: () => {},
  loadingHandler: () => {},
  setSaveCard: () => {},
};

export default StripeFormModal;
