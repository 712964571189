import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaInfo = ({ title, description, image, canonicalLink }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <link rel="canonical" href={canonicalLink} />
    </Helmet>
  );
};

MetaInfo.defaultProps = {
  title: 'Catered Club',
  description: '',
  image: '',
  canonicalLink: '',
};

MetaInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  canonicalLink: PropTypes.string,
};

export default MetaInfo;
