import { fetchAPI } from './apiUtils';
import { PRESET_URL } from './presetApi';

const SEARCH_URL = 'api/v2/search.json';
// const MOCKED_CHEF_JSON_SHOULD_CHANGE = '/chef.json';

export function searchRestaurants({ queryParams, page }) {
  return fetchAPI(
    `${SEARCH_URL}?${queryParams}&search_type=restaurant&page=${page || 1}`,
  );
}

export async function getRestaurant(presetSlug) {
  return fetchAPI(`${PRESET_URL}/${presetSlug}`);
}
