import { fetchAPI } from './apiUtils';

export function authenticate(signinFormData) {
  return fetchAPI('user_token', 'POST', signinFormData);
}

export function resetPassword(form) {
  return fetchAPI('api/v2/accounts/reset-password', 'PUT', form);
}

export function signup(signupFormData) {
  return fetchAPI('api/v2/users', 'POST', signupFormData);
}

export function validate() {
  return fetchAPI('api/v2/current', 'GET');
}

export function passwordReset(email) {
  return fetchAPI('users/password.json', 'POST', email);
}

export function updateUser(form) {
  return fetchAPI('api/v2/accounts', 'PUT', form);
}

export function getOrders() {
  return fetchAPI('api/v2/order_history', 'GET');
}

export function getSystemLookup(values) {
  if (values && values.length === 0) {
    return null;
  }
  const apiUrl = `api/v2/system_vars?valueTypes=${values}`;
  return fetchAPI(apiUrl, 'GET');
}
