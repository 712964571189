import React, { useEffect, useRef, useState } from 'react';
import { INVALID_EMAIL, REQUIRED_VALIDATION } from 'consts/form';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {
  cateringSuggestions,
  clearCateringSuggestions,
} from 'redux/catering/actions';
import { connect } from 'react-redux';
import FormError from '../FormError';

import './style.scss';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .typeError(INVALID_EMAIL)
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
  requestMessage: yup.string().required(REQUIRED_VALIDATION),
});

const Suggestion = ({
  preset,
  cateringSuggestions,
  clearCateringSuggestions,
  cateringOrderError,
  suggestions,
  loading,
  loadDate,
}) => {
  const [isForm, setIsForm] = useState(true);
  const [isEmail, setIsEmail] = useState(false);
  const [isSuggestions, setIsSuggestions] = useState(
    localStorage.getItem('isSuggestions') || true,
  );
  const initialValues = {
    requestMessage: '',
    email: '',
  };

  const refSuggestion = useRef(null);

  useEffect(() => {
    if (cateringOrderError) {
      setIsForm(true);
    }
  }, [cateringOrderError]);

  useEffect(() => {
    if (refSuggestion?.current && !loadDate) {
      setTimeout(
        () =>
          refSuggestion.current.scrollIntoView({
            behavior: 'smooth',
          }),
        1000,
      );
    }
  }, [refSuggestion, refSuggestion.current, loadDate]);

  const onSubmit = async form => {
    if (preset?.id) {
      cateringSuggestions(preset?.id, form);
      setIsForm(false);
    }
    // if (!isSignedIn) {
    //   showSignInModal(REQUIRED_SIGNIN_FOR_PAYMENT);
    //   return;
    // }
    // await updateOrder(checkoutData, preset, form);
  };

  return (
    <>
      {isSuggestions !== 'false' ? (
        <div className="overlay__suggestion" />
      ) : null}
      <div className="suggestion__wrapper active">
        <div className="inner__suggestion">
          {isSuggestions !== 'false' ? (
            <div ref={refSuggestion} className="tooltip__wrapper">
              <button
                type="button"
                onClick={() => {
                  setIsSuggestions('false');
                  localStorage.setItem('isSuggestions', false);
                }}
              >
                <img src="/assets/img/closebutton.svg" alt="closebutton" />
              </button>
              <span>
                <img src="/assets/img/ideatooltip.svg" alt="tooltip" />
              </span>
              <p>
                <span>Need suggestions from this vendor?</span> <br />
                Use our menu suggestion bot
              </p>
            </div>
          ) : null}
          <div className="suggestion__head">
            <p>
              <span>
                <img src="/assets/img/whiteidea.svg" alt="whiteidea" />
              </span>
              Menu Suggestion Bot
            </p>
          </div>
          <div className="suggestion__content">
            {isForm ? (
              <Formik
                {...{ initialValues, validationSchema }}
                validateOnChange
                validateOnBlur
                onSubmit={onSubmit}
              >
                {() => (
                  <Form className="row">
                    <div className="group__input">
                      <p>Your email</p>

                      <Field
                        className="form-control"
                        placeholder="Enter your email address"
                        name="email"
                        type="email"
                      />
                      <FormError name="email" />
                    </div>
                    <div className="group__input">
                      <p>What type of menu are you looking for?</p>
                      <Field
                        component="textarea"
                        placeholder="Example: I need a lunch for 25 people. We have 2 vegetarian people. Our budget is $20 per person."
                        name="requestMessage"
                        cols="30"
                        rows="10"
                      />
                      <FormError name="requestMessage" />
                    </div>
                    <div className="group__submit">
                      <button disabled={!preset?.id} type="submit">
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.74996 12C7.09683 12.0131 7.40622 11.7862 7.49996 11.4525C7.71934 10.4887 8.20683 9.60559 8.90622 8.9063C9.6056 8.20702 10.4887 7.71943 11.4524 7.50005C11.7721 7.4063 11.9924 7.11379 11.9924 6.78005C11.9924 6.4463 11.7721 6.15379 11.4524 6.06005C10.4783 5.84067 9.5877 5.34566 8.88654 4.63505C8.18622 3.9235 7.70434 3.02537 7.49998 2.04761C7.49998 1.63322 7.16437 1.29761 6.74998 1.29761C6.3356 1.29761 5.99998 1.63322 5.99998 2.04761C5.78061 3.01135 5.29311 3.89448 4.59373 4.59377C3.89434 5.29306 3.01122 5.78064 2.04757 6.00002C1.72789 6.09377 1.50757 6.38628 1.50757 6.72002C1.50757 7.05377 1.72788 7.34628 2.04757 7.44002C3.01976 7.66409 3.90757 8.16096 4.60789 8.87158C5.30727 9.58219 5.79008 10.4775 6.00008 11.4525C6.09383 11.7863 6.40309 12.0131 6.74996 12ZM4.24484 6.74995C5.30233 6.17246 6.17233 5.30246 6.74996 4.24483C7.32745 5.30232 8.19745 6.17232 9.25508 6.74995C8.19759 7.32744 7.32759 8.19744 6.74996 9.25507C6.17247 8.19759 5.30247 7.32758 4.24484 6.74995Z"
                              fill="#299976"
                            />
                            <path
                              d="M13.7026 17.25C12.9207 17.0728 12.2054 16.6781 11.6382 16.1118C11.072 15.5446 10.6773 14.8293 10.5001 14.0474C10.4063 13.7278 10.1138 13.5074 9.78008 13.5074C9.44634 13.5074 9.15382 13.7278 9.06008 14.0474C8.87821 14.8368 8.47508 15.5578 7.89663 16.1249C7.31818 16.6931 6.5907 17.0831 5.79757 17.2499C5.47789 17.3437 5.25757 17.6362 5.25757 17.9699C5.25757 18.3037 5.47788 18.5962 5.79757 18.6899C6.58695 18.8718 7.30789 19.2749 7.87506 19.8534C8.44318 20.4318 8.83318 21.1593 9.00006 21.9524C9.0938 22.2721 9.38631 22.4924 9.72006 22.4924C10.0538 22.4924 10.3463 22.2721 10.4401 21.9524C10.6219 21.1631 11.0251 20.4421 11.6035 19.875C12.182 19.3068 12.9094 18.9168 13.7026 18.75C14.0222 18.6562 14.2426 18.3637 14.2426 18.03C14.2426 17.6962 14.0223 17.4037 13.7026 17.31L13.7026 17.25ZM9.75003 19.8374C9.29629 19.0837 8.66629 18.4537 7.91254 17.9999C8.6691 17.5508 9.30099 16.919 9.75003 16.1624C10.1954 16.9227 10.8272 17.5546 11.5875 17.9999C10.831 18.449 10.1991 19.0808 9.75003 19.8374Z"
                              fill="#299976"
                            />
                            <path
                              d="M23.0252 10.3349C22.9258 10.2374 22.8011 10.1699 22.6652 10.1399C21.4277 9.8633 20.2952 9.23985 19.399 8.3436C18.5029 7.44734 17.8794 6.31485 17.6028 5.07744C17.5606 4.87025 17.4321 4.69024 17.2503 4.58244C16.984 4.44744 16.6634 4.48212 16.4328 4.67244C16.3053 4.78212 16.2162 4.92931 16.1778 5.09244C15.8984 6.33369 15.2712 7.46899 14.3693 8.36628C13.4675 9.26253 12.3275 9.88315 11.0852 10.155C10.7655 10.2488 10.5452 10.5413 10.5452 10.875C10.5452 11.2088 10.7655 11.5013 11.0852 11.595C12.333 11.8753 13.4758 12.5035 14.3796 13.4081C15.2843 14.3119 15.9125 15.4547 16.1928 16.7026C16.2865 17.0223 16.579 17.2426 16.9128 17.2426C17.2465 17.2426 17.539 17.0223 17.6328 16.7026C17.9056 15.4576 18.5281 14.3176 19.4281 13.4149C20.3281 12.513 21.4662 11.8867 22.7103 11.6102C22.7816 11.5914 22.8491 11.5643 22.9128 11.5277C23.09 11.4246 23.2175 11.2539 23.2653 11.0552C23.3216 10.7899 23.2297 10.5142 23.0253 10.3352L23.0252 10.3349ZM16.8752 14.4C16.1383 12.8587 14.8971 11.6148 13.3578 10.8749C14.8943 10.1333 16.1336 8.89392 16.8752 7.35741C17.6149 8.8968 18.8589 10.1381 20.4003 10.8749C18.86 11.6164 17.6168 12.8595 16.8752 14.4Z"
                              fill="#299976"
                            />
                          </svg>
                        </span>{' '}
                        Suggest Menu
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}
            {loading ? (
              <div className="preparing__block">
                <div className="inner__preparing">
                  <span>
                    <img src="/assets/img/loading.svg" alt="loading" />
                  </span>
                  <p>Preparing...</p>
                </div>
              </div>
            ) : null}
            {suggestions?.message ? (
              <div className="view__suggestion">
                <span>
                  <img src="/assets/img/checkgreen.svg" alt="checkgreen" />
                </span>
                <p>{suggestions?.message}</p>
                <button
                  type="button"
                  onClick={() => {
                    setIsForm(true);
                    clearCateringSuggestions();
                  }}
                >
                  View Suggestion
                </button>
              </div>
            ) : null}
            {isEmail ? (
              <div
                className="modal__wrapper modal__email"
                style={{ display: 'block' }}
              >
                <div className="inner__modal">
                  <div className="modal__box">
                    <div className="modal__top">
                      <button type="button" onClick={() => setIsEmail(false)}>
                        <img
                          src="/assets/img/closebutton.svg"
                          alt="closebutton"
                        />
                      </button>
                      <h6>What can you expect from using this?</h6>
                      <p>
                        You will receive a personalized email with a suggested
                        menu perfect for your event:
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="suggestion__after">
            <button
              className="modal__info"
              type="button"
              onClick={() => setIsEmail(true)}
            >
              <span>
                <img src="/assets/img/info.svg" alt="info" />
              </span>{' '}
              <p>What can you expect from using this?</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  suggestions: state.caterings.suggestions,
  loading: state.caterings.loadingSuggestions,
  cateringOrderError: state.caterings.error,
});
const mapDispatchToProps = {
  cateringSuggestions,
  clearCateringSuggestions,
};

Suggestion.propTypes = {
  preset: PropTypes.shape(),
  cateringSuggestions: PropTypes.func.isRequired,
  clearCateringSuggestions: PropTypes.func.isRequired,
  cateringOrderError: PropTypes.shape(),
  suggestions: PropTypes.shape(),
  loading: PropTypes.bool,
  loadDate: PropTypes.bool,
};

Suggestion.defaultProps = {
  preset: null,
  cateringOrderError: null,
  suggestions: null,
  loading: false,
  loadDate: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestion);
