import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

const FormError = ({ name, message }) => {
  return name ? (
    <ErrorMessage name={name}>
      {msg => (
        <div className="alert alert-danger d-flex align-items-center">
          <svg className="icon icon-warning">
            <use xlinkHref="#spriteIcon-warning" />
          </svg>
          <span>{msg}</span>
        </div>
      )}
    </ErrorMessage>
  ) : (
    <div className="alert alert-danger d-flex align-items-center">
      <svg className="icon icon-warning">
        <use xlinkHref="#spriteIcon-warning" />
      </svg>
      <span>{message}</span>
    </div>
  );
};

FormError.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
};

export default FormError;
