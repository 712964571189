import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import {
  splitItems,
  getPresetItemQuantity,
  getPresetItemTotalPrice,
} from 'utils/preset.util';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import TruncateWrapper from '../TruncateWrapper';
import './index.scss';
import GrandTotalPrice from '../TotalPrice';

const TRUNCATE_THRESHOLD = 640;

const QuickViewModal = ({ onHide, preset, isSavedPresets }) => {
  const {
    slug,
    name,
    description,
    orderCount,
    chefs,
    presetItems,
    servingStyles,
    pricePerPersonCents,
    totalPriceCents,
    numberOfGuests,
    averageRating,
  } = preset;

  const memoizedMenuCategories = useMemo(() => splitItems(presetItems), [
    presetItems,
  ]);

  return (
    <Modal size="lg" show onHide={onHide}>
      <button className="close" type="button" onClick={onHide} />
      <Modal.Body className="modal--quick-view">
        <div className="left">
          <div className="px-2 mb-2">
            <h2>{name}</h2>
            {averageRating && (
              <div className="star-ratings-sprite">
                <span
                  style={{ width: `${averageRating * 20}%` }}
                  className="star-ratings-sprite-rating"
                />
              </div>
            )}
            {!isSavedPresets && (
              <div className="stats">
                <ul>
                  <li>
                    <svg className="icon icon-dinner">
                      <use xlinkHref="#spriteIcon-dinner" />
                    </svg>
                    Ordered {orderCount} times
                  </li>
                  <li>
                    <svg className="icon icon-people">
                      <use xlinkHref="#spriteIcon-people" />
                    </svg>
                    Serves: {numberOfGuests}
                  </li>
                  <li>
                    <svg className="icon icon-meal">
                      <use xlinkHref="#spriteIcon-meal" />
                    </svg>
                    Served: {servingStyles && servingStyles.join(', ')}
                  </li>
                  <li>
                    <svg className="icon icon-wallet">
                      <use xlinkHref="#spriteIcon-wallet" />
                    </svg>
                    ${priceDecimalFormatter(pricePerPersonCents)}/person
                  </li>
                  <li>
                    <svg className="icon icon-coin-stack">
                      <use xlinkHref="#spriteIcon-coin-stack" />
                    </svg>
                    Total Price{' '}
                    <GrandTotalPrice totalPriceCents={totalPriceCents} />
                  </li>
                </ul>
              </div>
            )}
          </div>
          <hr />
          <div className="p-2 flex-grow-1">
            <TruncateWrapper
              text={description}
              threshold={TRUNCATE_THRESHOLD}
            />
          </div>
          <hr />
          {chefs && (
            <ul className="restaurants">
              {chefs.map(chef => (
                <li key={chef.id}>
                  <img src={chef.profileImageUrl} alt="chef_logo" />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="right">
          <div className="text-right px-5 py-1">
            <p>To change quantity or edit menu, view more details.</p>
            <Link
              className="btn btn-primary"
              to={`/preset-detail/${slug}`}
              target="_blank"
            >
              View Details
            </Link>
          </div>
          <div className="quick-view-meals mt-4">
            {memoizedMenuCategories.map((presetCategory, index) => {
              if (presetCategory.length === 0) {
                return null;
              }

              const { presetMenuCategoryName } = presetCategory[0];

              return (
                <div
                  // eslint-disable-next-line
                  key={`home-preset-quick-view-${index}`}
                  className={classnames({
                    'quick-view-meal--fluid': presetCategory.length > 1,
                    'quick-view-meal': presetCategory.length === 1,
                  })}
                >
                  <h3>{presetMenuCategoryName}</h3>
                  <div className="quick-view-meal__wrapper">
                    {presetCategory.map(presetItem => {
                      const { mainImageUrl, name, dietaryTypes } = presetItem;

                      const totalQuantity = getPresetItemQuantity(presetItem);

                      return (
                        <div key={presetItem.id} className="meal-card">
                          <div className="cover">
                            <img
                              className="w-100"
                              src={mainImageUrl}
                              alt="main_image"
                            />
                          </div>
                          <div className="info">
                            <h4>{name}</h4>
                            <ul className="tags">
                              {dietaryTypes
                                .split(',')
                                .map(
                                  dietary =>
                                    dietary && <li key={dietary}>{dietary}</li>,
                                )}
                            </ul>
                          </div>
                          <div className="qty">
                            <b className="text-muted">
                              $
                              {priceDecimalFormatter(
                                getPresetItemTotalPrice(presetItem),
                              )}
                              /{totalQuantity} quantity
                            </b>
                            <div>
                              <span>Qty</span>
                              <div className="badge badge-pill badge-primary">
                                {totalQuantity}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

QuickViewModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  isSavedPresets: PropTypes.bool,
  preset: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    bannerImageUrl: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    chefs: PropTypes.arrayOf(PropTypes.any),
    presetItems: PropTypes.arrayOf(PropTypes.any),
    orderCount: PropTypes.number,
    dietaryTypes: PropTypes.arrayOf(PropTypes.string),
    totalPriceCents: PropTypes.number,
    pricePerPersonCents: PropTypes.number,
    servingStyles: PropTypes.any,
    numberOfGuests: PropTypes.number,
    averageRating: PropTypes.number,
  }).isRequired,
};

QuickViewModal.defaultProps = {
  isSavedPresets: false,
};

export default QuickViewModal;
