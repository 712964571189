import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const SaveForLaterModal = ({ show, onClose, onSubmit }) => {
  const [name, setName] = useState('');
  return (
    <Modal show={show} onHide={onClose}>
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={onClose}
      />
      <Modal.Header>
        <h3>Name your order</h3>
        <div className="help">
          <svg
            className="icon icon-help"
            data-toggle="popover"
            data-placement="bottom"
            data-trigger="hover"
            data-html="true"
            data-template='<div class="popover modal-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
            data-content="Haven't finalized your cart yet? And don't want to lose your progress? Name and save your draft to continue editing or checkout at a later time."
          >
            <use xlinkHref="#spriteIcon-help" />
          </svg>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-5">
        <form
          className="row"
          onSubmit={e => {
            e.preventDefault();
            onClose();
            onSubmit(name);
          }}
        >
          <div className="col-xl-9">
            <input
              className="form-control mb-3"
              value={name}
              placeholder="ex: Dec 25th, Chiristmas order"
              onChange={e => {
                setName(e.currentTarget.value);
              }}
            />
          </div>
          <div className="col-xl-3">
            <button className="btn btn-primary btn-block" type="submit">
              Save
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

SaveForLaterModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

SaveForLaterModal.defaultProps = {
  show: true,
  onClose: () => {},
  onSubmit: () => {},
};

export default SaveForLaterModal;
