/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  closeSignInSignUpModal,
  showSignInModal,
} from 'redux/actions/modalActions';
import { REQUIRED_VALIDATION, INVALID_EMAIL } from 'consts/form';
import { authenticate, signup } from 'api/authApi';
import { signinRequest } from 'redux/actions/authActions';
import FormError from './FormError';
import AddressPickerField from './AddressPickerField';
import LoadingAnimation from './LoadingAnimation';
import VoidLink from './VoidLink';

const validationSchema = yup.object().shape({
  firstName: yup.string().required(REQUIRED_VALIDATION),
  lastName: yup.string().required(REQUIRED_VALIDATION),
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
  phone: yup.string().required(REQUIRED_VALIDATION),
  companyName: yup.string(),
  address1: yup.string().required(REQUIRED_VALIDATION),
  address2: yup.string(),
  // city: yup.string().required(REQUIRED_VALIDATION),
  // zip: yup.string().required(REQUIRED_VALIDATION),
  password: yup.string().required(REQUIRED_VALIDATION),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
  address1: JSON.parse(localStorage.getItem('jb-delivery-info'))?.address || '',
  address2: '',
  // city: 'Toronto',
  // zip: '',
  password: '',
  passwordConfirmation: '',
};

const SignUpModal = ({
  signUpModalShown,
  showSignInModal,
  closeSignInSignUpModal,
  signinRequest,
}) => {
  const [addressPickerFocused, setAddressPickerFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [latLng, setLatLng] = useState(null);
  const [errorMessages, setErrorMessages] = useState(null);
  const [confirmStatus, setConfirmStatus] = useState(false);

  async function onSubmit(formData) {
    setLoading(true);
    try {
      await signup({
        user: {
          ...formData,
          latitude: latLng.lat,
          longitude: latLng.lng,
        },
      });
      const auth = await authenticate({
        auth: {
          email: formData.email,
          password: formData.password,
        },
      });
      if (auth && auth.user && auth.user.confirmed) {
        signinRequest(auth);
        closeSignInSignUpModal();
      } else {
        showSignInModal();
      }
    } catch (e) {
      const message = await e.response.json();
      setConfirmStatus(false);
      setErrorMessages(message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={signUpModalShown}
      size="lg"
      onHide={() => {
        setErrorMessages(null);
        closeSignInSignUpModal();
      }}
    >
      {loading && <LoadingAnimation />}
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          setErrorMessages(null);
          closeSignInSignUpModal();
        }}
      />
      <Modal.Header>
        <h3>Create your Catered Club account</h3>
      </Modal.Header>

      <Modal.Body className="modal--sign-up">
        {!confirmStatus && (
          <>
            {errorMessages && (
              <div className="alert alert-danger py-4 py-xl-3 d-flex align-items-center">
                <svg className="icon icon-warning">
                  <use xlinkHref="#spriteIcon-warning" />
                </svg>
                <span>{errorMessages.join(', ')}</span>
              </div>
            )}
            <Formik
              {...{ initialValues, validationSchema }}
              validateOnChange
              validateOnBlur
              onSubmit={form => {
                onSubmit(form);
              }}
            >
              {({ setFieldValue, values, handleBlur, setFieldTouched }) => (
                <Form autoComplete="off">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="firstName"
                        />
                        <FormError name="firstName" />
                        <label
                          className={classnames({
                            'label-focused': values.firstName,
                          })}
                        >
                          First Name
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="lastName"
                        />
                        <FormError name="lastName" />
                        <label
                          className={classnames({
                            'label-focused': values.lastName,
                          })}
                        >
                          Last Name
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                        />
                        <FormError name="email" />
                        <label
                          className={classnames({
                            'label-focused': values.email,
                          })}
                        >
                          Email
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="phone"
                        />
                        <FormError name="phone" />
                        <label
                          className={classnames({
                            'label-focused': values.phone,
                          })}
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="companyName"
                        />
                        <FormError name="companyName" />
                        <label
                          className={classnames({
                            'label-focused': values.companyName,
                          })}
                        >
                          Company Name (Optional)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <AddressPickerField
                          id="checkout-address"
                          name="address1"
                          value={values.address1}
                          onChange={setFieldValue}
                          onSetLatLng={latLng => setLatLng(latLng)}
                          onFocus={() => {
                            setAddressPickerFocused(true);
                            setFieldTouched('address1');
                          }}
                          onBlur={e => {
                            setAddressPickerFocused(false);
                            handleBlur(e);
                          }}
                        />
                        <FormError name="address1" />
                        <label
                          className={classnames({
                            'label-focused':
                              addressPickerFocused || values.address1,
                          })}
                        >
                          Address
                        </label>
                      </div>
                    </div>

                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="text"
                          className="form-control"
                          name="address2"
                        />
                        <FormError name="address2" />
                        <label
                          className={classnames({
                            'label-focused': values.address2,
                          })}
                        >
                          Additional address (Suite #, Bring to reception etc..)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                        />
                        <FormError name="password" />
                        <label
                          className={classnames({
                            'label-focused': values.password,
                          })}
                        >
                          Password
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          type="password"
                          className="form-control"
                          name="passwordConfirmation"
                        />
                        <FormError name="passwordConfirmation" />
                        <label
                          className={classnames({
                            'label-focused': values.passwordConfirmation,
                          })}
                        >
                          Confirm Password
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-block btn-primary" type="submit">
                    Sign Up
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}

        {/* {confirmStatus && (
          <h3 style={{ textAlign: 'center' }}>
            Please confirm your email address
          </h3>
        )} */}
        <div className="text-center mt-4">
          Already have an account? &nbsp;
          <VoidLink
            onClick={() => {
              showSignInModal();
            }}
          >
            <b>Sign In</b>
          </VoidLink>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  signUpModalShown: state.modal.signUpModalShown,
});

const mapDispatchToProps = {
  closeSignInSignUpModal,
  showSignInModal,
  signinRequest,
};

SignUpModal.propTypes = {
  signUpModalShown: PropTypes.bool,
  closeSignInSignUpModal: PropTypes.func.isRequired,
  showSignInModal: PropTypes.func.isRequired,
  signinRequest: PropTypes.func.isRequired,
};

SignUpModal.defaultProps = {
  signUpModalShown: false,
};

const connectedSignUpModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpModal);

export default connectedSignUpModal;
