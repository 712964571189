/* eslint-disable no-case-declarations */
import * as types from './actionTypes';

const initialState = {
  loading: false,

  error: null,
  savedForLaterRestaurants: [],
  favoriteRestaurants: [],
  previouslyOrderedRestaurants: [],
  savedForLaterPresets: [],
  favoritePresets: [],
  previouslyOrderedPresets: [],

  loadingRemoveFavoriteOrSaved: false,
};

export default function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case types.FAVORITES_FETCH_REQUESTED:
      return { ...state, loading: true };

    case types.FAVORITES_FETCH_SUCCEEDED:
      const {
        favoriteRestaurants,
        savedForLaterRestaurants,
        previouslyOrderedRestaurants,
        favoritePresets,
        savedForLaterPresets,
        previouslyOrderedPresets,
      } = action.payload.presets;
      return {
        ...state,
        loading: false,

        favoriteRestaurants,
        savedForLaterRestaurants,
        previouslyOrderedRestaurants,
        favoritePresets,
        savedForLaterPresets,
        previouslyOrderedPresets,
      };

    case types.FAVORITES_FETCH_FAILED:
      return {
        ...initialState,
        error: action.payload.error,
      };

    case types.REMOVE_FAVORITE_OR_SAVED_REQUESTED:
      return { ...state, loadingRemoveFavoriteOrSaved: true };

    case types.REMOVE_FAVORITE_OR_SAVED_SUCCEEDED:
      const { isFavorites, presetId } = action.payload;
      if (isFavorites) {
        return {
          ...state,
          loadingRemoveFavoriteOrSaved: false,
          favoriteRestaurants: state.favoriteRestaurants.filter(
            restaurant => restaurant.id !== presetId,
          ),
          favoritePresets: state.favoritePresets.filter(
            preset => preset.id !== presetId,
          ),
        };
      }
      return {
        ...state,
        loadingRemoveFavoriteOrSaved: false,
        savedForLaterRestaurants: state.savedForLaterRestaurants.filter(
          restaurant => restaurant.id !== presetId,
        ),
        savedForLaterPresets: state.savedForLaterPresets.filter(
          preset => preset.id !== presetId,
        ),
      };

    case types.REMOVE_FAVORITE_OR_SAVED_FAILED:
      return {
        ...state,
        loadingRemoveFavoriteOrSaved: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
