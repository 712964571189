/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { store } from 'react-notifications-component';
import * as yup from 'yup';
import FormError from 'components/common/FormError';
import LoadingAnimation from 'components/common/LoadingAnimation';
import PropTypes from 'prop-types';

import { resetPasswordRequest } from 'redux/actions/authActions';
import './index.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { REQUIRED_VALIDATION } from 'consts/form';

const queryString = require('query-string');

const validationSchema = yup.object().shape({
  password: yup.string().required(REQUIRED_VALIDATION),
  passwordConfirmation: yup
    .string()
    .when('password', {
      is: val => !!(val && val.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'Both password need to be the same'),
    })
    .required(REQUIRED_VALIDATION),
});

const ResetPassword = ({ resetPasswordRequest }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const history = useHistory();

  const initialValues = {
    resetPasswordToken: token,
    password: '',
    passwordConfirmation: '',
  };

  const updateData = async form => {
    try {
      await resetPasswordRequest({ user: form });
      store.addNotification({
        message: 'Successfully updated!',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
      history.push({
        pathname: '/',
        state: {
          showSignIn: true,
        },
      });
    } catch (e) {
      const { messages, error } = await e.response.json();
      setError(messages);
      store.addNotification({
        message:
          error ||
          'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = form => {
    setError(null);
    if (token) {
      setLoading(true);
      updateData(form);
    } else {
      setError(['Invalid link']);
    }
  };

  return (
    <main>
      {loading && <LoadingAnimation />}
      <div className="container-fluid container-orders page-content">
        <h2 className="setting-page-title mb-4 ml-2">Reset password</h2>
        <div className="setting-page-body mr-2 ml-2">
          <Formik
            {...{ initialValues, validationSchema }}
            validateOnChange
            validateOnBlur
            onSubmit={onSubmit}
          >
            {({ values, touched }) => (
              <Form className="setting-form pt-2 mr-2 ml-2">
                <Field
                  hidden
                  type="resetPasswordToken"
                  name="resetPasswordToken"
                />
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label
                        className={classnames({
                          'label-focused': touched.password && values.password,
                        })}
                      >
                        New Password
                      </label>
                      <Field
                        className="form-control"
                        type="password"
                        name="password"
                      />
                      <FormError name="password" />
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label
                        className={classnames({
                          'label-focused':
                            touched.passwordConfirmation &&
                            values.passwordConfirmation,
                        })}
                      >
                        Confirm Password
                      </label>
                      <Field
                        className="form-control"
                        type="password"
                        name="passwordConfirmation"
                      />
                      <FormError name="passwordConfirmation" />
                    </div>
                  </div>
                </div>
                {error && (
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-6">
                      {error.map(item => (
                        <FormError message={item} />
                      ))}
                    </div>
                  </div>
                )}

                <div className="row justify-content-center mt-3">
                  <div className="col-12 col-sm-6 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary resetPass-btn"
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

const mapDispatchToProps = {
  resetPasswordRequest,
};

ResetPassword.propTypes = {
  resetPasswordRequest: PropTypes.func.isRequired,
};

const connectedHeader = connect(null, mapDispatchToProps)(ResetPassword);

export default connectedHeader;
