/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Geocode from 'react-geocode';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';

import FormError from 'components/common/FormError';
// import DatePickerField from 'components/common/DatePickerField';
import AddressPickerField from 'components/common/AddressPickerField';
import {
  REQUIRED_VALIDATION,
  REQUIRED_VALIDATION_TOO_BROAD,
} from 'consts/form';
import './index.scss';
import { getDeliveryInfo, setDeliveryInfo } from 'utils/address.util';

const queryString = require('query-string');

export const setInitialValues = (defaultAddress = '') => {
  const deliveryInfo = getDeliveryInfo();

  return {
    address: defaultAddress || deliveryInfo.address || '', // get these fields from the local storage
  };
};

export const geoCodeOnSubmit = async (form, onSubmit) => {
  const params = {
    address: form.address,
  };

  try {
    const { results } = await Geocode.fromAddress(form.address);
    const { lat, lng } = results[0].geometry.location;
    params.lat = lat;
    params.lng = lng;
  } catch (e) {
    console.log('---> geocode failed');
  }

  setDeliveryInfo(null, params); // set entire item
  onSubmit(queryString.stringify(params));
};

const DeliveryInfoAddress = ({ onSubmit, defaultAddress, receivingMethod }) => {
  const initialValues = setInitialValues(defaultAddress);
  const [toggle, setToggle] = useState(false);

  const validationSchema = yup.object().shape({
    address: yup.string().required(REQUIRED_VALIDATION),
  });

  return (
    <Formik
      {...{ initialValues, validationSchema }}
      validateOnChange
      validateOnBlur
      onSubmit={form => geoCodeOnSubmit(form, onSubmit)}
    >
      {({ setFieldValue, values, setFieldTouched }) => (
        <Form className="row">
          <div className="form-group col-xl-12" />
          <div className="form-group col-xl-9 mb-0">
            <label htmlFor="address" className="text-uppercase">
              Enter{' '}
              {receivingMethod === 'delivery'
                ? 'Street Address for Delivery'
                : 'your'}{' '}
              address
            </label>

            <div className="input-with-icons mb-2 ii-left">
              <AddressPickerField
                className="form-control"
                name="address"
                placeholder="Enter Street Address for Delivery"
                value={values.address}
                onChange={setFieldValue}
                setToggle={setToggle}
                onFocus={() => {
                  setFieldTouched('address');
                }}
              />
              <div className="input-prepend">
                <svg className="icon icon-location-arrow">
                  <use xlinkHref="#spriteIcon-location-arrow" />
                </svg>
              </div>
              {toggle ? (
                <FormError message={REQUIRED_VALIDATION_TOO_BROAD} />
              ) : (
                <FormError name="address" />
              )}
            </div>
          </div>
          <div className="form-group d-flex align-items-end col-xl-3 mb-0">
            <button
              disabled={!values.address}
              type="submit"
              className="btn btn-primary btn-block mb-2"
            >
              GO
              <svg className="icon icon-right-arrow-fat">
                <use xlinkHref="#spriteIcon-right-arrow-fat" />
              </svg>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

DeliveryInfoAddress.propTypes = {
  defaultAddress: PropTypes.string,
  onSubmit: PropTypes.func,
  receivingMethod: PropTypes.string,
};

DeliveryInfoAddress.defaultProps = {
  defaultAddress: '',
  onSubmit: () => {},
};

const mapStateToProps = state => {
  return {
    receivingMethod: state.receivingMethod,
  };
};

const connectedDeliveryInfoAddress = connect(mapStateToProps)(
  DeliveryInfoAddress,
);

export default connectedDeliveryInfoAddress;
