import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutCompleted = () => (
  <div className="container d-flex justify-content-center">
    <div className="checkout-completed">
      <div
        className="checkout-completed--content"
        style={{ marginBottom: 110 }}
      >
        <img src="/assets/img/order-sent.svg" alt="order-sent" />
        <h2 className="my-4">
          Thank you!
          <br />
          Your order has been submitted
        </h2>

        <p className="mb-5">You will receive a response shortly!</p>
        {/* <div className="row w-100 mb-4 mb-xl-5">
          <div className="col-xl-6">
            <button type="button" className="btn btn-primary btn-block">
              Download Order Details
            </button>
          </div>

          <div className="col-xl-6">
            <button
              type="button"
              className="btn btn-outline-primary btn-block"
              data-toggle="modal"
              data-target="#modalNameFavorite"
            >
              <svg className="icon icon-heart">
                <use xlinkHref="#spriteIcon-heart" />
              </svg>
              Save to Favorites
            </button>
          </div>
        </div> */}
        <Link to="/">Go to Home</Link>
      </div>
    </div>
  </div>
);

export default CheckoutCompleted;
