import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MenuSlider from 'components/common/MenuSlider';
import CycleLink from 'components/common/CycleLink';
import LoadingAnimation from 'components/common/LoadingAnimation';
import { loadPresetList } from 'redux/actions/presetActions';
import { Link, withRouter, useLocation } from 'react-router-dom';
import initialState from 'redux/reducers/initialState';
// import DeliveryInfoForm from 'components/common/DeliveryInfoForm';
import DeliveryInfoAddress from 'components/common/DeliveryInfoForm/DeliveryInfoAddress';
import {
  // getCurrentLocation,
  getDeliveryInfo,
} from 'utils/address.util';
import { store } from 'react-notifications-component';
import { showSignInModal } from 'redux/actions/modalActions';
import HowItWorks from './HowItWorks';
import Testimonials from './Testimonials';
import SimpleSolutions from './SimpleSolutions';
import GetStarted from './GetStarted';

import './index.scss';

const queryString = require('query-string');

const filteredPresetCategories = [
  'Healthy Lunch',
  'Corporate Breakfast',
  'Sandwich/Wrap & Salad Lunches',
];

const HomePage = ({ history }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const presets = useSelector(state => state.presets);
  // const receivingMethod = useSelector(state => state.receivingMethod);
  // const [address, setAddress] = useState('');
  const { search, state } = useLocation();
  const [searchBy, setSearchBy] = useState('restaurants');

  useEffect(() => {
    if (state?.showSignIn) {
      dispatch(showSignInModal());
    }
  }, [state?.showSignIn]);

  // confirm status and then show confirm modal
  const { confirmed, name } = queryString.parse(search);

  useEffect(() => {
    if (confirmed === 'ok') {
      store.addNotification({
        title: `Hello ${name}, good news!`,
        message: 'Your account has been verified. Please log in.',
        type: 'success',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        width: 400,
        dismiss: {
          duration: 10000,
          showIcon: true,
        },
      });
    } else if (confirmed === 'failed') {
      store.addNotification({
        title: 'Hmm ...',
        message:
          "Your account verification didn’t work. Please contact us at support@cateredclub.com and we're Happy to help!",
        type: 'danger',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        width: 500,
        dismiss: {
          duration: 10000,
          showIcon: true,
        },
      });
    }
  }, [confirmed]);

  useEffect(() => {
    const deliveryInfo = getDeliveryInfo();
    if (deliveryInfo && deliveryInfo.address) {
      // no need to call get current location
      console.log('--> delivery address already set');
    } else {
      // getCurrentLocation(setAddress);
    }
    history.push({
      state: {
        ...state,
        showSignIn: false,
      },
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (presets === initialState.presets) {
        await dispatch(loadPresetList());
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const onDeliveryFormSubmit = () => {
    console.log('--> query');
    if (searchBy === 'restaurants') {
      history.push(`/browse-restaurants`);
    } else {
      history.push(`/browse-presets`);
    }
  };

  const { trendingRestaurants, allPresets } = presets;
  return (
    <main>
      {loading && <LoadingAnimation />}
      <div className="home">
        <div className="container">
          <div className="row home--head">
            <div className="col-xl-6 pr-xl-5">
              <h1 className="home--title mb-xl-4">
                {searchBy === 'presets' &&
                  'The most popular catered food for the best teams.'}
                {searchBy === 'restaurants' &&
                  "Need food for the workplace? We're the Best to do it."}
              </h1>
              <p className="home--text page-description pr-xl-5 mb-xl-4">
                {searchBy === 'presets' &&
                  `We take the guessing out of food ordering. We've gathered data on what the most popular biz catering options are in your city and have created preset menus that are proven to be big HITS at offices just like yours.`}
                {searchBy === 'restaurants' &&
                  `Offering delicious meals to hungry teams daily. Support local, over 1,000,000 meals delivered to on-site workers. A happy workforce creates a happy customer. `}
              </p>
            </div>
            <div className="col-xl-6 home--picture">
              {searchBy === 'presets' && (
                <img
                  className="img-fluid mb-4"
                  src="/assets/img/home/head.jpg"
                  alt="team"
                />
              )}
              {searchBy === 'restaurants' && (
                <img
                  className="img-fluid mb-4"
                  src="/assets/img/home/head-2.jpg"
                  alt="team"
                />
              )}
            </div>
            <div className="col-xl-8 home--form-column">
              {/*
              <div className="home--form px-xl-5">
                <div className="home--form__tabs mb-30">
                  <button
                    aria-label="tab"
                    href="#"
                    className={`
                      home--form__tab-link
                      ${searchBy === 'restaurants' ? 'active' : ''}
                    `}
                    onClick={e => {
                      e.preventDefault();
                      setSearchBy('restaurants');
                    }}>
                    <span className="home--form__tab-link-text">Browse Restaurants</span>
                  </button>
                  <button
                    aria-label="tab"
                    href="#"
                    className={`
                      home--form__tab-link
                      ${searchBy === 'presets' ? 'active' : ''}
                    `}
                    onClick={e => {
                      e.preventDefault();
                      setSearchBy('presets');
                    }}>
                    <span className="home--form__tab-link-text">Browse Preset Menus</span>
                  </button>
                </div>
                <div className="delivery-info-form-padding-wrap">
                  <div className="mb-4">
                    {searchBy === 'presets'
                      ? <p className="fs-1rem mb-0 color-main-black">
                          Preset Menus are available for delivery only.
                        </p>
                      : <div className="radio-block__wrap">
                          <div className="radio-block__col">
                            <input
                              type="radio"
                              id={`receivingMethod-1`}
                              value="delivery"
                              className="radio-block__input"
                              checked={receivingMethod === 'delivery'}
                              onChange={() => updateReceivingMethod('delivery')}
                            />
                            <label
                              htmlFor={`receivingMethod-1`}
                              className="radio-block__label">
                              Delivery order
                            </label>
                          </div>
                          <div className="radio-block__col">
                            <input
                              type="radio"
                              id={`receivingMethod-2`}
                              value="pickup"
                              className="radio-block__input"
                              checked={receivingMethod === 'pickup'}
                              onChange={() => updateReceivingMethod('pickup')}
                            />
                            <label
                              htmlFor={`receivingMethod-2`}
                              className="radio-block__label">
                              Pick up order
                            </label>
                          </div>
                        </div>
                    }
                  </div>
                  <DeliveryInfoForm
                    defaultAddress={address}
                    onSubmit={onDeliveryFormSubmit}
                  />
                </div>
              </div>
*/}

              <div className="home--form px-xl-5">
                <div className="delivery-info-form-padding-wrap">
                  <DeliveryInfoAddress
                    // defaultAddress={address}
                    onSubmit={onDeliveryFormSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="home--meals">
          <div className="decoration-dots hide-mobile">
            <img src="/assets/img/home/dots_v.svg" alt="dots_v" />
          </div>

          <div
            className="container"
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            {trendingRestaurants && trendingRestaurants.length > 0 && (
              <>
                <h3 className="home--carousel-title mb-4">
                  {trendingRestaurants[0].categoryName} Restaurants
                </h3>
                <MenuSlider presets={trendingRestaurants} isChefs />
              </>
            )}

            <div className="home--book-demo my-5 pb-5 text-center">
              Yes, Janet, I know you&apos;re&nbsp;
              <br className="hide-desktop" />
              <CycleLink
                className="link"
                values={[
                  'gluten-free',
                  'vegan',
                  'sick of salad',
                  'anti-pickle',
                  'keto',
                  'not adventurous',
                  'counting macros',
                ]}
                links={[
                  '/daily-lunches',
                  '/daily-lunches',
                  '/daily-lunches',
                  '/daily-lunches',
                  '/daily-lunches',
                  '/',
                  '/',
                ]}
              />
              <br className="hide-mobile" />
              - Every office manager.
              <br />
              <br />
              That&apos;s why we create presets with all of these dietary
              restrictions in mind.
              <br />
              <Link className="btn btn-primary mt-4" to="/book-demo">
                Book a Demo
              </Link>
            </div>

            {/* {trendingPresets && trendingPresets.length > 0 && (
              <>
                <h3 className="home--carousel-title mb-4">
                  {trendingPresets[0].categoryName}
                </h3>
                <MenuSlider presets={trendingPresets} />
              </>
            )} */}

            {allPresets &&
              allPresets.length > 0 &&
              allPresets
                .filter(presetInfo =>
                  filteredPresetCategories.includes(presetInfo[0]),
                )
                .map(presetInfo => (
                  <Fragment key={presetInfo[0]}>
                    <h3 className="home--carousel-title mb-4 mt-5">
                      {presetInfo[0]}
                    </h3>
                    <MenuSlider presets={presetInfo[1]} />
                  </Fragment>
                ))}
          </div>

          <div className="decoration-bottom hide-mobile">
            <img
              src="/assets/img/home/decoration_1.jpg"
              srcSet="/assets/img/home/decoration_1@2x.jpg 2x"
              alt="decoration_1"
            />
          </div>
        </section>

        <HowItWorks />
        <Testimonials />
        <SimpleSolutions />
        <GetStarted>
          <div className="home--form px-xl-5">
            {/*
          <div className="home--form__tabs mb-30">
            <button
              aria-label="tab"
              href="#"
              className={`
                home--form__tab-link
                ${searchBy === 'restaurants' ? 'active' : ''}
              `}
              onClick={e => {
                e.preventDefault();
                setSearchBy('restaurants');
              }}>
              <span className="home--form__tab-link-text">Browse Restaurants</span>
            </button>
            <button
              aria-label="tab"
              href="#"
              className={`
                home--form__tab-link
                ${searchBy === 'presets' ? 'active' : ''}
              `}
              onClick={e => {
                e.preventDefault();
                setSearchBy('presets');
              }}>
              <span className="home--form__tab-link-text">Browse Preset Menus</span>
            </button>
          </div>
          <div className="delivery-info-form-padding-wrap">
            <div className="mb-4">
              {searchBy === 'presets'
                ? <p className="fs-1rem mb-0 color-main-black">
                    Preset Menus are available for delivery only.
                  </p>
                : <div className="radio-block__wrap">
                    <div className="radio-block__col">
                      <input
                        type="radio"
                        id={`receivingMethod-3`}
                        value="delivery"
                        className="radio-block__input"
                        checked={receivingMethod === 'delivery'}
                        onChange={() => updateReceivingMethod('delivery')}
                      />
                      <label
                        htmlFor={`receivingMethod-3`}
                        className="radio-block__label">
                        Delivery order
                      </label>
                    </div>
                    <div className="radio-block__col">
                      <input
                        type="radio"
                        id={`receivingMethod-4`}
                        value="pickup"
                        className="radio-block__input"
                        checked={receivingMethod === 'pickup'}
                        onChange={() => updateReceivingMethod('pickup')}
                      />
                      <label
                        htmlFor={`receivingMethod-4`}
                        className="radio-block__label">
                        Pick up order
                      </label>
                    </div>
                  </div>
              }
            </div>
            <DeliveryInfoForm
              defaultAddress={address}
              onSubmit={onDeliveryFormSubmit}
            />
          </div>
*/}
            <div className="delivery-info-form-padding-wrap">
              <DeliveryInfoAddress
                // defaultAddress={address}
                onSubmit={onDeliveryFormSubmit}
              />
            </div>
          </div>
        </GetStarted>
      </div>
    </main>
  );
};

HomePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(HomePage);
