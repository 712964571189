/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { store } from 'react-notifications-component';
import {
  getCateringOrder,
  getGroupOrders,
  cateringOrderCancel,
  cancelGroupOrders,
  getGroupOrderPdf,
  getCateringOrderPdf,
} from 'redux/catering/actions';

import { getOrders } from 'api/authApi';
import LoadingAnimation from 'components/common/LoadingAnimation';
import OrderView from 'components/common/OrderView';

import './index.scss';
import { format } from 'date-fns';
import GrandTotalPrice from 'components/common/TotalPrice';
import ConfirmModal from 'components/common/ConfirmModal';

const Orders = ({
  user,
  cateringOrderInfo,
  loadingRequest,
  getCateringOrder,
  getGroupOrders,
  groupOrderInfo,
  cateringOrderCancel,
  cancelGroupOrders,
  cancelledOrder,
  getGroupOrderPdf,
  getCateringOrderPdf,
}) => {
  const userSignedIn = user && user.confirmed;
  const history = useHistory();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState([]);
  const [filter, setFilter] = useState('UPCOMING');
  const [filterKey, setFilterKey] = useState('upcoming');
  const [orderViewItem, setOrderViewItem] = useState(null);
  const [cancelOrderId, setCancelOrderId] = useState(null);

  let orderShownCount = 0;

  const getStatus = (value, text) => {
    let requestStatusColor = 'request-status-yellow';
    let requestStatusText = 'In Progress';

    switch (value) {
      case 'New': {
        requestStatusColor = 'request-status-yellow';
        requestStatusText = 'New';
        break;
      }
      case 'Accepted': {
        requestStatusColor = 'request-status-green';
        requestStatusText = 'Accepted';
        break;
      }
      case 'CardSetup': {
        requestStatusColor = 'request-status-blue';
        requestStatusText = 'Card Setup';
        break;
      }
      case 'SetupIntentCreated': {
        requestStatusColor = 'request-status-lightBlue';
        requestStatusText = 'Setup Intent Created';
        break;
      }
      case 'ReadyToCapture': {
        requestStatusColor = 'request-status-lightGreen';
        requestStatusText = 'Ready To Capture';
        break;
      }
      case 'DeliveryReady': {
        requestStatusColor = 'request-status-purple';
        requestStatusText = 'Delivery Ready';
        break;
      }
      case 'Delivered': {
        requestStatusColor = 'request-status-darkGreen';
        requestStatusText = 'Delivered';
        break;
      }
      case 'cancelled': {
        requestStatusColor = 'request-status-red';
        requestStatusText = 'Cancelled';
        break;
      }
      case 'Canceled': {
        requestStatusColor = 'request-status-red';
        requestStatusText = 'Canceled';
        break;
      }
      case 'accepting': {
        requestStatusColor = 'request-status-green';
        requestStatusText = 'Accepting';
        break;
      }
      case 'waiting_for_delivery': {
        requestStatusText = 'Waiting for delivery';
        break;
      }
      default:
    }

    switch (text) {
      case 'Processed':
      case 'Processing': {
        requestStatusColor = 'request-status-green';
        break;
      }
      default:
    }

    return { text: text || requestStatusText, style: requestStatusColor };
  };

  const handleOrderButton = order => {
    switch (order.orderType) {
      case 'Group Order':
        getGroupOrders(order.id);
        break;
      case 'Catering Order':
        getCateringOrder(order.id);
        break;
      default:
    }
    setOrderViewItem(order);
  };

  const GetOrdersList = orders => {
    let orderList = null;
    if (orders) {
      orderList = orders.map(order => {
        orderShownCount += 1;
        return (
          <div className="order-card" key={order.id}>
            <div className="order-card-header d-flex flex-row justify-content-between align-items-center">
              <h6 className="card-title">
                Order for &quot;{order.name}&quot;
                {` (Order Placed: ${format(
                  new Date(order.createdAt),
                  'MMM do, y, p',
                )})`}
              </h6>
              <button
                type="button"
                className="link-order-details hide-desktop"
                onClick={() => handleOrderButton(order)}
              >
                View Order
              </button>
            </div>
            <div className="order-card-body">
              <div className="row card-category-wrap">
                <div className="col-2 col-sm-2 col-xl-2 category-wrap__center hide-mobile">
                  <button
                    type="button"
                    className="link-order-details"
                    onClick={() => handleOrderButton(order)}
                  >
                    View Order
                  </button>
                </div>
                <div className="col-12 col-sm-12 col-xl-10">
                  <div className="row card-category-wrap">
                    <div className="col-3 col-sm-3 col-xl-3 card-category">
                      Delivery Date:
                    </div>
                    <div className="col-3 col-sm-2 col-xl-2 card-category">
                      Request Status
                    </div>
                    <div className="col-2 col-sm-2 col-xl-2 card-category">
                      Order Number
                    </div>
                    <div className="col-2 col-sm-3 col-xl-3 card-category">
                      Order Type
                    </div>
                    <div className="col-2 col-sm-2 col-xl-2 card-category">
                      Charged Total
                    </div>
                  </div>
                  <div className="order-card-border" />
                  <div className="row card-category-info-wrap">
                    <div className="col-3 col-sm-3 col-xl-3 card-category-info">
                      {order &&
                        format(
                          new Date(order.deliveryDateTime),
                          'MMM do, y, p',
                        )}
                    </div>
                    <div
                      className={`col-3 col-sm-2 col-xl-2 card-category-info ${
                        getStatus(order.status, order.displayStatus).style
                      }`}
                    >
                      {getStatus(order.status, order.displayStatus).text}
                    </div>
                    <div className="col-2 col-sm-2 col-xl-2 card-category-info">
                      {order.orderNumber}
                    </div>
                    <div className="col-2 col-sm-3 col-xl-3 card-category-info">
                      {order.orderType}
                    </div>
                    <div className="col-2 col-sm-2 col-xl-2 card-category-info">
                      <GrandTotalPrice
                        popoverHidden
                        totalPriceCents={order.totalAmount || 0}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    return orderList;
  };

  const fetchData = async () => {
    try {
      const orders = await getOrders();
      const sortOrders = {};
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in orders) {
        sortOrders[key] = orders[key]?.sort(
          (a, b) =>
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
        );
      }
      setMyOrders(sortOrders);
    } catch (e) {
      console.log('---> error', e);
      store.addNotification({
        message:
          'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (cancelledOrder) {
      setLoading(true);
      fetchData();
    }
  }, [cancelledOrder]);

  useEffect(() => {
    if (!userSignedIn) {
      history.push({
        pathname: '/',
        state: {
          showSignIn: true,
          linkRedirect: pathname,
        },
      });
    }
  });

  return (
    <main>
      {loading && <LoadingAnimation />}
      {!loading && (
        <div className="container-fluid container-orders page-content pl-4 pr-4">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <h2 className="setting-page-title">My Orders</h2>
            <div className="sorting-links-wrap">
              <button
                type="button"
                className={`orders-sorting-link ${filter === 'UPCOMING' &&
                  'active'}`}
                onClick={() => {
                  setFilter('UPCOMING');
                  setFilterKey('upcoming');
                }}
              >
                UPCOMING
              </button>
              <button
                type="button"
                className={`orders-sorting-link ${filter === 'PAST' &&
                  'active'}`}
                onClick={() => {
                  setFilter('PAST');
                  setFilterKey('past');
                }}
              >
                PAST
              </button>
            </div>
          </div>
          {GetOrdersList(myOrders[filterKey])}
          {orderShownCount === 0 && (
            <div className="order-container">
              <span>No orders available</span>
            </div>
          )}
        </div>
      )}
      {cancelOrderId && (
        <ConfirmModal
          show
          className="order__cancel"
          description="Are you sure you want to cancel this order?"
          confirmText="YES"
          cancelText="NO"
          onConfirm={() => {
            if (cateringOrderInfo) {
              cateringOrderCancel(cancelOrderId);
            }
            if (groupOrderInfo) {
              cancelGroupOrders(cancelOrderId);
            }
            setCancelOrderId(null);
            setOrderViewItem(null);
          }}
          onClose={() => {
            setCancelOrderId(null);
          }}
        />
      )}
      {loadingRequest && <LoadingAnimation />}
      {!loading && orderViewItem && (
        <OrderView
          orderItem={orderViewItem}
          onClose={() => {
            setOrderViewItem(null);
          }}
          getStatus={getStatus}
          upcoming={filterKey === 'upcoming'}
          setCancelOrderId={setCancelOrderId}
          cateringOrderInfo={cateringOrderInfo}
          groupOrderInfo={groupOrderInfo}
          getGroupOrderPdf={getGroupOrderPdf}
          getCateringOrderPdf={getCateringOrderPdf}
        />
      )}
    </main>
  );
};
const mapStateToProps = state => ({
  user: state.user && state.user.user,
  cateringOrderInfo: state.caterings.cateringOrderInfo,
  groupOrderInfo: state.caterings.groupOrderInfo,
  loadingRequest: state.caterings.loading,
  cancelledOrder: state.caterings.cancelledOrder,
});
const mapDispatchToProps = {
  getCateringOrder,
  getGroupOrders,
  cateringOrderCancel,
  cancelGroupOrders,
  getGroupOrderPdf,
  getCateringOrderPdf,
};

Orders.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    confirmed: PropTypes.bool,
  }).isRequired,
  cateringOrderInfo: PropTypes.shape({}),
  groupOrderInfo: PropTypes.shape({}),
  loadingRequest: PropTypes.bool,
  cancelledOrder: PropTypes.bool,
  getCateringOrder: PropTypes.func,
  getGroupOrders: PropTypes.func,
  cateringOrderCancel: PropTypes.func,
  cancelGroupOrders: PropTypes.func,
  getGroupOrderPdf: PropTypes.func,
  getCateringOrderPdf: PropTypes.func,
};

Orders.defaultProps = {
  getCateringOrder: () => {},
  getGroupOrders: () => {},
  cateringOrderCancel: () => {},
  cancelGroupOrders: () => {},
  getGroupOrderPdf: () => {},
  getCateringOrderPdf: () => {},
  cateringOrderInfo: null,
  groupOrderInfo: null,
  loadingRequest: false,
  cancelledOrder: false,
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Orders);
export default connectedHeader;
