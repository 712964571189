export const ADDITIONAL_ITEMS =
  'Looking to add more / different items to your order?';
export const TRUNCATE_DEFAULT_THRESHOLD = 136;
export const TRENDING_CATEGORY = 'Trending Meals';
export const PRESET_TRUNCATE_THRESHOLD = 300;
export const REVIEWS_TRUNCATE_THRESHOLD = 5;

export const SINGLE_PRESET = {
  id: 25,
  preset_location_id: 2,
  slug: 'joe-bird-pg-cluck-s-special-lunch',
  chef_ids: [283, 302],
  user_id: null,
  name: "Joe Bird + PG Cluck's Special Lunch ",
  description: 'Bringing you a combination of two Chicken Giants in this city!',
  categories: ['Board Meeting Lunch'],
  cuisine_types: ['Canadian'],
  serving_styles: ['Family Style'],
  event_types: ['Board Meeting'],
  price_per_person_cents: 14,
  number_of_guests: 30,
  max_order_notice: 48,
  weight: null,
  created_at: '2019-12-09T14:56:47.878-05:00',
  updated_at: '2019-12-15T11:42:17.747-05:00',
  listed: true,
  banner_image_url:
    'https://venuezz-production.s3.amazonaws.com/assets/preset/000/006/480/banner/header2.jpg?1575921634',
  search_result_image_url:
    'https://venuezz-production.s3.amazonaws.com/assets/preset/000/006/479/search_result/header.jpg?1575921586',
  chefs: [
    {
      id: 302,
      first_name: 'PG Clucks',
      last_name: 'Catered',
      street_number: null,
      street_name: null,
      address1: '610 College St',
      address2: '',
      city: 'Toronto',
      neighborhood: null,
      state: 'ON',
      state_code: null,
      zip: 'M6G1B4',
      country: 'Canada',
      country_code: 'CA',
      website: null,
      slug: 'pg-clucks-catered',
      social_links: null,
      email: 'pgclucks@gmail.com',
      phone: '9052527628',
      about:
        'PG Clucks is a take-out fried chicken shack downtown Toronto, specializing in sandwiches and Nashville style Hot Chicken',
      max_delivery_distance: 2,
      latitude: '43.655306',
      longitude: '-79.416773',
      certified: true,
      pre_order_notice_hour: 10,
      pre_order_min_order_amount: '100.0',
      delivery_fee: '30.0',
      free_delivery_min_order_amount: null,
      min_fee_for_onsite_cooking: null,
      onsite_cooking_available: false,
      pickup_available: true,
      email_sec: null,
      phone_sec: null,
      menus_count: 13,
      shareables: true,
      individually_packaged: true,
      price_category: 'medium',
      event_types: ['Lunch \u0026 Learn', 'Special Occasion', 'Other'],
      main_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/chef/000/005/892/chef_banner/Screen_Shot_2019-05-22_at_4.42.37_PM.png?1558558315',
      profile_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/chef/000/005/891/chef_profile/logo.png?1558558314',
    },
    {
      id: 283,
      first_name: 'Joe Bird',
      last_name: 'Catered',
      street_number: null,
      street_name: null,
      address1: '207 Queens Quay W',
      address2: '',
      city: 'Toronto',
      neighborhood: null,
      state: 'ON',
      state_code: null,
      zip: 'M5J1A7',
      country: 'Canada',
      country_code: 'CA',
      website: null,
      slug: 'joe-bird-catered',
      social_links: null,
      email: 'taylor@joebird.ca',
      phone: '4169020805',
      about:
        'Joe Bird is a place of worship to all things chicken, authentic flavors and beer (because who doesn’t like beer?). Scoot on down to the lake and join us for a trip to flavor town with a view. We’re serving up our chicken seven days a week, with a soul healing hangover curing brunch on weekends.',
      max_delivery_distance: 2,
      latitude: '43.63889',
      longitude: '-79.382546',
      certified: true,
      pre_order_notice_hour: 48,
      pre_order_min_order_amount: '200.0',
      delivery_fee: '25.0',
      free_delivery_min_order_amount: null,
      min_fee_for_onsite_cooking: null,
      onsite_cooking_available: false,
      pickup_available: false,
      email_sec: '',
      phone_sec: '',
      menus_count: 20,
      shareables: true,
      individually_packaged: true,
      price_category: 'low',
      event_types: ['Lunch \u0026 Learn'],
      main_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/chef/000/005/544/chef_banner/header.jpg?1557862603',
      profile_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/chef/000/005/543/chef_profile/logo.png?1557862600',
    },
  ],
  total_price_cents: 30000,
  delivery_price_cents: 10000,
  display_order: 20,
  view_count: null,
  order_count: null,
  preset_items: [
    {
      id: 89,
      preset_id: 25,
      name: '25 Pieces of Chicken',
      preset_menu_category_name: 'Mains',
      chef_id: 302,
      menu_id: 3014,
      quantity: 1,
      description: 'Regular and Nashville Hot',
      created_at: '2019-12-09T14:57:49.677-05:00',
      updated_at: '2019-12-09T14:57:49.677-05:00',
      main_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/898/menu_preset/WK33_Catering_LP_chicken.jpg?1558559373',
      images: [
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/898/menu_preset/WK33_Catering_LP_chicken.jpg?1558559373',
      ],
      menu_item_id: -1,
      special_instruction: '',
    },
    {
      id: 90,
      preset_id: 25,
      name: 'Bean, buckwheat \u0026 corn chili [Vegetarian]',
      preset_menu_category_name: 'Mains',
      chef_id: 283,
      menu_id: 2758,
      quantity: 5,
      description:
        'Bean, buckwheat \u0026 corn chili \r\n\r\nWith crispy tortillas\r\n',
      created_at: '2019-12-09T14:58:18.927-05:00',
      updated_at: '2019-12-09T14:58:18.927-05:00',
      main_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/550/menu_preset/bveggiechil.jpg?1557863593',
      images: [
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/550/menu_preset/bveggiechil.jpg?1557863593',
      ],
      menu_item_id: -1,
      special_instruction: '',
    },
    {
      id: 91,
      preset_id: 25,
      name: 'Party Platter [Serves 4-6]',
      preset_menu_category_name: 'Mains',
      chef_id: 283,
      menu_id: 2756,
      quantity: 1,
      description:
        '12 Pieces of Grilled or Fried Chicken\r\n\r\n1 extra-large side of patatas bravas, 2 salads (Superfood\r\n\u0026 Newton), brussel sprouts with balsamic-lemon glaze, 3 corn\r\nbreads and the 6 sauce flight',
      created_at: '2019-12-09T14:58:53.748-05:00',
      updated_at: '2019-12-09T14:58:53.748-05:00',
      main_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/548/menu_preset/partyplatter.jpg?1557863297',
      images: [
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/548/menu_preset/partyplatter.jpg?1557863297',
      ],
      menu_item_id: -1,
      special_instruction: '',
    },
    {
      id: 92,
      preset_id: 25,
      name: 'Coop Salad',
      preset_menu_category_name: 'Salads',
      chef_id: 283,
      menu_id: 2762,
      quantity: 30,
      description:
        'Spring mix, pumpkin seeds, coconut, with hibiscus vinaigrette.\r\n',
      created_at: '2019-12-09T14:59:14.157-05:00',
      updated_at: '2019-12-09T14:59:14.157-05:00',
      main_image_url:
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/554/menu_preset/coop.jpg?1557864714',
      images: [
        'https://venuezz-production.s3.amazonaws.com/assets/menu/000/005/554/menu_preset/coop.jpg?1557864714',
      ],
      menu_item_id: -1,
      special_instruction: '',
    },
  ],
};

export const BROWSE_RESTAURANT_URL =
  'https://vendor.cateredclub.com/listings?qr=&qm=&location=Toronto,%20ON,%20Canada&lat=43.653226&lng=-79.38318429999998';

export const SORT_OPTIONS = {
  DEFAULT: 'Popular',
  SORT_BY_PRICE_DESC: 'Sort by Price High to Low',
  SORT_BY_PRICE_ASC: 'Sort by Price Low to High',
  SORT_BY_HEADCOUNT_DESC: 'Sort by Headcount High to Low',
  SORT_BY_HEADCOUNT_ASC: 'Sort by Headcount Low to High',
};
