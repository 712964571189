/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { store } from 'react-notifications-component';
import * as yup from 'yup';
import FormSelect from 'components/common/FormSelect';
import {
  REQUIRED_RECAPCHA,
  REQUIRED_VALIDATION,
  INVALID_EMAIL,
  NUMBER_OF_PEOPLE,
  ORDER_FREQUENCY,
  INVALID_OPTION,
} from 'consts/form';
import FormError from 'components/common/FormError';
import { bookDemo } from 'api/book';
import LoadingAnimation from 'components/common/LoadingAnimation';

const validationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_VALIDATION),
  email: yup
    .string()
    .required(REQUIRED_VALIDATION)
    .email(INVALID_EMAIL),
  company: yup.string().required(REQUIRED_VALIDATION),
  phone: yup.string().required(REQUIRED_VALIDATION),
  numPeopleRange: yup
    .mixed()
    .oneOf(NUMBER_OF_PEOPLE, INVALID_OPTION)
    .required(REQUIRED_VALIDATION), // 5-10, 10-50, etc
  orderFrequency: yup
    .mixed()
    .oneOf(ORDER_FREQUENCY, INVALID_OPTION)
    .required(REQUIRED_VALIDATION),
  message: yup.string(),
  recapFinished: yup
    .boolean()
    .oneOf([true], REQUIRED_RECAPCHA)
    .required(REQUIRED_RECAPCHA),
});

const initialValues = {
  name: '',
  email: '',
  company: '',
  phone: '',
  numPeopleRange: '',
  orderFrequency: '',
  message: '',
  recapFinished: false,
};

const BookDemo = () => {
  const [loading, setLoading] = useState(false);
  const recapRef = useRef();

  const onSubmit = async form => {
    setLoading(true);
    try {
      await bookDemo({ message: form });
      store.addNotification({
        message:
          'Your message was successfully sent! One of our experts will be in contact with you shortly',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 8000,
        },
      });
    } catch (e) {
      console.log('--> error', e);
      store.addNotification({
        message:
          'Sorry, we are not able to process your request now. Please try again later.',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'bounceIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 4000,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      {loading && <LoadingAnimation />}
      <div className="container">
        <img
          className="img-fluid"
          src="/assets/img/book-demo/head.jpg"
          alt="head"
        />
        <div className="row">
          <div className="offset-xl-2 col-xl-8 my-5">
            <h2 className="book-demo--title text-center mb-3">
              Discover how simple and cost-effective corporate catering can be
            </h2>
            <p className="book-demo--description">
              From regularly scheduled, weekly lunches to special occasions, and
              corporate breakfasts or one-off orders and everything in between -
              we&apos;ll help you get setup with the a catered meal program that
              fits your team&apos;s requirements.
            </p>
            <Formik
              {...{ initialValues, validationSchema }}
              validateOnChange
              validateOnBlur
              onSubmit={onSubmit}
            >
              {({
                setFieldValue,
                values,
                touched,
                handleBlur,
                setFieldTouched,
              }) => (
                <Form className="book-demo--form mt-5">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          className="form-control"
                          type="text"
                          name="name"
                        />
                        <FormError name="name" />
                        <label
                          className={classnames({
                            'label-focused': touched.name && values.name,
                          })}
                        >
                          Name
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          className="form-control"
                          type="email"
                          name="email"
                        />
                        <FormError name="email" />
                        <label
                          className={classnames({
                            'label-focused': touched.email && values.email,
                          })}
                        >
                          Email
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          className="form-control"
                          type="text"
                          name="company"
                        />
                        <FormError name="company" />
                        <label
                          className={classnames({
                            'label-focused': touched.company && values.company,
                          })}
                        >
                          Company
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="labeled-group floating">
                        <Field
                          className="form-control"
                          type="text"
                          name="phone"
                        />
                        <FormError name="phone" />
                        <label
                          className={classnames({
                            'label-focused': touched.phone && values.phone,
                          })}
                        >
                          Phone
                        </label>
                      </div>
                      {/* <div className="book-demo--phone">
                          <div className="delimiter" />
                          <div className="labeled-group floating">
                            <Field
                              className="form-control"
                              type="text"
                              name="ext"
                            />
                            <label>Ext.</label>
                          </div>
                        </div> */}
                    </div>
                    <div className="col-12">
                      <div className="labeled-group">
                        <FormError name="numPeopleRange" />
                        <FormSelect
                          name="numPeopleRange"
                          placeholder="How many people would you like to order meals for?"
                          options={NUMBER_OF_PEOPLE}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onFocus={() => {
                            setFieldTouched('numPeopleRange');
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="labeled-group">
                        <FormError name="orderFrequency" />
                        <FormSelect
                          name="orderFrequency"
                          placeholder="How often would you like to order meals to your office?"
                          options={ORDER_FREQUENCY}
                          onChange={setFieldValue}
                          onBlur={handleBlur}
                          onFocus={() => {
                            setFieldTouched('orderFrequency');
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="labeled-group">
                        <Field
                          className="form-control"
                          name="message"
                          as="textarea"
                          rows={4}
                          placeholder="Tell us a bit about what you're looking for. (Optional)"
                        />
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <ReCAPTCHA
                        ref={recapRef}
                        sitekey="6LdRSuAUAAAAACb_JHMKlEVWruRylSJ606Jb8212"
                        onChange={value => {
                          setFieldValue('recapFinished', value !== null);
                        }}
                      />
                      <FormError name="recapFinished" />
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="book-demo--features mb-5">
          <div className="book-demo--feature">
            <div className="cover">
              <img
                className="img-fluid"
                src="/assets/img/book-demo/meal_1.jpg"
                alt="meal_1"
              />
              <div className="title">Daily Food Programs</div>
            </div>
            <div className="subtitle">
              Daily curated menus that meet all of your teams requirements.
            </div>
            <p>
              Our team of Foodies will work with you to create the perfect
              office catering program - delivered regularly. All of our meals
              will meet all of your groups requirements including headcount,
              dietary restrictions, price points and taste profiles. Always
              something new and fresh to try!
            </p>
          </div>
          <div className="book-demo--feature">
            <div className="cover">
              <img
                className="img-fluid"
                src="/assets/img/book-demo/meal_2.jpg"
                alt="meal_2"
              />
              <div className="title">One-Off Orders</div>
            </div>
            <div className="subtitle">
              Order anytime for any occasion no strings attached and no
              commitment.
            </div>
            <p>
              Do you have an upcoming breakfast, lunch, or dinner, and don&pos;t
              know what to order? Catering for your business while staying on
              budget and trying something new has never been simpler.
            </p>
          </div>
          <div className="book-demo--feature">
            <div className="cover">
              <img
                className="img-fluid"
                src="/assets/img/book-demo/meal_3.jpg"
                alt="meal_3"
              />
              <div className="title">Office Treats</div>
            </div>
            <div className="subtitle">
              Keep your team&pos;s energy high with Snacks &amp; Drinks!
            </div>
            <p>
              With nearly unlimited options of snacks and high quality,
              energizing drinks, let the Catered Club team put together the
              perfect mix for your office pantry - conveniently delivered to
              your office.
            </p>
          </div>
          <div className="book-demo--feature">
            <div className="cover">
              <img
                className="img-fluid"
                src="/assets/img/book-demo/meal_4.jpg"
                alt="meal_4"
              />
              <div className="title">Event Planning</div>
            </div>
            <div className="subtitle">Let us plan - so you can party!</div>
            <p>
              Let our awesome team of Event Planners plan your next office
              event. It can be on-site or off-site we can do it all, from
              finding venues, and booking dj&pos;s, to creating custom ice
              sculptures - the whole deal! Think intimate cocktail parties,
              sit-down dinners, company offsites, seasonal soirées, and more!
            </p>
          </div>
        </div>
        <h2 className="text-center pt-5">
          Top Companies Trust Catered Club to cater to their teams!
        </h2>
        <div className="book-demo--logos pt-2 pb-5 my-5">
          <div className="book-demo--logo">
            <img
              src="/assets/img/book-demo/logos/shopify.png"
              height={26}
              alt="shopify"
            />
          </div>
          <div className="book-demo--logo">
            <img
              src="/assets/img/book-demo/logos/wework.png"
              height={23}
              alt="wework"
            />
          </div>
          <div className="book-demo--logo">
            <img
              src="/assets/img/book-demo/logos/uber.png"
              height={17}
              alt="uber"
            />
          </div>
          <div className="book-demo--logo">
            <img
              className="mr-5"
              src="/assets/img/book-demo/logos/nfl.png"
              height={27}
              alt="nfl"
            />
          </div>
          <div className="book-demo--logo">
            <img
              src="/assets/img/book-demo/logos/deloitte.png"
              height={15}
              alt="deloitte"
            />
          </div>
          <div className="book-demo--logo">
            <img
              src="/assets/img/book-demo/logos/amazon.png"
              height={24}
              alt="amazon"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default BookDemo;
