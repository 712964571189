import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function modalReducer(state = initialState.modal, action) {
  switch (action.type) {
    case types.SHOW_SIGNIN_MODAL_SUCCESS:
      return {
        ...state,
        signUpModalShown: false,
        confirmModalShown: false,
        signInModalShown: true,
        modalHeader: action.text,
        resetPasswordModalShown: false,
      };
    case types.SHOW_SIGNUP_MODAL_SUCCESS:
      return {
        ...state,
        signUpModalShown: true,
        signInModalShown: false,
        confirmModalShown: false,
        resetPasswordModalShown: false,
      };
    case types.CLOSE_SIGNIN_SIGNUP_MODAL_SUCCESS: // close all modals - maybe after signin/sign up success
      return {
        signUpModalShown: false,
        signInModalShown: false,
        confirmModalShown: false,
        resetPasswordModalShown: false,
      };
    case types.SHOW_PASSWORD_MODAL_SUCCESS:
      return {
        ...state,
        signUpModalShown: false,
        signInModalShown: false,
        confirmModalShown: false,
        resetPasswordModalShown: true,
      };
    case types.SHOW_CONFIRM_MODAL_SUCCESS:
      return {
        signUpModalShown: false,
        signInModalShown: false,
        confirmModalShown: true,
        modalHeader: action.modalHeader,
        modalText: action.modalText,
      };
    default:
      return state;
  }
}
