import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getPresetSubTotalPrice } from 'utils/preset.util';

import './index.scss';
import { priceDecimalFormatter } from 'formatter/preset.formatter';
import CheckoutInfo from '../CheckoutInfo';

const OrderBasket = ({
  onCheckoutClick,
  buttonWrap,
  totalPrice,
  totalItems,
  preset,
  deliveryPriceCents,
  checkoutData,
  removeCheckoutItem,
  setCurrentPresetItemFromCheckoutData,
  receivingMethod,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const subTotal = getPresetSubTotalPrice(checkoutData);
  const totalPriceCents =
    receivingMethod === 'delivery'
      ? (subTotal + deliveryPriceCents) * (1 + preset.taxRate)
      : subTotal * (1 + preset.taxRate);

  return (
    <div className="home--form order-bk__wrapper p-0">
      <button
        type="button"
        className={`order-bk__toggler ${isOpened ? 'active' : ''}`}
        onClick={() => setIsOpened(!isOpened)}
      >
        <span className="order-bk__toggler-inner">
          <div className="order-bk__toggler-info">
            <h4>
              Total ${priceDecimalFormatter(totalPrice || totalPriceCents)}
            </h4>
            <span>{totalItems} Items</span>
          </div>
          <img
            src="/assets/img/select-caret.svg"
            className="d-block order-bk-caret"
          />
          {buttonWrap || (
            <button
              className="btn btn-primary"
              onClick={e => {
                e.stopPropagation();
                onCheckoutClick();
              }}
              type="button"
            >
              Checkout
            </button>
          )}
        </span>
      </button>

      {isOpened && (
        <div
          className={`order-bk__body order-details ${isOpened ? 'active' : ''}`}
        >
          <label className="disabled text-uppercase d-block mb-2">
            Order basket
          </label>
          <CheckoutInfo
            preset={preset}
            deliveryPriceCents={deliveryPriceCents}
            checkoutData={checkoutData}
            removeCheckoutItem={removeCheckoutItem}
            setCurrentPresetItemFromCheckoutData={
              setCurrentPresetItemFromCheckoutData
            }
          />
        </div>
      )}
    </div>
  );
};

OrderBasket.propTypes = {
  onCheckoutClick: PropTypes.func,
  buttonWrap: PropTypes.element || PropTypes.nullable,
  removeCheckoutItem: PropTypes.func,
  setCurrentPresetItemFromCheckoutData: PropTypes.func,
  totalPrice: PropTypes.number,
  totalItems: PropTypes.number,
  preset: PropTypes.shape(),
  deliveryPriceCents: PropTypes.number,
  receivingMethod: PropTypes.string,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
};

OrderBasket.defaultProps = {
  onCheckoutClick: () => {},
  removeCheckoutItem: () => {},
  setCurrentPresetItemFromCheckoutData: () => {},
  totalPrice: 0,
  totalItems: 0,
  preset: {},
  deliveryPriceCents: 0,
  checkoutData: [],
  buttonWrap: null,
  receivingMethod: '',
};

export default OrderBasket;
