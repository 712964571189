import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { priceDecimalFormatter } from 'formatter/preset.formatter';

const popover = (
  <Popover id="popover-basic">
    <Popover.Content>
      This total price includes <strong>tax</strong> AND{' '}
      <strong>delivery</strong>. This is the final price that you will see
      charged to your credit card.
    </Popover.Content>
  </Popover>
);

export default function GrandTotalPrice({ totalPriceCents, popoverHidden }) {
  return (
    <span style={{ position: 'relative' }}>
      ${priceDecimalFormatter(totalPriceCents)}&nbsp;
      {!popoverHidden && (
        <span
          className="help"
          style={{ marginTop: -2, position: 'absolute', cursor: 'pointer' }}
        >
          <OverlayTrigger placement="top" overlay={popover}>
            <svg className="icon icon-help">
              <use xlinkHref="#spriteIcon-help" />
            </svg>
          </OverlayTrigger>
        </span>
      )}
    </span>
  );
}

GrandTotalPrice.propTypes = {
  totalPriceCents: PropTypes.number,
  popoverHidden: PropTypes.bool,
};

GrandTotalPrice.defaultProps = {
  totalPriceCents: 0,
  popoverHidden: false,
};
