import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { showLightBoxSuccess } from 'redux/actions/lightboxActions';

import { connect } from 'react-redux';

import './index.scss';

const WelcomeModal = ({ showLightBoxSuccess }) => {
  const [show, setShow] = useState(true);
  return (
    <Modal id="welcomeModal" show={show} size="lg" backdrop="static">
      <button
        className="close"
        type="button"
        aria-label="Close"
        onClick={() => {
          setShow(false);
          showLightBoxSuccess();
        }}
      />
      <div className="modal-sidebar" />
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <div className="modal-text">
            <h2>We have taken the guess work out of what to order!</h2>
            <p>
              Having done over 100,000 deliveries, we’ve come up with the most
              popular preset menus that are sure to be crowd pleasers! No more
              crossing your fingers that what you ordered will be good. We’ve
              hand-picked the best options from the best restaurants so all you
              have to do is adjust it to your needs and you’re good to go!
              Guaranteed quality. We’ve created the templates, you edit and
              order!
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 modal-image">
          <img src="/assets/img/picforlightboxpopup.jpg" alt="lightbox" />
        </div>
      </div>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  showLightBoxSuccess: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showLightBoxSuccess,
};

export default connect(null, mapDispatchToProps)(WelcomeModal);
