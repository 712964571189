import { getTopBarSearchResult } from 'api/searchApi';
import * as types from './actionTypes';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function getTopBarSearchResultSuccess(presets, q) {
  return { type: types.TOP_BAR_SEARCH_RESULT_SUCCESS, presets, q };
}

export function setTopBarSearchFocused(isFocused) {
  return { type: types.TOP_BAR_SEARCH_INPUT_FOCUSED, isFocused };
}

export function topBarSearch(q) {
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      let results = [];
      if (q && q.length >= 3) {
        const { presets } = await getTopBarSearchResult(q);
        results = presets;
      }
      const action = getTopBarSearchResultSuccess(results, q);
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function topBarSearchFocused(isFocused) {
  return async dispatch => {
    dispatch(setTopBarSearchFocused(isFocused));
  };
}
