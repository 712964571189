import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {
  closeSignInSignUpModal,
  showSignInModal,
} from 'redux/actions/modalActions';
import VoidLink from './VoidLink';

const SignUpConfirmModal = ({
  confirmModalShown,
  closeSignInSignUpModal,
  showSignInModal,
  modalHeader,
  modalText,
}) => {
  return (
    <Modal
      dialogClassName="modal-signup-confirm"
      show={confirmModalShown}
      size="lg"
      onHide={() => {
        closeSignInSignUpModal();
      }}
    >
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          closeSignInSignUpModal();
        }}
      />
      <Modal.Header>
        <h3>{modalHeader}</h3>
      </Modal.Header>

      <Modal.Body className="modal--sign-up">
        <h3 style={{ textAlign: 'center' }}>{modalText}</h3>
        <div className="text-center mt-4">
          Already have an account? &nbsp;
          <VoidLink
            onClick={() => {
              showSignInModal();
            }}
          >
            <b>Sign In</b>
          </VoidLink>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  confirmModalShown: state.modal.confirmModalShown,
  modalHeader: state.modal.modalHeader,
  modalText: state.modal.modalText,
});

const mapDispatchToProps = {
  closeSignInSignUpModal,
  showSignInModal,
};

SignUpConfirmModal.propTypes = {
  confirmModalShown: PropTypes.bool,
  closeSignInSignUpModal: PropTypes.func.isRequired,
  showSignInModal: PropTypes.func.isRequired,
  modalHeader: PropTypes.string,
  modalText: PropTypes.string,
};

SignUpConfirmModal.defaultProps = {
  confirmModalShown: false,
  modalHeader: '',
  modalText: '',
};

const connectedSignUpConfirmModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpConfirmModal);

export default connectedSignUpConfirmModal;
