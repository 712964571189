import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';

import ConditionalWrap from 'components/common/ConditionalWrap';

import './index.scss';

const mapStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const MapModal = ({
  show,
  onClose,
  firstName,
  shortAddress,
  slug,
  latitude,
  longitude,
}) => {
  const googleMapEl = useRef(null);

  function triggerClickOnFullscreenButton() {
    if (googleMapEl.current && googleMapEl.current.mapRef) {
      const el = googleMapEl.current.mapRef.querySelector(
        'button.gm-fullscreen-control',
      );
      if (el) {
        el.click();
      }
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        onClose();
      }}
      dialogClassName="location-modal"
    >
      <button
        className="close"
        type="button"
        aria-label="Close"
        onClick={() => {
          onClose();
        }}
      />

      <div className="location-modal__body">
        <p className="locatio-modal__name mb-2">{firstName}</p>
        <p className="location-modal__address mb-3">{shortAddress}</p>

        <Link to={slug} type="submit" className="btn btn-primary mb-4">
          View Details
        </Link>

        <div className="location-modal__map-holder">
          <ConditionalWrap
            condition={Boolean(
              window.google && Object.keys(window.google.maps) > 0,
            )}
            wrap={() => (
              <LoadScriptNext googleMapsApiKey="AIzaSyCZah7g2ZEiY5RZKMJY9QejlVW0LPKQi6g" />
            )}
          >
            <GoogleMap
              ref={googleMapEl}
              mapContainerStyle={mapStyle}
              center={{ lat: Number(latitude), lng: Number(longitude) }}
              zoom={15}
            >
              <Marker
                position={{ lat: Number(latitude), lng: Number(longitude) }}
              />
            </GoogleMap>
          </ConditionalWrap>
        </div>

        <div className="text-right">
          <button
            type="button"
            className="btn btn-link px-0 fw-500"
            onClick={e => {
              e.preventDefault();
              triggerClickOnFullscreenButton();
            }}
          >
            View full screen
          </button>
        </div>
      </div>
    </Modal>
  );
};

MapModal.propTypes = {
  onClose: PropTypes.func,
  show: PropTypes.bool,
  firstName: PropTypes.string,
  shortAddress: PropTypes.string,
  slug: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
};

MapModal.defaultProps = {
  onClose: () => {},
  show: false,
  firstName: '',
  shortAddress: '',
  slug: '',
  latitude: '',
  longitude: '',
};

export default MapModal;
