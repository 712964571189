import React from 'react';
import PropTypes from 'prop-types';

const OrderError = ({ errorText }) => {
  return (
    <div className="alert alert-danger">
      <svg className="icon icon-warning">
        <use xlinkHref="#spriteIcon-warning" />
      </svg>
      <span>{errorText}</span>
    </div>
  );
};

OrderError.propTypes = {
  errorText: PropTypes.string,
};

OrderError.defaultProps = {
  errorText: '',
};
export default OrderError;
