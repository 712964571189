/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import {
  closeSignInSignUpModal,
  showSignInModal,
} from 'redux/actions/modalActions';
import { REQUIRED_VALIDATION, INVALID_EMAIL } from 'consts/form';
import { passwordReset } from 'api/authApi';
import VoidLink from './VoidLink';
import FormError from './FormError';
import LoadingAnimation from './LoadingAnimation';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
});

const initialValues = {
  email: '',
};

const ForgotPasswordModal = ({
  resetPasswordModalShown,
  closeSignInSignUpModal,
  showSignInModal,
  modalHeader,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setEmailSent(false);
    setErrorMessages(null); // recover initial state
  }, [resetPasswordModalShown]);

  async function onSubmit(formData) {
    setLoading(true);

    try {
      await passwordReset({ user: formData });
      setEmailSent(true);
    } catch (e) {
      const { message } = await e.response.json();
      setEmailSent(false);
      setErrorMessages([message]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={resetPasswordModalShown}
      onHide={() => {
        setErrorMessages(null);
        closeSignInSignUpModal();
      }}
    >
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          setErrorMessages(null);
          closeSignInSignUpModal();
        }}
      />
      {loading && <LoadingAnimation />}
      <Modal.Header>
        <h3>{modalHeader}</h3>
      </Modal.Header>

      <Modal.Body className="modal--sign-in pb-5">
        {!emailSent && (
          <>
            {errorMessages && (
              <div className="alert alert-danger py-4 py-xl-3 d-flex align-items-center">
                <svg className="icon icon-warning">
                  <use xlinkHref="#spriteIcon-warning" />
                </svg>
                <span>{errorMessages.join(', ')}</span>
              </div>
            )}
            <Formik
              {...{ initialValues, validationSchema }}
              validateOnChange
              validateOnBlur
              onSubmit={form => {
                onSubmit(form);
              }}
            >
              {({ values }) => (
                <Form>
                  <h3 className="hide-mobile mb-3">Password Reset</h3>
                  <div className="labeled-group floating">
                    <Field type="email" className="form-control" name="email" />
                    <FormError name="email" />
                    <label
                      className={classnames({
                        'label-focused': values.email,
                      })}
                    >
                      Email
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-2"
                  >
                    Send Reset Link
                  </button>
                </Form>
              )}
            </Formik>
          </>
        )}
        {emailSent && (
          <h3 style={{ textAlign: 'center' }}>
            Please check your email for your reset password instructions. Thank
            you!
          </h3>
        )}
        <div className="form-footer mt-4">
          <VoidLink onClick={() => showSignInModal()}>
            <b>Back to Sign In</b>
          </VoidLink>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  resetPasswordModalShown: state.modal.resetPasswordModalShown,
  modalHeader: state.modal.modalHeader,
});

const mapDispatchToProps = {
  closeSignInSignUpModal,
  showSignInModal,
};

ForgotPasswordModal.propTypes = {
  resetPasswordModalShown: PropTypes.bool.isRequired,
  closeSignInSignUpModal: PropTypes.func.isRequired,
  showSignInModal: PropTypes.func.isRequired,
  modalHeader: PropTypes.string,
};

ForgotPasswordModal.defaultProps = {
  modalHeader: REQUIRED_VALIDATION,
};

const connectedForgotPasswordModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordModal);
export default connectedForgotPasswordModal;
