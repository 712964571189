import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import { Link } from 'react-router-dom';

import './index.scss';
// import { priceDecimalFormatter } from 'formatter/preset.formatter';

const TopBarSearch = ({ presets, query, onClose }) => {
  if (query.length < 3) {
    return null;
  }

  const chefsOrPresets = groupBy(presets, 'defaultPreset');

  return (
    <main className="topbar-container">
      <div className="search">
        <div className="container">
          <button
            className="topbar-container__close"
            type="button"
            onClick={onClose}
          />
          <h2>Results for “{query}”</h2>
          <div className="search--results mt-5">
            {chefsOrPresets.true && chefsOrPresets.true.length > 0 && (
              <div className="search--result-group">
                <div className="name name--preset">{query} Restaurants</div>
                <div>
                  {chefsOrPresets.true.map(preset => {
                    const { slug, name, chefImages } = preset;
                    return (
                      <Link
                        key={preset.id}
                        className="search--result-item"
                        to={`/chefs/${slug}`}
                      >
                        <span>
                          {chefImages.map(chefImage => (
                            <img
                              key={chefImage}
                              src={chefImage}
                              alt="chefImage"
                            />
                          ))}
                        </span>
                        <span className="name">{name}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}

            {/* {chefsOrPresets.false && chefsOrPresets.false.length > 0 && (
              <div className="search--result-group">
                <div className="name name--preset">{query} Presets</div>
                <div>
                  {chefsOrPresets.false.map(preset => {
                    const {
                      slug,
                      name,
                      numberOfGuests,
                      pricePerPersonCents,
                      servingStyles,
                      chefImages,
                    } = preset;
                    return (
                      <Link
                        key={preset.id}
                        className="search--result-item"
                        to={`/preset-detail/${slug}`}
                      >
                        <span>
                          {chefImages.map(chefImage => (
                            <img
                              key={chefImage}
                              src={chefImage}
                              alt="chefImage"
                            />
                          ))}
                        </span>
                        <span className="name">{name}</span>
                        <span>
                          Price: ${priceDecimalFormatter(pricePerPersonCents)} /
                          person
                        </span>
                        <span>Serves {numberOfGuests}</span>
                        <span>{servingStyles}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </main>
  );
};

TopBarSearch.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  query: PropTypes.string,
  onClose: PropTypes.func,
};

TopBarSearch.defaultProps = {
  presets: [],
  query: '',
  onClose: () => {},
};

export default TopBarSearch;
