import { beginApiCall, apiCallError } from 'redux/actions/apiStatusActions';
import { batch } from 'react-redux';
import {
  fetchFavoritesAndSavedOrders,
  removeFavorite,
  removeSavedPreset,
} from 'api/presetApi';
import { store } from 'react-notifications-component';
import * as types from './actionTypes';

export const fetchFavoritesRequested = () => {
  return { type: types.FAVORITES_FETCH_REQUESTED };
};

export const fetchFavoritesFailed = error => {
  return { type: types.FAVORITES_FETCH_FAILED, payload: { error } };
};

export const fetchFavoritesSucceeded = presets => {
  return { type: types.FAVORITES_FETCH_SUCCEEDED, payload: { presets } };
};

export const fetchFavorites = () => {
  // fetchFavoritesAndSavedOrders
  return async dispatch => {
    batch(() => {
      dispatch(beginApiCall());
      dispatch(fetchFavoritesRequested());
    });

    try {
      const response = await fetchFavoritesAndSavedOrders();
      console.log('--> response checking', response);
      dispatch(fetchFavoritesSucceeded(response.presets));
    } catch (error) {
      batch(() => {
        dispatch(apiCallError(error));
        dispatch(fetchFavoritesFailed(error));
      });
      throw error;
    }
  };
};

// Remove favorite or saved on browse restaurants page
export const removeFavoriteOrSavedRequested = () => {
  return { type: types.REMOVE_FAVORITE_OR_SAVED_REQUESTED };
};

export const removeFavoriteOrSavedSucceeded = payload => {
  return { type: types.REMOVE_FAVORITE_OR_SAVED_SUCCEEDED, payload };
};

export const removeFavoriteOrSavedFailed = error => {
  return { type: types.REMOVE_FAVORITE_OR_SAVED_FAILED, payload: { error } };
};

export const removeFavoriteOrSaved = (presetId, isFavorites) => {
  return async dispatch => {
    batch(() => {
      dispatch(beginApiCall());
      dispatch(removeFavoriteOrSavedRequested());
    });
    try {
      if (isFavorites) {
        await removeFavorite(presetId);
        store.addNotification({
          message: 'Removed from your Favorites. You can add it back anytime.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
      } else {
        // isSavedPresets
        await removeSavedPreset(presetId);
        store.addNotification({
          message: 'Removed from your saved list.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'bounceIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 4000,
          },
        });
      }
      dispatch(removeFavoriteOrSavedSucceeded({ presetId, isFavorites }));
    } catch (error) {
      batch(() => {
        dispatch(apiCallError(error));
        dispatch(removeFavoriteOrSavedFailed(error));
      });
      throw error;
    }
  };
};
