import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import DeliveryInfoFormInline from 'components/common/DeliveryInfoForm/DeliveryInfoFormInline';
import RestaurantCard from 'components/common/RestaurantCard';
import MapModal from 'components/common/MapModal';

import { getQueryString } from 'utils/address.util';
import {
  loadRestaurants,
  toggleFilterTag,
  setSelectedTags,
  // removeFavoriteOrSaved,
} from 'redux/restaurants/actions';
import { getFilteredChefs } from 'selectors/restaurants';
// import NoFavorites from 'pages/MadeForYou/NoFavorites';
import { Link } from 'react-router-dom';
import MenuCardPlaceholder from '../MadeForYou/MenuCardPlaceholder';

import './index.scss';
// import ConfirmModal from 'components/common/ConfirmModal';
// import LoadingAnimation from 'components/common/LoadingAnimation';

const BrowseRestaurants = () => {
  const dispatch = useDispatch();
  // const [favoriteRemoveId, setFavoriteRemoveId] = useState(null);
  // const [savedRemoveId, setSavedRemoveId] = useState(null);
  const userSignedIn = useSelector(state => get(state, 'user.user.confirmed'));
  const {
    loading,
    loadingMore,
    currentPage,
    totalPage,
    filterTags,
    selectedFilterTags,
  } = useSelector(state => state.restaurants);
  const [formDataQuery, setFormDataQuery] = useState(getQueryString());
  const [filteredChefs, filteredTitle] = useSelector(getFilteredChefs);

  const [showMapModal, setShowMapModal] = useState(false);
  const [mapModalData, setMapModalData] = useState({});

  function openRestaurantLocation({
    firstName,
    shortAddress,
    slug,
    latitude,
    longitude,
  }) {
    setMapModalData({
      firstName,
      shortAddress,
      slug,
      latitude,
      longitude,
    });
    setShowMapModal(true);
  }

  const fetchData = () => {
    dispatch(
      loadRestaurants({
        queryParams: formDataQuery,
        page: currentPage,
        loadingMore: false,
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, [formDataQuery, userSignedIn]);

  const onDeliveryFormSubmit = query => {
    setFormDataQuery(query);
  };

  const loadingPlaceholder = (
    <div className="slick-initialized slick-slider slick slick-grid home--carousel">
      <div className="slick-list">
        <div className="slick-track slick-track--wrapped">
          <MenuCardPlaceholder />
        </div>
      </div>
    </div>
  );

  return (
    <main>
      <DeliveryInfoFormInline
        isAbleToChangeReceivingMethod
        onSubmit={onDeliveryFormSubmit}
      />

      <div className="container">
        <div className="row align-items-center mb-40-20">
          <div className="col-12 d-md-flex align-items-center text-center text-md-left">
            {loading ? (
              <h3 className="page-title mt-0 mb-3 mb-md-0 text-center text-md-left">
                Searching for restaurants near you...
              </h3>
            ) : (
              <>
                <h1 className="page-title mt-0 mb-3 mb-md-0 d-block d-xl-inline-block text-center text-md-left">
                  {filteredChefs.length} Restaurant
                  {filteredChefs.length === 1 ? '' : 's'}
                </h1>
                <div className="alert alert-custom mb-0 ml-md-4">
                  <img src="assets/img/ico-deliver-human-1.svg" alt="Waiter" />
                  Free online ordering
                </div>
              </>
            )}
          </div>
        </div>

        {loading && loadingPlaceholder}

        {!loading && (
          <>
            {filterTags.length > 0 && (
              <div className="rests-filter__section">
                <p className="rests-filter__title">Search by filter</p>

                <div className="rests-filter__wrap">
                  {filterTags.map((tag, i) => (
                    <div
                      key={tag}
                      className="custom-control custom-checkbox custom-checkbox-100"
                    >
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={tag.replace(' ', '') + i}
                        onChange={() => dispatch(toggleFilterTag(tag))}
                        checked={selectedFilterTags.includes(tag)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={tag.replace(' ', '') + i}
                      >
                        <span>{tag}</span>
                      </label>
                    </div>
                  ))}

                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary px-4"
                      onClick={() => dispatch(setSelectedTags(filterTags))}
                    >
                      All
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-link"
                      onClick={() => dispatch(setSelectedTags([]))}
                    >
                      Clear selected
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="made--section-content mb-xl-1">
              <h3 className="mb-4">{filteredTitle} restaurants</h3>
              {userSignedIn && (
                <Link to="/favorites">View Your Favorites / Saved Orders</Link>
              )}
              <div className="mt-4 slick-initialized slick-slider slick slick-grid">
                <div className="slick-list">
                  {filteredChefs.length === 0 ? (
                    <div className="check__other">
                      <span>
                        <img src="/assets/img/foodicon.svg" alt="foodicon" />
                      </span>
                      <p>Hmm.. It looks like there are no options.</p>
                      <br />
                      <p>This could be for a few reasons.</p>
                      <br />
                      <p>
                        1) Click Update Details above and update your delivery
                        date and time. Most vendors require a minimum of 24
                        hours notice and some even require 48 hours. So the
                        further out the delivery is scheduled, the more options
                        you will likely see.
                      </p>
                      <br />
                      <p>
                        2) Update your delivery address, most of our vendors are
                        near the core of each city. If you still don't see
                        results, you can contact us at support@cateredclub.com
                        and we can work on getting some results in your area!
                      </p>
                    </div>
                  ) : null}
                  <div className="slick-track slick-track--wrapped pt-1">
                    {filteredChefs.map(chef => {
                      const {
                        id,
                        chefIds,
                        slug,
                        name,
                        distance,
                        searchResultImageUrl,
                        shortAddress,
                        cuisineTypes,
                        chefImages,
                        latitude,
                        longitude,
                        distanceUnit,
                        description,
                        favorite,
                      } = chef;
                      return (
                        <RestaurantCard
                          key={`${id}-${slug}`}
                          firstName={name}
                          profileImageUrl={
                            chefImages[chefIds[0]].profileImageUrl
                          }
                          tagNames={cuisineTypes}
                          mainImageUrl={searchResultImageUrl}
                          {...{
                            favorite,
                            latitude,
                            longitude,
                            description,
                            distance,
                            distanceUnit,
                            shortAddress,
                            slug: `/chefs/${slug}`,
                            openRestaurantLocation,
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              {loadingMore && loadingPlaceholder}
              {!loadingMore && totalPage > currentPage && (
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    dispatch(
                      loadRestaurants({
                        queryParams: formDataQuery,
                        page: currentPage + 1,
                        loadingMore: true,
                      }),
                    );
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <MapModal
        show={showMapModal}
        onClose={() => setShowMapModal(false)}
        firstName={mapModalData.firstName}
        shortAddress={mapModalData.shortAddress}
        slug={mapModalData.slug}
        latitude={mapModalData.latitude}
        longitude={mapModalData.longitude}
      />
    </main>
  );
};

export default BrowseRestaurants;
