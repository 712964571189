import React from 'react';
import PropTypes from 'prop-types';

const AdditionalItem = ({ onClickHandler }) => (
  <div className="additional-item">
    <img
      src="/assets/img/detailed-view/additional-items.svg"
      alt="additional_item"
    />
    <button
      onClick={onClickHandler}
      className="btn btn-primary mt-5 mb-3"
      type="button"
    >
      Add Additional Items
    </button>
    <p>Dietary restriction items available</p>
  </div>
);

AdditionalItem.propTypes = {
  onClickHandler: PropTypes.func,
};

AdditionalItem.defaultProps = {
  onClickHandler: () => {},
};

export default AdditionalItem;
