import { getDeliveryInfo } from 'utils/address.util';
import Geocode from 'react-geocode';

import { fetchAPI } from './apiUtils';

const queryString = require('query-string');

export const getTopBarSearchResult = async q => {
  const deliveryInfo = getDeliveryInfo();
  const address = deliveryInfo.address || '';
  try {
    const { results } = await Geocode.fromAddress(address);
    const { lat, lng } = results[0].geometry.location;
    console.log(lat, lng);
    return fetchAPI(
      `api/v2/search/elastic_search?${queryString.stringify({ lat, lng, q })}`,
    );
  } catch (e) {
    console.log('---> geocode failed');
    // just use plain address
    return fetchAPI(
      `api/v2/search/elastic_search?${queryString.stringify({
        q,
        address,
        city: 'Toronto',
      })}`,
    );
  }
};
