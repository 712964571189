import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class AddressPickerField extends Component {
  constructor(props) {
    super(props);
    const address = props.addAddressLocal
      ? JSON.parse(localStorage.getItem('jb-delivery-info'))?.address ||
        props.defaultAddress
      : props.defaultAddress;
    this.state = { address };
    if (address) {
      geocodeByAddress(address).then(results => {
        getLatLng(results[0]).then(prop => {
          props.onSetLatLng(prop);
        });
      });
    }
  }

  handleChange = address => {
    const { name, onChange, setToggle } = this.props;
    onChange(name, '');
    setToggle(true);
    if (address === '') {
      setToggle(false);
    }
    this.setState({ address });
  };

  handleSelect = address => {
    const { name, onChange, onSetLatLng, setToggle } = this.props;

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]).then(prop => onSetLatLng(prop)))
      .then(() => {
        this.change = false;
        onChange(name, address);
        this.setState({ address });
        setToggle(false);
      })
      .catch(error => console.error('Error', error));
  };

  handleBlur = e => {
    const { address } = this.state;
    e.forEach(item => {
      if (item.description === address) {
        this.handleSelect(address);
      }
    });
  };

  render() {
    const { name, onFocus, placeholder, showGoogleLogo } = this.props;
    const { address } = this.state;
    return (
      <PlacesAutocomplete
        searchOptions={{
          types: ['address'],
          componentRestrictions: {
            country: ['CA', 'US'],
          },
        }}
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                // placeholder: 'Search Places ...',
                className: 'location-search-input form-control',
              })}
              {...{ name, onFocus, placeholder }}
              onBlur={() => this.handleBlur(suggestions)}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading ...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    key={index}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
              {showGoogleLogo && (loading || suggestions.length > 0) && (
                <div
                  id="google-logo"
                  style={{ textAlign: 'right', padding: 8 }}
                >
                  <img
                    alt="google"
                    src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

AddressPickerField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSetLatLng: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  setToggle: PropTypes.func,
  placeholder: PropTypes.string,
  showGoogleLogo: PropTypes.bool,
  addAddressLocal: PropTypes.bool,
  defaultAddress: PropTypes.string,
};

AddressPickerField.defaultProps = {
  id: '',
  value: '',
  placeholder: '',
  defaultAddress: '',
  showGoogleLogo: true,
  addAddressLocal: true,
  onChange: () => {},
  setToggle: () => {},
  onSetLatLng: latLng => {
    console.log('Success', latLng);
  },
  onFocus: () => {},
  onBlur: () => {},
};

export default AddressPickerField;
