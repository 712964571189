import React from 'react';
import './index.scss';
import HowItWorks from 'pages/Home/HowItWorks';

const AboutUS = () => {
  return (
    <div className="container">
      <img
        className="cover-image"
        src="/assets/img/about/headerlandscape.jpg"
        alt="about"
      />
      <h2 className="book-demo--title text-center mt-5 mb-3">About Us</h2>
      <div className="row">
        <p className="about-us">
          We’re on a mission to take the thinking out of food ordering. Consider
          us your extended family of food experts. We curate the best food
          options in your city so all you have to do is order and enjoy.
        </p>
      </div>
      <h2 className="book-demo--title text-center mt-5 mb-3">Our Story</h2>
      <div className="row flex-quote">
        <img
          className="cover-image cover-image--half"
          src="/assets/img/home/head.jpg"
          alt="team-lunch"
        />
        <div className="quote">
          <p className="about__description">
            Catered Club was founded on the vision of bringing people together
            with amazing food. We believe that you can be blissfully
            dissatisfied. When you have one fork of your favourite cuisine you
            always want another. We do our best to make sure that the next bite
            is always as good as the last one. Our curated food experts explore
            the cuisines of each city to come up with the best options so that
            you don’t have to cross your fingers each time you order something
            new.
          </p>
        </div>
      </div>
      <h2 className="book-demo--title text-center mt-5 mb-3">What We Do</h2>
      <div className="row flex-quote mb-5">
        <div className="quote">
          <p className="about__description">
            We have our foodie experts go out and try all of the restaurants in
            your city to curate only the BEST options possible. We partner up
            with the restauranteurs and caterers that offer the highest quality
            and fullest quantity across North America to cater amazing food to
            you.
          </p>
          <p className="about__description">
            Our job is to make each order that you make from us not just a meal
            but an experience. Our mission is to bring happiness and smiles to
            you and your people, and we are the number one option for office
            catering and individual food orders online.
          </p>
        </div>
        <div className="quote">
          <HowItWorks column />
        </div>
      </div>

      <div className="row flex-quote my-5">
        <img
          className="cover-image cover-image--half"
          src="/assets/img/about/office_team_lunch.jpg"
          alt="team-lunch"
        />
        <div className="quote quote--mission">
          <p className="about__description">
            Investing in yourself and your people with amazing food is where it
            all starts. Sharing meals with others is one of the best ways to
            build deeper bonds and increase over all wellbeing and happiness and
            it has been this way for centuries. We believe that food is one of
            the most important investments that you can make in yourself and
            must be carefully curated and well thought through.
          </p>
          - Anthony Panepinto
          <p className="about__description">Co-founder & CEO, Catered Club</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUS;
