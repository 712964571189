import { fetchAPI } from './apiUtils';

export const PRESET_URL = 'api/v2/presets';

export const SEARCH_URL = 'api/v2/search';

export const FAVORITES_URL = 'api/v2/favorites';

export const GROUP_ORDER_URL = 'api/v2/group_orders';

export function getPresets() {
  return fetchAPI(PRESET_URL);
}

export function getPresetDetail(presetSlug) {
  return fetchAPI(`${PRESET_URL}/${presetSlug}`);
}

export function searchPresets({ queryParams, page }) {
  return fetchAPI(
    `${SEARCH_URL}?${queryParams}&search_type=preset&page=${page || 1}`,
  );
}

export function saveForLater(newName, preset, checkoutData) {
  const formData = {
    preset: {
      presetType: 'save_for_later',
      basePresetId: preset.id,
      name: newName,
      description: preset.description,
      presetItems: checkoutData,
    },
  };

  return fetchAPI(PRESET_URL, 'POST', formData);
}

export function removeSavedPreset(presetId) {
  return fetchAPI(`${PRESET_URL}/${presetId}`, 'DELETE');
}

export function addFavorite(presetId) {
  const formData = {
    presetId,
  };
  return fetchAPI(FAVORITES_URL, 'POST', formData);
}

export function removeFavorite(presetId) {
  const formData = {
    presetId,
  };
  return fetchAPI(`${FAVORITES_URL}/remove`, 'DELETE', formData);
}

export function fetchFavoritesAndSavedOrders() {
  return fetchAPI(`${SEARCH_URL}?search_type=user_data`);
}

export function createGroupOrder(presetId) {
  const formData = {
    presetId,
  };
  return fetchAPI(GROUP_ORDER_URL, 'POST', formData);
}
