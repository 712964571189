/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router-dom';

import styles from './style.module.scss';

const Accordion = ({
  topHeader,
  content,
  isDefaultOpen,
  className,
  hash,
  link,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [isFirstOpen, setFirstOpen] = useState(false);
  const { pathname } = useLocation();

  const contentRef = useRef(null);
  const wrapRef = useRef(null);

  const handlerOpen = () => {
    if (isFirstOpen) {
      setFirstOpen(false);
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    if (contentRef.current?.offsetHeight) {
      setContentHeight(contentRef.current?.offsetHeight);
    }
  }, [contentRef.current?.offsetHeight]);

  useEffect(() => {
    if (hash && link === hash && wrapRef?.current?.offsetTop) {
      window.scrollTo({ top: wrapRef.current.offsetTop, behavior: 'smooth' });
      setTimeout(() => setIsOpen(true), 1000);
    }
  }, [wrapRef?.current?.offsetTop]);

  useEffect(() => {
    if (isDefaultOpen) {
      setFirstOpen(isDefaultOpen);
    }
  }, [isDefaultOpen]);

  return (
    <div ref={wrapRef} className={cn(className, styles.wrapper)}>
      <div
        className={cn(styles.header, {
          [styles.active]: isOpen || isFirstOpen,
        })}
        onClick={handlerOpen}
      >
        <>
          <a href={`${pathname}${link}`}>{topHeader}</a>
          <span className={styles.arrow} />
        </>
      </div>
      <div
        className={cn(styles.contentWrapp, {
          [styles.open]: isOpen || isFirstOpen,
        })}
        {...((isOpen || isFirstOpen) && {
          style: {
            maxHeight: `${contentHeight}px`,
          },
        })}
      >
        <div ref={contentRef}>{ReactHtmlParser(content)}</div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  topHeader: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  hash: PropTypes.string,
  link: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  topHeader: 'Title',
  content: '',
  className: '',
  hash: '',
  link: '',
  isDefaultOpen: false,
};

export default Accordion;
