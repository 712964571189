import React from 'react';

const SimpleSolutions = () => {
  return (
    <section>
      <div className="container text-center hide-mobile">
        <h2 className="mb-4">Simple Solutions Built For Your Team</h2>
        <div className="row home--features-list centered">
          <div className="col-xl-3 home--feature">
            <div className="feature-image">
              <img src="/assets/img/home/preset_menus.svg" alt="preset_menus" />
            </div>
            <h4>Preset Menus</h4>
            <p className="text-muted">
              Catering fully customized for your office&apos;s needs. Perfect
              for any meal.
            </p>
          </div>
          <div className="col-xl-3 home--feature mx-1">
            <div className="feature-image">
              <img
                src="/assets/img/home/easy_ordering.svg"
                alt="easy_ordering"
              />
            </div>
            <h4>Easy Ordering</h4>
            <p className="text-muted">
              99% of the work is done for you. Once you find a preset that you
              like, you can customize the preset further to meet your exact
              requirements.
            </p>
          </div>
          <div className="col-xl-3 home--feature">
            <div className="feature-image">
              <img
                src="/assets/img/home/pop_up_stations.svg"
                alt="popup_stations"
              />
            </div>
            <h4>Pop-Up Stations</h4>
            <p className="text-muted">
              Meals from top local caterers, prepared on-site at your office for
              purchase by your team.
            </p>
          </div>
          <div className="col-xl-3 home--feature">
            <div className="feature-image">
              <img
                src="/assets/img/home/special_events.svg"
                alt="special_event"
              />
            </div>
            <h4>Special Events</h4>
            <p className="text-muted">
              We plan, you party - for every budget and any occasion, from
              holiday parties to happy hour.
            </p>
          </div>
          <div className="col-xl-3 home--feature">
            <div className="feature-image">
              <img
                src="/assets/img/home/office_snacks.svg"
                alt="office_snacks"
              />
            </div>
            <h4>Office Snacks</h4>
            <p className="text-muted">
              Keep your pantry full and your team fuel-led with delivery options
              that suit your office.
            </p>
          </div>
          <div className="col-xl-3 home--feature">
            <div className="feature-image">
              <img src="/assets/img/home/discounts.svg" alt="discounts" />
            </div>
            <h4>Employee In-Store Discounts</h4>
            <p className="text-muted">
              If you order with us a lot we can get you in-store employee
              discounts for your team&apos;s available with all our Catered Club
              vendors.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SimpleSolutions;
