import { REQUIRED_VALIDATION } from 'consts/form';
import * as types from './actionTypes';

export function showSignInModal(headerText = REQUIRED_VALIDATION) {
  return async dispatch => {
    const action = { type: types.SHOW_SIGNIN_MODAL_SUCCESS, text: headerText };
    dispatch(action);
  };
}

export function showSignUpModal() {
  return async dispatch => {
    const action = { type: types.SHOW_SIGNUP_MODAL_SUCCESS };
    dispatch(action);
  };
}

export function showPasswordModal() {
  return async dispatch => {
    const action = { type: types.SHOW_PASSWORD_MODAL_SUCCESS };
    dispatch(action);
  };
}

export function showConfirmModal(confirmSuccess, name = '') {
  return async dispatch => {
    const action = {
      type: types.SHOW_CONFIRM_MODAL_SUCCESS,
      modalHeader: confirmSuccess ? `Hello ${name}, good news!` : 'Hmm ...',
      modalText: confirmSuccess
        ? 'Your account has been verified. Please log in'
        : "Your account verification didn’t work. Please contact us at support@cateredclub.com and we're Happy to help!",
    };

    dispatch(action);
  };
}
export function closeSignInSignUpModal() {
  return async dispatch => {
    const action = { type: types.CLOSE_SIGNIN_SIGNUP_MODAL_SUCCESS };
    dispatch(action);
  };
}
