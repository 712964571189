import _ from 'lodash';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
// eslint-disable-next-line
export const objectKeysToCamelCase = snake_case_object => {
  // for the array which contains object keys as well
  const camelCaseObject = {};
  let newValue;
  _.forEach(snake_case_object, (value, key) => {
    if (_.isPlainObject(value) || _.isArray(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      newValue = objectKeysToCamelCase(value); // recursively update keys of any values that are also objects
    }
    camelCaseObject[_.camelCase(key)] = newValue;
  });
  return camelCaseObject;
};

export const objectKeysToSnakeCase = camelCaseObject => {
  // eslint-disable-next-line
  const snake_case_object = {};
  let newValue;
  _.forEach(camelCaseObject, (value, key) => {
    if (_.isPlainObject(value) || _.isArray(value)) {
      // checks that a value is a plain object or
      // an array for recursive key conversion
      newValue = objectKeysToSnakeCase(value); // recursively update keys of any values that are also objects
    }
    snake_case_object[_.snakeCase(key)] = newValue;
  });
  // eslint-disable-next-line
  return snake_case_object;
};

export const toCamelKeys = obj => {
  // for regular use
  if (Array.isArray(obj)) {
    return obj.map(v => toCamelKeys(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: toCamelKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

function snakeCaseWithException(key) {
  if (key === 'address1' || key === 'address2') {
    return key;
  }

  return snakeCase(key);
}
export const toSnakeKeys = obj => {
  // for regular use
  if (Array.isArray(obj)) {
    return obj.map(v => toSnakeKeys(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [snakeCaseWithException(key)]: toSnakeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};
