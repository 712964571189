import * as types from './actionTypes';

export const updateUserCart = (presetId, checkoutData) => {
  return {
    type: types.UPDATE_USER_CART,
    payload: {
      presetId,
      checkoutData,
    },
  };
};

export const removeUserCart = presetId => {
  return {
    type: types.REMOVE_USER_CART,
    payload: {
      presetId,
    },
  };
};
