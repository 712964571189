import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const ConfirmModal = ({
  show,
  icon,
  description,
  confirmText,
  cancelText,
  onClose,
  onConfirm,
  className,
}) => {
  return (
    <Modal
      dialogClassName="modal-confirm"
      className={className}
      show={show}
      onHide={() => {
        onClose();
      }}
    >
      <button
        className="close"
        type="button"
        aria-label="Close"
        onClick={() => {
          onClose();
        }}
      />
      <Modal.Body>
        {icon && <img src={icon} alt="modal_icon" />}
        <h3 className="mt-5 mb-5">{description}</h3>
        <button
          className="btn btn-primary mr-xl-3"
          type="button"
          onClick={() => {
            onConfirm();
          }}
        >
          {confirmText}
        </button>
        <button
          className="btn btn-outline-primary ml-3 hide-mobile"
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          {cancelText}
        </button>
      </Modal.Body>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  show: PropTypes.bool,
  icon: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmModal.defaultProps = {
  show: false,
  icon: '',
  description: '',
  className: '',
  confirmText: 'Ok',
  cancelText: 'Cancel',
  onClose: () => {},
  onConfirm: () => {},
};

export default ConfirmModal;
