import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function receivingMethodReducer(
  state = initialState.receivingMethod,
  action,
) {
  switch (action.type) {
    case types.UPDATE_RECEIVING_METHOD:
      return action.payload.receivingMethod;
    default:
      return state;
  }
}
