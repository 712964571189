import { beginApiCall, apiCallError } from 'redux/actions/apiStatusActions';
import * as cateringApi from 'api/cateringApi';
import * as types from './actionTypes';

export function clearCateringOrder() {
  return { type: types.CLEAR_CATERING_ORDER };
}

export function getCateringOrder(id) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.GET_CATERING_ORDER_REQUEST });
    try {
      const data = await cateringApi.getCateringOrder(id);
      dispatch({ type: types.GET_CATERING_ORDER_SUCCESS, data });
    } catch (e) {
      const { error } = await e.response.json();
      dispatch({ type: types.GET_CATERING_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function getGroupOrders(id) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.GET_GROUP_ORDER_REQUEST });
    try {
      const data = await cateringApi.getGroupOrders(id);
      dispatch({ type: types.GET_GROUP_ORDER_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.GET_GROUP_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function cateringOrder(cart) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.FETCH_CATERING_ORDER_REQUEST });
    try {
      const preset = await cateringApi.createCateringOrder(cart);
      dispatch({ type: types.FETCH_CATERING_ORDER_SUCCESS, data: preset });
    } catch (error) {
      dispatch({ type: types.FETCH_CATERING_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function cateringSuggestions(id, cart) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.CATERING_ORDER_SUGGESTIONS_REQUEST });
    try {
      const data = await cateringApi.suggestions(id, cart);
      dispatch({ type: types.CATERING_ORDER_SUGGESTIONS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.CATERING_ORDER_SUGGESTIONS_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function clearCateringSuggestions() {
  return async dispatch => {
    dispatch({ type: types.CLEAR_CATERING_ORDER_SUGGESTIONS });
  };
}

export function cateringOrderUpdate(cart, id) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.UPDATE_CATERING_ORDER_REQUEST });
    try {
      const preset = await cateringApi.updateCateringOrder(cart, id);
      dispatch({ type: types.UPDATE_CATERING_ORDER_SUCCESS, data: preset });
    } catch (error) {
      dispatch({ type: types.UPDATE_CATERING_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function cateringOrderFinalize(paymentMethodId, id, saveCard = false) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.FINALIZE_CATERING_ORDER_REQUEST });
    try {
      const preset = await cateringApi.finalizeCateringOrder(
        paymentMethodId,
        id,
        saveCard,
      );
      dispatch({ type: types.FINALIZE_CATERING_ORDER_SUCCESS, data: preset });
    } catch (error) {
      dispatch({ type: types.FINALIZE_CATERING_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function cateringOrderCancel(id) {
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch({ type: types.CANCEL_CATERING_ORDER_REQUEST });
    try {
      await cateringApi.cancelCateringOrder(id);
      dispatch({ type: types.CANCEL_CATERING_ORDER_SUCCESS });
    } catch (error) {
      dispatch({ type: types.CANCEL_CATERING_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function cancelGroupOrders(id) {
  return async dispatch => {
    dispatch({ type: types.CANCEL_GROUP_ORDER_REQUEST });
    try {
      await cateringApi.cancelGroupOrders(id);
      dispatch({ type: types.CANCEL_GROUP_ORDER_SUCCESS });
    } catch (error) {
      dispatch({ type: types.CANCEL_GROUP_ORDER_FAILED, error });
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function getCateringOrderPdf(id) {
  return async dispatch => {
    try {
      await cateringApi.getCateringOrderPdf(id);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function getGroupOrderPdf(id) {
  return async dispatch => {
    try {
      await cateringApi.getGroupOrderPdf(id);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
