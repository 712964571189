import omit from 'lodash/omit';
import format from 'date-fns/format';
import { SAVED_AT_TIME_FORMAT } from 'consts/form';
import * as types from './actionTypes';

const initialState = {}; // will save the user cart data identified by preset_id. "1": { checkoutData: [], savedAt: Today }

export default function userCartReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case types.UPDATE_USER_CART:
      return {
        ...state,
        [payload.presetId]: {
          savedAt: format(new Date(), SAVED_AT_TIME_FORMAT),
          checkoutData: [...payload.checkoutData],
        },
      };
    case types.REMOVE_USER_CART:
      return omit(state, [payload.presetId]);

    default:
      return state;
  }
}
