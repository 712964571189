/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import {
  closeSignInSignUpModal,
  showSignUpModal,
  showPasswordModal,
} from 'redux/actions/modalActions';
import { REQUIRED_VALIDATION, INVALID_EMAIL } from 'consts/form';
import { signinRequest } from 'redux/actions/authActions';
import { authenticate } from 'api/authApi';
import { useHistory, useLocation } from 'react-router-dom';
import VoidLink from './VoidLink';
import FormError from './FormError';
import LoadingAnimation from './LoadingAnimation';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_VALIDATION),
  password: yup.string().required(REQUIRED_VALIDATION),
});

const initialValues = {
  email: '',
  password: '',
};

const SignInModal = ({
  signInModalShown,
  showSignUpModal,
  showPasswordModal,
  closeSignInSignUpModal,
  signinRequest,
  modalHeader,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const history = useHistory();
  const { state } = useLocation();

  async function onSubmit(formData) {
    setLoading(true);
    try {
      const auth = await authenticate({ auth: formData });
      if (auth && auth.user && auth.user.confirmed) {
        signinRequest(auth);
        closeSignInSignUpModal();
        if (state?.linkRedirect) {
          history.push({
            pathname: state?.linkRedirect,
            state: {
              ...state,
              linkRedirect: null,
            },
          });
        }
        // need to go to made for you page
      } else {
        setErrorMessages(['Please confirm your email address.']);
      }
    } catch (e) {
      setErrorMessages(['Invalid Information. Please try again.']);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      show={signInModalShown}
      onHide={() => {
        setErrorMessages(null);
        closeSignInSignUpModal();
      }}
    >
      <button
        className="close"
        type="button"
        data-dismiss="modal"
        aria-label="Close"
        onClick={() => {
          setErrorMessages(null);
          closeSignInSignUpModal();
        }}
      />
      {loading && <LoadingAnimation />}
      <Modal.Header>
        <h3>{modalHeader}</h3>
      </Modal.Header>

      <Modal.Body className="modal--sign-in pb-5">
        {errorMessages && (
          <div className="alert alert-danger py-4 py-xl-3 d-flex align-items-center">
            <svg className="icon icon-warning">
              <use xlinkHref="#spriteIcon-warning" />
            </svg>
            <span>{errorMessages.join(', ')}</span>
          </div>
        )}
        <Formik
          {...{ initialValues, validationSchema }}
          validateOnChange
          validateOnBlur
          onSubmit={form => {
            onSubmit(form);
          }}
        >
          {({ values }) => (
            <Form>
              <h3 className="hide-mobile mb-3">Sign In</h3>
              <div className="labeled-group floating">
                <Field type="email" className="form-control" name="email" />
                <FormError name="email" />
                <label
                  className={classnames({
                    'label-focused': values.email,
                  })}
                >
                  Email
                </label>
              </div>
              <div className="input-with-icons mb-2 ii-right">
                <div className="labeled-group floating">
                  <Field
                    type="password"
                    className="form-control"
                    name="password"
                  />
                  <FormError name="password" />
                  <label
                    className={classnames({
                      'label-focused': values.password,
                    })}
                  >
                    Password
                  </label>
                </div>
                <div className="input-append">
                  <VoidLink onClick={() => showPasswordModal()}>
                    Forgot ?
                  </VoidLink>
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block mt-2">
                Sign In
              </button>
              <div className="form-footer mt-4">
                <span>Don&apos;t have an account? &nbsp;</span>
                <VoidLink onClick={() => showSignUpModal()}>
                  <b>Sign Up</b>
                </VoidLink>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  signInModalShown: state.modal.signInModalShown,
  modalHeader: state.modal.modalHeader,
});

const mapDispatchToProps = {
  closeSignInSignUpModal,
  showSignUpModal,
  signinRequest,
  showPasswordModal,
};

SignInModal.propTypes = {
  signInModalShown: PropTypes.bool.isRequired,
  showPasswordModal: PropTypes.func.isRequired,
  closeSignInSignUpModal: PropTypes.func.isRequired,
  showSignUpModal: PropTypes.func.isRequired,
  signinRequest: PropTypes.func.isRequired,
  modalHeader: PropTypes.string,
};

SignInModal.defaultProps = {
  modalHeader: REQUIRED_VALIDATION,
};

const connectedSignInModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInModal);
export default connectedSignInModal;
