import React from 'react';
import PropTypes from 'prop-types';
import VoidLink from './VoidLink';

export default class CycleLink extends React.Component {
  constructor() {
    super();
    this.state = {
      cycleIndex: 0,
    };
  }

  componentDidMount() {
    const { values } = this.props;
    const len = values && values.length;
    if (len > 1) {
      this.interval = setInterval(() => {
        const { cycleIndex } = this.state;
        this.setState({ cycleIndex: (cycleIndex + 1) % len });
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  render() {
    const { className, values, links } = this.props;
    const { cycleIndex } = this.state;
    const len = values && values.length;
    return len > 0 ? (
      <a className={className} href={links[cycleIndex]}>
        {values[cycleIndex]}
      </a>
    ) : (
      <VoidLink className={className}>{values[cycleIndex]}</VoidLink>
    );
  }
}

CycleLink.propTypes = {
  className: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.string),
};

CycleLink.defaultProps = {
  className: '',
  values: [],
  links: [],
};
