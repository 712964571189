import React from 'react';
import './index.scss';

const LoadingAnimation = () => (
  <div className="loading-animation-container">
    <img src="/assets/img/loading-2.gif" width="375" alt="loading_gif" />
  </div>
);

export default LoadingAnimation;
