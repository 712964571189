import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const HowItWorks = ({ column }) => {
  return (
    <section>
      <div className="container p-xl-5">
        <h2 className="text-center">How It Works</h2>
        <div className="row home--features-list">
          <div
            className={classnames('home--feature', {
              'col-xl-3': !column,
              'col-xs-12 mt-0': column,
            })}
          >
            <div className="feature-image">
              <img src="/assets/img/home/get-ideas.svg" alt="get-ideas" />
            </div>
            <h4>Get Ideas</h4>
            <p className="text-muted">
              Your day is busy enough. Our search experience makes it easy to
              get ideas on what meals to order.
            </p>
          </div>
          <div
            className={classnames('home--feature', {
              'col-xl-3': !column,
              'col-xs-12 mt-0': column,
            })}
          >
            <div className="feature-image">
              <img src="/assets/img/home/order-online.svg" alt="order-online" />
            </div>
            <h4>Order Online in Minute</h4>
            <p className="text-muted">
              Once you&apos;ve found what you&apos;d like to order, our ordering
              experience lets you order quickly and easily.
            </p>
          </div>
          <div
            className={classnames('home--feature', {
              'col-xl-3': !column,
              'col-xs-12 mt-0': column,
            })}
          >
            <div className="feature-image">
              <img
                src="/assets/img/home/only-the-best.svg"
                alt="only-the-best"
              />
            </div>
            <h4>Only The Best</h4>
            <p className="text-muted">
              You can count on consistently good experiences, because our
              mission is to keep you full and fulfilled, pinky promise.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

HowItWorks.defaultProps = {
  column: false,
};

HowItWorks.propTypes = {
  column: PropTypes.bool,
};
export default HowItWorks;
